/*
*
*  Push Notifications codelab
*  Copyright 2015 Google Inc. All rights reserved.
*
*  Licensed under the Apache License, Version 2.0 (the "License");
*  you may not use this file except in compliance with the License.
*  You may obtain a copy of the License at
*
*      https://www.apache.org/licenses/LICENSE-2.0
*
*  Unless required by applicable law or agreed to in writing, software
*  distributed under the License is distributed on an "AS IS" BASIS,
*  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
*  See the License for the specific language governing permissions and
*  limitations under the License
*
*/

/* eslint-env browser, serviceworker, es6 */

/**
 * Web service worker uses to push web notification when an ECG is available for review
 */

'use strict';

const applicationServerPublicKey = 'BBHIeYmCiY5EZIV01VKlEpaaBzCGwbTNuZ9-179OeV0M-RQS0kelk4mSWGwvd5hUelXx92DNg50V9d6iU3USd-0';

let isSubscribed = false;
let swRegistration = null;

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}



export function initializeSubscribeWebPush(registerWebpushCallback) { 
    
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Service Worker and Push is supported');
    
        const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
    
        navigator.serviceWorker.register(swUrl)
        .then(function(swReg) {
            console.log('Service Worker is registered', swReg);
    
            swRegistration = swReg;

            subscribeUser(registerWebpushCallback)
        })
        .catch(function(error) {
            console.error('Service Worker Error', error);
        });
    } else {
        console.warn('Push messaging is not supported');
    }

    // setTimeout(() => { subscribeUser(); }, 1000)

    // Set the initial subscription value
    
}

function subscribeUser(registerWebpushCallback) {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  swRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey
  })
  .then(function(subscription) {
    console.log('User is subscribed.');

    registerWebpushCallback(JSON.stringify(subscription));

    isSubscribed = true;
  })
  .catch(function(err) {
    console.log('Failed to subscribe the user: ', err);

  });
}

function unsubscribeUser() {
  swRegistration.pushManager.getSubscription()
  .then(function(subscription) {
    if (subscription) {
      return subscription.unsubscribe();
    }
  })
  .catch(function(error) {
    console.log('Error unsubscribing', error);
  })
  .then(function() {
    updateSubscriptionOnServer(null);

    console.log('User is unsubscribed.');
    isSubscribed = false;

  });
}

function updateSubscriptionOnServer(subscription) {
  // TODO: Send subscription to application server

  if (subscription) {
    var textContent = JSON.stringify(subscription);
    
    console.log('Subscription text:' + textContent);
    
  } else {
    console.warn('No subscription');
  }
}