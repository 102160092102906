import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles';

import UniversalDialog from '../UniversalDialog'
import { Button, InputAdornment, MenuItem, Select, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const useStyles = makeStyles((theme) => ({
  formInput: {
    width: `calc(100% - calc(${theme.spacing()} * 2))`,
    marginTop: theme.spacing() * 2,
  },
  divider: {
    margin: `calc(${theme.spacing()} * 4) 0 ${theme.spacing()}`,
  },
}))

function EditProfileDialog({ open, onClose, onSave, userInfo, admin, add }) {
  const classes = useStyles()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [medicalBackground, setMedicalBackground] = useState('')
  const [role, setRole] = useState('')
  const [email, setEmail] = useState('')
  const [disableAccount, setDisableAccount] = useState(false)
  const [twoFaEnabled, setTwoFaEnabled] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [seniorityLevel, setSeniorityLevel] = useState(50)

  useEffect(() => {
    if (!userInfo || !Object.keys(userInfo).length) {
      return
    }
    const { username, country, city, phone, medical_background, role, email, seniority_level, two_fa_enabled } = add? {} : userInfo

    const splitted = username?.split(' ')
    const firstName = username
      ? splitted.length > 1
        ? splitted.slice(0, splitted.length - 1)[0]
        : username
      : ''
    const lastName =
      username && splitted.length > 1 ? splitted.slice(splitted.length - 1)[0] : ''
    setFirstName(firstName || '')
    setLastName(lastName || '')
    setCountry(country || '')
    setCity(city || '')
    setPhoneNumber(phone || '')
    setEmail(email || '')
    setRole(role || '')
    setMedicalBackground(medical_background || '')
    setSeniorityLevel(seniority_level, 50)
    setTwoFaEnabled(two_fa_enabled)
  }, [userInfo, open])

  const handleSave = () => {
    const data = admin ? {
      city,
      country,
      first_name: firstName,
      last_name: lastName,
      medical_background: medicalBackground,
      two_fa_enabled: twoFaEnabled,
      phone: phoneNumber,
      role: role,
      seniority_level: seniorityLevel,
      email
    } : {
      ...userInfo,
      username: `${firstName} ${lastName}`,
      country,
      city,
      phone: phoneNumber,
      medical_background: medicalBackground,
      two_fa_enabled: twoFaEnabled,
    }
    
    onSave(add ?  data :{ ...data, reset_password: resetPassword, disable: disableAccount} )
  }

  const handleToggleTwoFA = () => {
    setTwoFaEnabled(!twoFaEnabled)
  }

  const buttons = [
    {
      text: 'Cancel',
      props: {
        onClick: onClose,
        variant: 'outlined',
        color: 'primary',
      },
    },
    {
      text: add ? 'Create profile' : 'Save',
      props: {
        onClick: handleSave,
        variant: 'contained',
        color: 'primary',
      },
    },
  ]

  return (
    <UniversalDialog open={open} title={add ? "Add profile" : "Edit profile"} buttons={buttons}>
      <Box>
        <Grid container direction="column" spacing={4}>
          <Grid item container direction="column" spacing={2}>
            <Grid item style={{ marginTop: "20px" }}>
              <Typography variant="h6">Basic Information</Typography>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  label={'First name'}
                  placeholder={'First name'}
                  variant="outlined"
                  size="small"
                  value={firstName}
                  onChange={({ target: { value } }) => {
                    setFirstName(value)
                  }}
                  className={classes.formInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={'Last name'}
                  placeholder={'Last name'}
                  variant="outlined"
                  size="small"
                  value={lastName}
                  onChange={({ target: { value } }) => {
                    setLastName(value)
                  }}
                  className={classes.formInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={'Country'}
                  placeholder={'Country'}
                  variant="outlined"
                  size="small"
                  value={country}
                  onChange={({ target: { value } }) => {
                    setCountry(value)
                  }}
                  className={classes.formInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={'City'}
                  placeholder={'City'}
                  variant="outlined"
                  size="small"
                  value={city}
                  onChange={({ target: { value } }) => {
                    setCity(value)
                  }}
                  className={classes.formInput}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: "40px", ml: "20px" }} />
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Contact information</Typography>
            </Grid>
            <Grid item container>
              <Grid item xs={6}>
              <PhoneInput
                country={'us'}
                value={phoneNumber}
                inputStyle={{width:"250px", height: "40px"}}
                onChange={phone => setPhoneNumber(phone)}
              />
                {/* <TextField
                  label={'Phone number'}
                  placeholder={'Phone number'}
                  variant="outlined"
                  size="small"
                  value={phoneNumber}
                  onChange={({ target: { value } }) => {
                    setPhoneNumber(value)
                  }}
                  className={classes.formInput}
                /> */}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={!add}
                  label={'Email'}
                  placeholder={'Email'}
                  variant="outlined"
                  size="small"
                  value={email || ''}
                  className={classes.formInput}
                  onChange={({ target: { value } }) => {
                    setEmail(value)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  className={classes.formInput}
                  control={
                    <Checkbox
                    onChange={handleToggleTwoFA}
                    name="negative" 
                    checked={twoFaEnabled}
                    />
                  }
                  label="2FA Enabled"
                    />
              </Grid>

            </Grid>
          </Grid>
          <Divider sx={{ mt: "40px", ml: "20px" }} />

          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Medical background</Typography>
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={3}
                  label={'Medical background'}
                  placeholder={'Medical background'}
                  variant="outlined"
                  size="small"
                  value={medicalBackground}
                  onChange={({ target: { value } }) => {
                    if (value.length < 201) {
                      setMedicalBackground(value)
                    }
                  }}
                  className={classes.formInput}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mt: "40px", ml: "20px" }} />
          {admin &&

            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6">Access</Typography>
              </Grid>
              <Grid item container>
                <Grid item xs={8}>
                  Select user role
                  <Typography variant="subtitle1" style={{ color: "gray", fontSize: "12px" }}>Set technician permission </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    sx={{ float: "right" }}
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Role"
                    value={role}
                    onChange={e => setRole(e.target.value)}
                  >
                    <MenuItem value='technician'>Technician</MenuItem>
                    <MenuItem value='junior_technician'>Junior technician</MenuItem>
                    <MenuItem value='guest'>Guest</MenuItem>
                    <MenuItem value='auditor'>Auditor</MenuItem>
                    <MenuItem value='admin'>Admin</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Divider sx={{ m: "20px", mr: "10px" }} />
              <Grid item container>
                <Grid item xs={8}>
                  Select seniority level
                  <Typography variant="subtitle1" style={{ color: "gray", fontSize: "12px" }}>Set percent of reviews by a technician that should be audited</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label=""
                    size="small"
                    type="number"
                    value={seniorityLevel}
                    onChange={e => setSeniorityLevel(() =>
                      parseInt(e.target.value > 100 ? 100 : (e.target.value < 0 ? 0 : e.target.value))
                    )}
                    sx={{ width: "100px", float: "right" }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {!add && <>
                <Divider sx={{ m: "20px", mr: "10px" }} />


                <Grid item container>
                  <Grid item xs={8}>
                    Reset password
                    <Typography variant="subtitle1" style={{ color: "gray", fontSize: "12px" }}>Send a new password to the technician email account</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={() => setResetPassword(prev => !prev)} variant={resetPassword ? "contained" : "outlined"} sx={{ float: "right" }}>RESET PASSWORD</Button>

                  </Grid>
                </Grid>

                <Divider sx={{ m: "20px", mr: "10px" }} />

                <Grid item container>
                  <Grid item xs={8}>
                    Disable account
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={() => setDisableAccount(prev => !prev)} variant={disableAccount ? "contained" : "outlined"} color="warning" sx={{ float: "right" }}>DISABLE ACCOUNT</Button>

                  </Grid>
                </Grid>
                <Divider sx={{ ml: "20px", mt: "20px" }} />
              </>}
            </Grid>

          }
        </Grid>
      </Box>
    </UniversalDialog>
  )
}

export default EditProfileDialog
