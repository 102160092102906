const createEnum = (values) => {
  const obj = {}
  values.forEach((value) => {
    obj[value] = value
  })
  return obj
}

export const submitStates = createEnum([
  'NONE',
  'IN_PROGRESS',
  'DONE',
  'ERROR',
  'ERROR_ECG_ERROR',
])

export const boxTypes = createEnum(['LARGE', 'SMALL'])

export const eventTypes = createEnum([
  'beat_added',
  'beat_removed',
  'rhythm_added',
  'rhythm_removed',
  'click',
  'sanity_triggered',
])

export const sanityErrors = createEnum([
  'NO_RHYTHM_SELECTED',
  'NO_WARM_MESSAGE',
  'EMPTY_PQRST',
  'NO_FULL_SCROLL',
  'NO_ALLOW_FINISH_REVIEW',
])
