
import React, { useContext, useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material';
import TableCell from '@mui/material/TableCell';
import { TableTag } from '../../components/TaskTable/TaskTable.styled'
import { Table, Paper, TableBody, TableContainer, TableHead, TableRow, IconButton, InputBase, Select, MenuItem, TablePagination, Avatar, Typography, Box, Chip, Button, Dialog, DialogTitle, Divider, ListItem, ListItemAvatar, ListItemText, DialogActions, Tooltip, Radio, TextField } from '@mui/material'
import moment from 'moment'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { AdminContext } from '../../contexts/Admin.context';
import { stringAvatar } from '../../common/utils';
import API from '../../common/api'
import { ASSIGN_TECHNICIAN } from '../../common/endpoints'
import EditIcon from '@mui/icons-material/Edit';
import { forceEcgComplete } from '../../services/admin';
import { random } from 'lodash';

// table settings
const initialRowsPerPage = 50
const initialPage = 0

const TasksTab = () => {

    const { tasksData, setPriority, forceComplete, getTasks, technicianData, getTechniciansTasks } = useContext(AdminContext)

    const [page, setPage] = useState(initialPage)
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
    const [search, setSearch] = useState(null)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    useEffect(() => {

        getTechniciansTasks()
    }, [])

    useEffect(() => {
        getTasks(page + 1, rowsPerPage, search)
    }, [page, rowsPerPage, search])

    const [openModal, setOpenModal] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleClickOpen = () => {
        setOpenModal(true);
    };

    const handleClose = (value) => {
        setOpenModal(false);
        setSelectedValue(null)
    };



    const getTagInfo = (status) => {
        if (status === 0) {
            return { customColor: '#ED6C02', label: "Uploaded" }
        }
        else if (status === 1) {
            return { customColor: '#DA100B', label: "Unassigned" }

        }
        else if (status === 2) {
            return { customColor: '#3F51B5', label: "Assigned" }

        }
        return { customColor: '#4CAF50', label: "Completed" }


    }
    return (
        <>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', marginTop: "20px" }}
            >
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    value={search}
                    onChange={e => {
                        setSearch(e.target.value)
                    }}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Enter task number..."
                    inputProps={{ 'aria-label': 'Enter task number...' }}
                />

            </Paper>

            <TableContainer style={{ marginTop: "20px", maxHeight: "60vh" }} component={Paper}>
                <Table size="small" stickyHeader sx={{ minWidth: 750 }} aria-label="simple table">
                    <TableHead sx={{height:"56px"}}>
                        <TableRow>
                            <TableCell>Task</TableCell>
                            <TableCell >Date</TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell >Technicians</TableCell>
                            <TableCell >Priority</TableCell>
                            <TableCell >
                                Force Complete 
                                (Only use for Error Task which can not Finish Review)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {tasksData.tasks?.map((row) => (
                            <TableRow
                                key={row.ecg_id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.ecg_id}
                                </TableCell>
                                <TableCell >{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                <TableCell ><TableTag {...getTagInfo(row.status)} /> </TableCell>
                                <TableCell sx={{ cursor: "pointer" }} ><Box sx={{ display: "flex", alignItems: "center", gap: "5px" }} >{row.technician ? <><Avatar sx={{ width: 18, height: 18 }} {...stringAvatar(row.technician)} />
                                    <Typography>{row.technician}</Typography></> : <Typography sx={{ color: "gray" }}>Not assigned</Typography>}
                                    {row.status === 1 && <Tooltip onClick={() => {
                                        setSelectedValue(row)
                                        setOpenModal(true)
                                    }} title="Assign technician">
                                        <IconButton
                                            size="small">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>} 
                                     </Box>
                                </TableCell>
                                <TableCell >
                                    <Select className="tasks-select" disabled={row.status === 3} size="small" defaultValue={row.priority} onChange={e => setPriority(row.ecg_id, e.target.value)}>
                                        <MenuItem value="normal" >
                                            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>      <FiberManualRecordIcon style={{ color: "#4CAF50" }} />
                                                Normal
                                            </div>
                                        </MenuItem>
                                        <MenuItem value="high">
                                            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>      <FiberManualRecordIcon style={{ color: "#F44336" }} />
                                                High
                                            </div>
                                        </MenuItem>
                                    </Select>
                                </TableCell>

                                <TableCell >
                                   
                                    <Button onClick={() => forceComplete(row.ecg_id)} disabled={row.status != 2} size="small" variant={"contained"} color="warning">Force Complete</Button>
                                   
                                </TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <AssignDialog open={openModal} close={handleClose} selectedValue={selectedValue} />
            </TableContainer>
            
            <TablePagination
                    component="div"
                    count={tasksData.meta?.total_count || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[]}
                /> </>

    )
}


function AssignDialog({ open, close, selectedValue }) {
    const { technicianTasksData, getTasks } = useContext(AdminContext)
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        if (!open) {
            setSelected(null)
        }
    }, [open])

    useEffect(() => {
        if (selectedValue) {
            setSelected(selectedValue.technician_id || 0)
        }

    }, [selectedValue])
    const save = () => {
        API.post(ASSIGN_TECHNICIAN(selectedValue.ecg_id), { technician: selected }).then()
            .finally(() => {
                getTasks()
                close()
            })
    }

    const buttons = [
        {
            text: 'Cancel',
            props: {
                onClick: close,
                variant: 'outlined',
                color: 'primary',
            },
        },
        {
            text: 'Save',
            props: {
                onClick: save,
                variant: 'contained',
                color: 'primary',
            },
        },
    ]

    const [searchTechnician, setSearchTechnician] = useState(null)
    return (
        <Dialog onClose={close} open={open} buttons={buttons} sx={{ padding: "50px" }}>
            <DialogTitle>Select Technician</DialogTitle>
            <Divider />
            <TextField
                value={searchTechnician}
                onChange={(e) => setSearchTechnician(e.target.value)}
                sx={{ ml: 1, flex: 1, width: "375px", margin: "20px" }}
                variant="outlined"
                placeholder="Enter technician name..."

                InputProps={{
                    startAdornment: <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>,
                }}
            />

            <Box sx={{ height: "400px", overflow: "scroll", margin: "5px 20px" }}>
                <ListItem sx={{ margin: "7px 0", gap: "10px" }}><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.452 12.516C18.732 11.9 18.9 11.228 18.9 10.5C18.9 7.798 16.702 5.6 14 5.6C13.272 5.6 12.6 5.768 11.984 6.048L18.452 12.516Z" fill="black" fill-opacity="0.54" />
                    <path d="M14 0C6.272 0 0 6.272 0 14C0 21.728 6.272 28 14 28C21.728 28 28 21.728 28 14C28 6.272 21.728 0 14 0ZM14 18.2C10.752 18.2 7.77 19.32 5.404 21.168C3.78 19.222 2.8 16.73 2.8 14C2.8 11.41 3.682 9.03 5.166 7.14L9.17 11.144C9.464 13.328 11.172 15.05 13.356 15.33L16.436 18.41C15.638 18.27 14.826 18.2 14 18.2ZM22.834 20.86L7.14 5.166C9.03 3.682 11.41 2.8 14 2.8C20.188 2.8 25.2 7.812 25.2 14C25.2 16.59 24.318 18.956 22.834 20.86Z" fill="black" fill-opacity="0.54" />
                </svg>
                    <ListItemText primary={'Unasigned'} />
                    <Radio onClick={() => setSelected(0)} checked={selected === 0} />
                </ListItem>

                <Divider />
                {technicianTasksData.technicians?.filter(line => ['technician', 'junior_technician', "auditor"].includes(line.role)).filter(line => searchTechnician ? line.username?.toLowerCase().includes(searchTechnician.toLowerCase()) : true).map((line) => (
                    <>  <ListItem key={line.id} sx={{ margin: "7px 0", gap: "10px" }}>
                        <Avatar {...stringAvatar(line.username)}>

                        </Avatar>
                        <ListItemText primary={line.username} />
                        <Radio onClick={() => setSelected(line.id)} checked={selected === line.id} />
                    </ListItem>

                        <Divider /></>
                ))}
            </Box>

            <DialogActions sx={{ height: "50px" }}>
                {buttons.map((button, index) => (
                    <Button key={index} {...button.props}>
                        {button.text}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
}
export default TasksTab
