import { toast, Slide } from "react-toastify";

export const TASK_STATUS = {
  0: 'Unassigned',
  1: 'Pending',
  2: 'In Progress',
  3: 'Completed',
}

export const TASK_TYPE = {
  'apple_watch': 'Apple Watch',
  'media_file': 'Media File',
  'withings': 'Withings',
  'samsung': 'Samsung',
  'fitbit': 'Fitbit',
}

export const AUDIT_STATUS = {
  0: 'Pending',
  1: 'Done',
}


export const INTERVAL_MAX_VALUE ={
  'p' : 121,
  'qrs' : 121,
  'pr' : 201,
  'qtc': 470
}

export const INTERVAL_MIN_VALUE ={
  'p' : 79,
  'qrs' : 79,
  'pr' : 119,
  'qtc': 349
}


export const SESSION_COOKIE = 'SESSION'
export const LAST_LOGIN_VERIFIED_COOKIE = 'LASTLOGINVERIFIED'

// number of coordinates per 10s
export const DEFAULT_SPLIT_FREQUENCY = 5121 // 512 * 10 for 10 seconds of Apple Watch ECG (30 seconds / 3)

export const TOASTR_OPTIONS = {
  position: toast.POSITION.BOTTOM_CENTER,
  transition: Slide,
  theme: "colored"
};
