const sinus_arrythmia = (function () {
    return [
"Hi {{name}},\n\nFirst and foremost, your ECG looks very lovely! There's a tiny irregularity in your rhythm, but that's just your heart syncing with your breaths. As you breathe in, your heart rate quickens; as you breathe out, it slows down a bit. Sinus arrhythmia is super common in young people and adults alike! So, rest assured, there's no need to worry 😌.\n\nTake care! 💕",
 

"Hey {{name}},\n\nLet's begin with the crucial part: your ECG is looking great! A slight irregularity in your rhythm exists, but it's due to your heart's coordination with your breathing. Inhaling makes your heart rate go up, while exhaling slows it down. Sinus arrhythmia is incredibly common in both young ones and grown-ups! In conclusion, you're all good, and there's nothing to stress about.\n\nTake care and stay well! 🌻",

"Hello {{name}},\n\nFirst things first, your ECG is looking amazing! There's a slight irregularity in your rhythm, but it's due to your heart syncing up with your breaths. As you inhale, your heart rate picks up a bit; as you exhale, it slows down. Sinus arrhythmia is common in younger folks and adults, too! So, relax, everything's all right 😊.\n\nTake care and stay happy! 🌼",

"Hi {{name}},\n\nStarting with the key point, your ECG looks fabulous! 😄 You may notice a slight variation in your rhythm, but that's your heart harmonizing with your breathing. Your heart rate increases when you inhale and decreases when you exhale. Sinus arrhythmia is very typical in younger people and adults, and it’s still considered normal heart rhythm!\n\nTake care! 😊",

"Hey {{name}},\n\nLet's dive into the essential aspect: your ECG is in pretty good shape! There's a minor irregularity in your rhythm, which is just your heart adjusting to your breathing. Your heart rate speeds up when you breathe in and slows down when you breathe out. Sinus arrhythmia is quite common, and it’s still considered a normal rhythm! In short, there's no reason for concern.\n\nTake care! 😄",

"Hello {{name}},\n\nI examined your ECG, and it appears quite normal. Sinus arrhythmia indicates that your heartbeat is irregular, but there's no need to worry – it's generally not a problem at all ❤️. It can occur in even healthy young individuals and is viewed as a typical ECG result. All other parameters are within normal limits, and there are no unusual beats, which is fantastic news :)\n\nI hope this helps! Take care and stay safe. 💖",

"Hi {{name}},\n\nI inspected your ECG, and it appears entirely normal. Sinus arrhythmia means that your heart's rhythm is irregular, but don't be alarmed – it's usually not a cause for stress at all ❤️. It is considered a standard ECG finding. All other readings are well within the norm, and there are no unusual beats as well.\n\nHave a good day! 😊",

"Hey {{name}},\n\nI evaluated your ECG, and it looks pretty usual. Sinus arrhythmia means that your heart is beating with an irregular pattern, but don't stress – it's typically not worrisome at all ❤️. It is considered a common ECG finding - something that is found even in healthy athletic people. PQRST values are well within the standard range, and there are no abnormal heartbeats in your recording.\n\nSending lots of love your way! ❤️",

"Hello {{name}},\n\nI checked your ECG, and it appears totally normal. Sinus arrhythmia shows that your heart has an irregular beat (it’s synchronizing with your breathing). It can happen in extremely healthy young individuals and is considered a typical ECG result - it's generally not a reason for concern at all ❤️ All other aspects are within the norm, and there are no odd beats, which is marvelous news :)\n\nTake care! 💕",

"Hi {{name}},\n\nOverall, your ECG looks fine 😊. Your heart rate goes up as you inhale and lowers as you exhale, yet your rhythm remains normal (this is what sinus arrhythmia represents). While it may seem alarming, it's incredibly common, and you shouldn't be concerned about it at all.\n\nTake extra good care! 😁",

"Hey {{name}}! 🌞\n\nYour results showed you have Sinus Arrhythmia. Sinus Arrhythmia happens when your heart rate changes as you breathe. It's super common and nothing to worry about, so you can relax knowing your heart is just doing its own special thing. 💓\n\nI hope this helps! Take care and stay safe. 💖",

"Hey {{name}}!\n\nI've taken a look at your ECG and noticed you have Sinus Arrhythmia, which is a totally normal variation of your heart rhythm. 😊 It's not something to be concerned about, as it just means that your heart rate changes slightly as you breathe in and out. Rest assured, your PQRST waves are regular, and there are no ectopic beats present.\n\nTake extra good care! 😁",

"Hello {{name}}!\n\nI've checked your ECG and found that you have Sinus Arrhythmia. Don't worry, it's an entirely normal heart rhythm variation! 😄 It's simply your heart rate adjusting as you breathe. Your PQRST waves are normal, and no ectopic beats were detected - which is excellent news.\n\nTake care! 💕",

"Hi {{name}},\n\nUpon reviewing your ECG, I noticed that you have Sinus Arrhythmia. Please don't worry about it, it's a typical heart rhythm variation that occurs as you inhale and exhale. 😌 Your PQRST waves are in good shape, and there are no ectopic beats to report\n\nI hope everything is alright, and stay safe. 💖",

"Hey {{name}},\n\nI've gone through your ECG and found out that you have Sinus Arrhythmia. No worries, it's a regular heart rhythm change that comes with breathing 😄. Your PQRST waves are looking good, and no ectopic beats were spotted.\n\nI hope this helps! Always take care 💖",

"Hi {{name}}! 😄 \n\nI've looked at your ECG, and it turns out you have a rhythm known as Sinus Arrhythmia. No need to stress, as it's quite common and harmless! Sinus Arrhythmia is simply a small change in heart rate related to your breathing. Your ECG also indicates normal PQRST and no ectopic beats.\n\nKeep taking good care of yourself! 🌸",

"Hello {{name}},\n\nI've examined your ECG and noticed you have a rhythm called Sinus Arrhythmia. Please, don't worry about it; it's actually a normal finding! Sinus Arrhythmia is a slight fluctuation in heart rate that occurs with your breathing. Additionally, your ECG shows normal PQRST and no abnormal beats. - this is what we love to see.\n\nKeep shining! 🌟",

"Hey {{name}},\n\nI've reviewed your ECG and discovered that you have a rhythm referred to as Sinus Arrhythmia. Rest assured, it's not something to be concerned about. Sinus Arrhythmia is a minor variation in heart rate that corresponds with your breaths. Your ECG also demonstrated normal PQRST and no ectopic beats.\n\nTake care 💖",

"Hi {{name}},\n\nJust wanted to let you know that I've checked your ECG and you have a rhythm called Sinus Arrhythmia. There's no need to be frighten, as it's a completely normal pattern. Sinus Arrhythmia is a minor change in your heart rate that happens as you breathe in and out. Additionally, your ECG has normal PQRST and no extra beats like PVCs and PACs.\n\nTake care and stay safe! 💖",

"Hello {{name}},\n\nI'm delighted to share the results of your ECG review with you. It turns out that you have Sinus Arrhythmia, which is a natural variation in your heart rhythm. Please don't get scared, as it's usually considered a harmless and normal finding. 😊\n\nTo give you a little insight, Sinus Arrhythmia is when your heart rate speeds up and slows down naturally in response to your breathing, and it's common in people of all ages.\n\nTake care! 😊",

"Hello {{name}} 🌞 \n\nI'm here to share some information about your recent ECG review. It looks like you have Sinus Arrhythmia, which is actually quite common and not something to worry about. Sinus Arrhythmia is just a natural variation in your heart rhythm, where the rate speeds up during inhalation and slows down during exhalation 😊. Rest assured, this isn't a cause for concern.\n\nTake care! 💖",

"Hi {{name}}, \n\nI just reviewed your ECG, and it’s looks like you have Sinus Arrhythmia, which is a natural variation in your heart rhythm. It's actually quite common and not something to be concerned about. In a nutshell, it means that your heart rate changes a little as you breathe in and out. Your ECG also shows normal PQRST and no ectopic beats, which is great!\n\nTake care! 😊",

"Hey {{name}},\n\nYour ECG shows Sinus Arrhythmia. It is a natural variation in your heart rhythm that often occurs when your breathing pattern changes. As you breathe in, your heart rate may slightly increase, and as you breathe out, it may decrease. It's pretty common and usually harmless, so there's no need to worry. In addition, your ECG showed a normal PQRST and no ectopic beats, which is excellent news! \n\nI hope this helps! Always take care 💖",

"Hello {{name}}, \n\nYour ECG indicates Sinus Arrhythmia. This is a natural variation in heart rhythm related to changes in your breathing. As you inhale, your heart rate might go up a bit, and as you exhale, it might go down. It's a common and typically benign condition, so don't worry. Also, your ECG displayed a normal PQRST and no ectopic beats, which is fantastic to see!\n\nTake care 💖",

"Hi {{name}} ☺️, \n\nYour ECG has revealed Sinus Arrhythmia. It's a natural fluctuation in heart rhythm that's connected to your breathing patterns. Your heart rate increases slightly as you breathe in and decrease as you breathe out. It's quite common and generally harmless, so there's no need for concern. Every other aspect of you recording is as normal as it gets 😊\n\nHave a great day!",

"Hey {{name}},\n\nYour ECG shows Sinus Arrhythmia. This is a natural change in your heart rhythm that's linked to variations in your breathing. As you inhale, your heart rate increases slightly, and as you exhale, it decreases. It's very common and usually harmless, so there is no need to be concerned.\n\nTake care and be well 💖",

"Hey {{name}}! 🌞\n\nI just wanted to share the findings from your ECG review. It turns out you have Sinus Arrhythmia, but don't worry; it's nothing to stress about! 😊 Sinus Arrhythmia is simply a variation in your heart's rhythm, which happens as you breathe in and out. It's quite normal and often seen in healthy people. Your ECG also showed a normal PQRST and no ectopic beats, which is great news! \n\nJust remember to take good care of yourself, and have a fantastic day ahead! 🌻",

"Hello {{name}}! 🌞\n\nI'm here to share some info from your ECG review. You've got Sinus Arrhythmia, but don't stress, it's not something to worry about! 😊 It's just a normal fluctuation in your heart's rhythm that occurs with breathing. Additionally, your ECG displayed a normal PQRST and no extra beats.\n\nKeep taking good care of yourself, and have a beautiful day! 🌻",

"Hi {{name}}, \n\nI wanted to let you know about your ECG review findings. You have Sinus Arrhythmia, but rest assured, it's not a cause for concern! 💙 Sinus Arrhythmia is a natural variation in your heart's rhythm related to breathing. Your ECG also showed a normal PQRST and no ectopic beats, which is awesome! \n\nTake care :) ",

"Greetings {{name}}! 🌟\n\nYour ECG results are in, and there's no need for concern! The Sinus Arrhythmia you're experiencing is simply your heart's natural rhythm syncing with your breaths. It's a common finding in healthy people of all ages.\n\nTake care :) ",

"Hey {{name}}!\n\nGood news! Your ECG is looking pretty good. You may have noticed some irregularity in your rhythm, but it's just your heart syncing with your breathing, a normal occurrence known as Sinus Arrhythmia that happen in healthy people of all ages.\n\nWishing you the best! 😄",

"Hello {{name}},\n\nI've got your ECG results, and they're looking good! The slight rhythm irregularity you're seeing is called Sinus Arrhythmia, and it's completely normal. It's just your heart rate changing with your breaths. To sum up, nothing rise my concern 😊\n\nCheers! 💙",

"Hello {{name}},\n\nYour ECG results are in, and they're looking pretty nice! The rhythm irregularity I've noticed is called Sinus Arrhythmia, which is just your heart rate changing as you breathe. It's perfectly normal. Plus, your PQRST values are all within the normal range, and there are no extra beats recorded. \n\nWishing you the best! 😄",

"Hey {{name}} 🌟\n\nI'm happy to report that your ECG results are good! The slight rhythm irregularity, Sinus Arrhythmia, is nothing to worry about. It's simply your heart syncing with your breaths. Also, your PQRST values are normal, and no extra beats (PVCs or PACs) were detected. \n\nTake care! 😊",

"Hello {{name}},\n\nYour ECG indicates a pattern of Sinus Arrhythmia, which is a naturally occurring variation in heart rate related to breathing patterns. It's quite common and usually harmless. Your PQRST intervals, are in the normal spectrum, and there are no irregular beats. This type of rhythm is often seen even in healthy individuals.\n\nWishing you all best! 💓", 

"Hi {{name}},\n\nI've reviewed your ECG and it shows Sinus Arrhythmia, a rhythm that fluctuates with your breaths—faster during inhalation, slower on exhalation. It’s still consider normal rhythm, and it’s not something you should worry about. Your PQRST intervals are consistent and there are no abnormal beats.\n\nAll the best! 💖",

"Greetings {{name}} 😄,\n\nAfter evaluating your ECG, Sinus Arrhythmia was observed, which reflects a heart rate that varies with the breathing cycle. This is a normal physiological response, and not something you should worry about, {{name}}. Rest assured, all your heart's intervals are within normal ranges.\n\nAll the best! 🌟", 

"Hey {{name}},\n\nYour ECG shows Sinus Arrhythmia, a common and typically benign variation in heart rhythm that's related to your breathing. It's actually very common in even with 100% healthy individuals. Your other measurements are normal, with no ectopic beats noted.\n\nHave amazing day! 🎉", 

"Hello {{name}},\n\nUpon examining your ECG, Sinus Arrhythmia was noted, which means your heart rate changes with your breaths. This is a normal response and not something you should worry about. Every other parameter on your ECG is within normal limits, and there are no signs of irregular beats.\n\nTake care! ✨", 

"Hi {{name}},\n\nYour ECG shows a rhythm known as Sinus Arrhythmia, a natural fluctuation in heart rate associated with breathing patterns. It's a common finding and generally not a concern. Your heart's PQRST intervals are all normal, and no ectopic beats are present.\n\nWishing you a happy day! 😊", 

"Hey {{name}},\n\nJust went over your ECG and spotted a Sinus Arrhythmia, which is a variation in heart rate that's typically connected to your breathing pattern. Please don’t get worry about it - Sinus Arrhythmia is still consider normal heart rhythm. All PQRST measurements are spot on normal.\n\nAll the best! 💕",

"Hi {{name}},\n\nI've assessed your ECG and your heart is showing a Sinus Arrhythmia pattern, a perfectly normal variation that reflects the heart's response to breathing. It's often found in healthy hearts. The rest of your readings, including the PQRST intervals, are exactly where they should be.\n\nContinue to take care of that heart of yours! ❤️",

"Hello {{name}},\n\nI've analyzed your ECG and noticed a Sinus Arrhythmia, a variation in the heart rate during your breathing cycle. This is often seen in healthy individuals and is usually of no concern. All other indicators, including PQRST intervals, are within the normal range.\n\nBest wishes for your wellbeing! 💙", 

"Hello {{name}},\n\nYour ECG has indicated a Sinus Arrhythmia, an absolutely normal variation in heart rate corresponding with your breathing pattern. Moreover, your PQRST measurements are all within normal parameters and I didn’t find abnormal beats as well.\n\nWishing you a great day! 💓", 

"Hey {{name}},\n\nYour ECG showed a Sinus Arrhythmia, which is a naturally occurring rhythm that varies with each breath. This is a normal heart rhythm, seen even in young athletics. The PQRST intervals are normal, and there are no ectopic beats - this is always good news.\n\nStay happy! 😄", 

"Greetings {{name}},\n\nYour ECG shows a rhythm pattern of Sinus Arrhythmia, a common and generally healthy variation in heart rate that's influenced by your breathing. It's nothing to worry about as your PQRST intervals are in the perfect range as well, and there are no unusual beats.\n\nAll the best! 🍀", 

"Hi there {{name}},\n\nA review of your ECG indicates Sinus Arrhythmia, which is a variation in rhythm that correlates with your breathing cycle. It's a common finding even in young or physically fit individuals. All your PQRST measurements are within a normal range.\n\nBest wishes! 💖", 

"Hello {{name}},\n\nI've taken a look at your ECG and it displays a Sinus Arrhythmia, a perfectly normal variation in heart rate that usually reflect changes in your breathing. Your PQRST intervals are also regular, and there's a complete absence of ectopic beats.\n\nTake care! ✨", 

"Hi {{name}} 💕,\n\nSinus Arrhythmia is what your ECG is showing, which is a rhythmic fluctuation of the heart rate associated with breathing. This is a typical behavior, and nothing you should worry about. All other aspects of your ECG, including the PQRST intervals, are completely normal.\n\nKeep smiling! 😊", 

"Hi {{name}}!\n\nYour ECG review is in, and it shows a Sinus Arrhythmia. No worries, though — it's a perfectly normal heart rhythm that just shows a slight fluctuation with your breathing. Your PQRST waves are spot on, and I didn't find any ectopic beats.\n\nHave a great day! 😄",

"Hello {{name}}!\n\nI've checked your ECG and it reveals Sinus Arrhythmia, which is a common and perfectly benign variation in heart rhythm. It's linked to your breaths, causing slight changes in heart rate. Your PQRST waves are consistently normal, with no ectopic beats to speak of.\n\nStay vibrant! 🌟",

"Hi there {{name}}!\n\nI've gone over your ECG and everything looks good. You've got a Sinus Arrhythmia, which is a natural variation in heart rhythm due to breathing. Your PQRST waves are in perfect shape, and there's no evidence of ectopic beats.\n\nAll the best 💖",

"Hey {{name}}!\n\nJust finished looking at your ECG. You have what's called Sinus Arrhythmia, but that's just a fancy way of saying your heart rhythm naturally changes with your breath—totally normal. PQRST waves? Perfect. Ectopic beats? None.\n\nLook after yourself and keep smiling! 😊", 

"Hi {{name}}!\n\nYou caught Sinus Arrhythmia in your recording, but that's usually just a normal response to breathing—nothing to stress over. Your PQRST waves are exactly as they should be, and there are zero ectopic beats.\n\nCheers to your wellbeing! 🥳",

"Hey {{name}}!\n\nYour ECG came through, and it's showing a Sinus Arrhythmia. It's a normal finding, reflecting how your heart rate adapts to your breathing. PQRST waves? Normal. Ectopic beats? Nowhere to be found.\n\nKeep taking fabulous care of yourself! 🌟",

"Hello {{name}}!\n\nYour ECG presents a Sinus Arrhythmia, which is usually simply a variation in heart rhythm that occurs with breathing—completely normal. Your PQRST waves are looking good, and there’s not an ectopic beat in sight.\n\nKeep on taking outstanding care of yourself! ✨",

"Greetings {{name}}!\n\nI've reviewed your ECG and it's textbook Sinus Arrhythmia—no cause for concern, as it's a variation that happens with breathing and it’s still considered normal. Everything else, including your PQRST waves, is normal.\n\nTake care! ❤️",

"Greetings {{name}}!\n\nI've reviewed your ECG and it's textbook Sinus Arrhythmia—no cause for concern, as it's a variation that happens with breathing. Everything else, including your PQRST waves, is normal. Ectopic beats? None to be found.\n\nTake extra good care and keep beaming positivity! 😃",

"Hi {{name}},\n\nJust finished analyzing your ECG and it looks like you have Sinus Arrhythmia. This is a normal rhythm where your heart rate naturally speeds up and slows down with your breathing cycle - nothing to worry about! Your PQRST waves are looking fantastic, and I didn't spot any ectopic beats.\n\nHave a good day! 😄", 

"Hello {{name}},\n\nI've reviewed your ECG and everything looks fine. You've got a Sinus Arrhythmia, which is a common pattern where your heart rate varies with your breathing. It's nothing unusual, and not something you should worry about. Plus, your PQRST waves are all within the normal range, with no ectopic beats to report.\n\nAll the best for your wellbeing! 🌷",

"Hey {{name}},\n\nYour ECG has shown a pattern known as Sinus Arrhythmia. It's a harmless variation where your heart rate fluctuates as you breathe in and out. I'm happy to say that your PQRST waves are normal and there are no ectopic beats.\n\nContinue taking good care of your heart! 🌟", 

"Greetings {{name}},\n\nYour ECG indicates Sinus Arrhythmia, which is a perfectly normal and often benign variation in heart rhythm related to your breathing. Everything else, including your PQRST waves, looks great and there are no ectopic beats as well.\n\nBest wishes for your health! 💓", 

"Hi {{name}} 😃,\n\nI've taken a thorough look at your ECG and it shows Sinus Arrhythmia, a natural variation in heart rhythm that's often seen in healthy individuals (even athletes). Your PQRST waves are normal, and I found no ectopic beats.\n\nTake care! 💖",

"Hello {{name}},\n\nI've inspected your ECG and found a Sinus Arrhythmia, which simply means your heart rate varies with your breath. Sinus Arrhythmia sounds scary, but it’s nothing to worry about - it’s still consider normal heart rhythm. The PQRST waves are exactly where they should be, and I didn’t find any abnormal beats.\n\nWishing you all the best! ✨", 

"Hey {{name}},\n\nYour ECG reading shows a Sinus Arrhythmia, but there's no need to worry—it's a common and typically normal heart rhythm (it means that your rhythm varies with your breathing pattern). Your PQRST waves are normal, and there are no ectopic beats like PVCs and PACs.\n\nTake care and enjoy life! 🌻", 

"Hi {{name}} 😃,\n\nYour ECG suggests Sinus Arrhythmia, which is still a normal heart rhythm variation that happens with your heart rate changes with your breathing. The PQRST waves are looking good, and I'm not seeing any ectopic beats, which is excellent.\n\nKeep focusing on your wellness! 💕", 

"Hello {{name}} ❤️,\n\nYour ECG has been reviewed, and it shows Sinus Arrhythmia, a regular rhythm variation linked to breathing. This is a standard finding, even in the young or fit people. Your PQRST waves are normal, and there's an absence of extra beats, so it's all good news.\n\nBest regards! 😊",

"Hello {{name}},\n\nYour ECG indicates a presence of Sinus Arrhythmia, which might sound worrying, but it's actually a common variation of normal heart rhythm that can happen when you breathe in and out. It doesn't signal any problem, especially since your PQRST intervals are consistent and there are no ectopic beats.\n\nAll the best! 😊",

"Hey {{name}},\n\nI've reviewed your ECG and it shows a rhythm known as Sinus Arrhythmia. While the term might seem daunting, it's often a benign variation that means that your rhythms is changing  with your breathing. Your PQRST intervals looks great, and there are no ectopic beats.\n\nHave a great day 👍", 

"Hi {{name}},\n\nUpon examining your ECG, there's a noticeable pattern of Sinus Arrhythmia. Although it involves variations in heart rate related to your breathing, it's generally not a cause for concern given the absence of other irregularities. Your PQRST intervals are steady, and abnormal beats are not present.\n\nTake care!", 

"Greetings {{name}} 😁,\n\nYour ECG shows a pattern of Sinus Arrhythmia, which is a fluctuation in heart rate with your breaths. While this might sounds bad, it's a common finding and often not problematic, especially since your PQRST values are all normal and I didn’t find any ectopic beats. It shouldn't cause you any undue stress.\n\nBest regards! 😊",

"Hi {{name}} 😁,\n\nYour ECG has revealed Sinus Arrhythmia, a variation in heart rate during breathing. It's a relatively common finding and, in the absence of other symptoms or ectopic beats, it's not typically concerning. Your PQRST intervals are normal, which is reassuring as well.\n\nWishing you well! 🌟", 

"Hello {{name}},\n\nA look at your ECG suggests Sinus Arrhythmia, where your heart rate changes with your breath. While it's a deviation from the textbook sinus rhythm, it's usually not worrisome and still consider normal heart rhythm. Your PQRST is normal, and no ectopic beats were detected.\n\nWishing you great day 😄", 

"Hey {{name}} 😉,\n\nYour ECG reveals Sinus Arrhythmia, a condition where your heart rate varies with your breathing. It’s consider a normal finding, especially since your other heart rhythm markers like PQRST are within normal limits and no ectopic beats are seen. It's something you should worry about, {{name}}.\n\nAll the best!", 

"Hello {{name}} 💖,\n\nI've observed Sinus Arrhythmia on your ECG, a pattern where your heart's rhythm syncs with your breathing cycle. Though it’s sounds scary, it's consider normal rhythm and not something you have worry about. Especially when your PQRST intervals are normal, and you don’t have any abnormal beats or symptoms.\n\nBest! 😉", 

"Hi {{name}} 😄,\n\nYour ECG review indicates Sinus Arrhythmia. It's a fancy term for a simple thing: your heart beats a little differently when you breathe in and out. It’s still consider normal rhythm and it’s not something you should worry about! Alongside this, your PQRST waves are looking good, and there are no signs of extra beats.\n\nAll the best! ❤️", 

"Hi {{name}},\n\nGood news from your ECG – you have Sinus Arrhythmia, which, despite the name, is a common and typically harmless variation in heart rhythm linked to your breathing. Everything else looks great, including your PQRST interval. Additionally I didn’t find any ectopic beats.\n\nHave a great day!",

"Hey there {{name}}! 😄\n\nTook a peek at your ECG and found Sinus Arrhythmia, but that's just medical lingo for a heart rate that changes when you breath in and out – totally normal. Your PQRST pattern is on point, and ectopic beats are nowhere to be seen.\n\nCheers to a great day! 🎈",

"Dear {{name}} 💗,\n\nI've reviewed your ECG and it shows you have Sinus Arrhythmia. Sounds serious, but it’s not (it’s still consider normal rhytm)! It's quite a natural thing where your heart rate fluctuates with breathing. Plus, your PQRST is normal, and there's no ectopic beats.\n\nTake a deep breath, relax, and savor the day. 🌸", 

"Greetings {{name}},\n\nA glance at your ECG and there it is – Sinus Arrhythmia. But here’s the kicker, it’s nothing unusual! It’s actually pretty common and harmless, showing your heart's rhythm changes with your breaths. And with a normal PQRST and no ectopic beats, it’s not something you should worry about, {{name}}.\n\nHave a great day! 🌟", 

"Hey {{name}} 👋, \n\nJust finished going over your ECG, and you've got Sinus Arrhythmia. It's a normal, natural variation that many people have (even athletes). Your heart's just keeping up with your breathing! Plus, your PQRST waves are looking perfect, and ectopic beats are a no-show.\n\nStay cheerful and keep smiling! 😊", 

"Hello {{name}}! 🌼\n\nYour ECG's in, and it shows Sinus Arrhythmia. It’s a common pattern where your heart rhythm naturally speeds up and slows down with your breath – don’t worry, it still consider normal rhythm 😃. The rest of your heart's signals, like the PQRST, are right where they should be. \n\nHave a delightful day ahead! 🦋", 

"Hey {{name}} 👋, \n\nI've got your ECG results, and they show Sinus Arrhythmia. It sounds scary, but it’s still consider normal finding – your heart rhythm just sync up with your breathing. Plus, your PQRST is textbook perfect, and there's not a single ectopic beat in sight.\n\nTake care! 🎉",

"Hi {{name}} 💕,\n\nYour ECG came through, and you have Sinus Arrhythmia, but there's no cause for concern, {{name}}. It's a common rhythm variation that corresponds with your breathing cycle. Plus, your PQRST waveforms are normal, and ectopic beats are missing – that's excellent news!\n\nJust keep taking care of yourself and enjoy your days to the fullest! 🌺",

"Hello {{name}},\n\nFirst off, your heart's PQRST pattern is just as it should be – stellar! Now, about that Sinus Arrhythmia on your ECG – it's your heart's natural rhythm, varying with your breathing. And let's not forget, no ectopic beats in sight. The conclusion? Your recording looks pretty good, and it’s not something you should worry about.\n\nAll the best! 💖",

"Hi {{name}}, Just finished looking over your ECG results, and everything appears to be normal. A sinus arrhythmia is present, which, as you might know, is just a natural variation in heart rhythm. It's often seen even in young, healthy individuals and isn't a cause for concern. The PQRST intervals are regular, and I didn't detect any unusual heartbeats in the recording!\n\nTake care! 💖",

"Hey {{name}}, I've reviewed your recent ECG, and the results are reassuring. You caught sinus arrhythmia, which is just a fancy way of saying your heart rate shows some variability (it’s speeds up when you breath in, and slow down when you breath out). This is perfectly normal! All your PQRST values are within expected limits, and there were no abnormal beats noted.\n\nBest wishes! 💗",

"Greetings {{name}},\n\nAfter reviewing your ECG, I'm pleased to inform you that everything seems to be looking good. Sinus arrhythmia is just a fancy way of saying your heart rate changes with your breathing, but rest assured, it's usually completely harmless ❤️. It's a natural variant often found even in young, robust individuals and is nothing out of the ordinary for an ECG. Every other measure is right on target, with no abnormal beats detected.\n\nWishing you wellness and joy! 💖", 

"Hi {{name}},\n\nUpon analyzing your ECG, the results are reassuringly normal. The presence of sinus arrhythmia might sound concerning, but it’s only means that your heart rate changes when you breath in and out - it’s not something you should worry about (it’s consider normal) ❤️. Your PQRST intervals looks great, and I didn’t find any odd beats in your recording.\n\nAll the best! 😊",

"Hello {{name}},\n\nI've gone over your ECG, and I'm happy to say it's all looking very standard. Sinus arrhythmia is a term that might seem a bit intimidating, signifying an irregular beat, but it's actually quite innocent ❤️. It's simply means that your heart rate changes with your breathing pattern. Every other indicator is within the expected range, and your recording is free of any abnormal beats.\n\nBest wishes! 💕",

"Greetings {{name}},\n\nUpon reviewing your ECG, it's clear that everything is looking normal. The presence of sinus arrhythmia suggests an irregular heartbeat pattern, but this isn't a cause for concern ❤️. It's means that your heart rate changes when breath in and breath out (it’s consider 100% normal). Your PQRST values are all within expected ranges, with no odd heartbeats noted. That's excellent! 😊\n\nWishing you good health! 🌟", 

"Hi {{name}},\n\nHaving analyzed your ECG, I can confirm that your recording look good. Sinus arrhythmia is present, which means your heart rate varies slightly with your breathing – this is a normal physiological phenomenon, even in young and healthy individuals, and usually is not indicative of any health issues ❤️. All other aspects of your ECG are regular, with no unusual heart beats.\n\nTake care! 🌈", 

"Hi there {{name}},\n\nYour ECG shows a pattern known as sinus arrhythmia, where your heartbeat changes rate during breathing. I know it can sounds scary, but there's no need for concern, it’s still consider normal rhythm ❤️. Aside from this, your ECG readings are all normal, indicating no irregular heartbeats as well.\n\nBest health wishes to you! 🌟", 

"Hello {{name}},\n\nI've looked over your ECG and found it to be normal, including a sinus arrhythmia, which is a common and harmless variation in the heartbeat related to the breathing cycle, seen even in healthy individuals ❤️. It's a natural reflection of the heart's ability to adjust and is not worrisome. Every other values on your ECG is consistent with a norm, with no unexpected beats.\n\nHave a great day! ✨", 

"Good day {{name}},\n\nYour ECG indicates a sinus arrhythmia, a perfectly normal and healthy variation where your heart rate changes with your breathing cycle. Rest assured, all other indicators are also well within normal limits, and there are no unusual beats to mention ❤️.\n\nBest wishes for you, {{name}}! 🌻",

"Hello {{name}},\n\nUpon examining your ECG, I noticed a sinus arrhythmia, which is an entirely normal rhythm variation that syncs with your breaths ❤️. This is seen even in young, physically active people and usually isn't a concern. All your PQRST values are within the normal range, and there are no extraordinary beats.\n\nStay well and in good spirits! 🍀", 

"Hi {{name}},\n\nYour ECG looks good. Including a sinus arrhythmia, which is just way of saying that your heart beat changes with breathing - it’s common and normal (and not harmful) ❤️. It's a sign of your heart's natural rhythmic adaptability. There's nothing else out of the ordinary, and no abnormal beats were found.\n\nWishing you good health and happiness! 🌼",

"Hello {{name}},\n\nI'm pleased to tell you that your ECG is normal, showing a sinus arrhythmia, which is a slight change in the heart rate that naturally occurs with breathing - this is not something you should be concern with ❤️. Everything else on your ECG is also within the expected normal range, with no abnormal heartbeats.\n\nWarm regards and best health! 🌟",

"Greetings {{name}},\n\nI'm happy to report that your ECG is looking good! You might have noticed your heart rate increases when you breathe in and decreases when you breathe out – this is sinus arrhythmia, a normal heart rhythm pattern. It's nothing unusual and not something to worry about.\n\nBest regards and stay well! 😊"
    ];
})();

export default sinus_arrythmia;