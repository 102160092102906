import React, { useContext, useEffect, useState } from 'react'
import { Paper, Grid, TextField, Button, FormControlLabel, Checkbox, Typography, LinearProgress, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors'
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth.context';
import { TryOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => {
  return {
    margin: {
      margin: "40px",
    },
    padding: {
      padding: "10px",
    },
    container: {
      minHeight: '90vh',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }
})

const OtpPage = () => {

  const { loading, error, verifyOtp, resendOtp, loginVerified, useremail } = useContext(AuthContext)
  const classes = useStyles()
  const history = useHistory()

  const [otpCode, setOtpCode] = React.useState('')

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      verifyOtp(otpCode)
    }
  }

  useEffect(() => {
      if (loginVerified === "true" || loginVerified === true) {
        history.push('/live')
      }
  }, [loginVerified, verifyOtp])

  useEffect(() => {
    console.log("Call at the first load")
  }, [])

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >

      <Grid item  >
        
        <Paper className={classes.padding}>

          <div className={classes.margin} >
            <Grid container justifyContent="center" spacing={2} alignItems="flex-end" >
              <Grid item align="center" md={true} sm={true} xs={true}>
                <Typography variant="h5">Enter OTP Code (6 numbers)</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={4} alignItems="flex-end">
              <Grid item align="center" md={true} sm={true} xs={true}>
                <Typography variant="subtitle1">
                  Please check your email ({useremail}) after getting the OTP
                </Typography>
              </Grid>
            </Grid>
            {error ? (
              <Grid
                container
                justifyContent="center"
                spacing={4}
                alignItems="flex-end"
              >
                <Grid item align="center" md={true} sm={true} xs={true}>
                  <Typography variant="body2" color="error">
                    * OTP is invalid, please check again!
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true} style={{ marginTop: "20px" }}>
                <TextField
                  id="otpcode"
                  label="OTP Code"
                  type="text"
                  variant="outlined"
                  autoComplete='off'
                  className={classes.formInput}
                  onChange={(e) => setOtpCode(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e)}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: '30px' }}
              alignItems="flex-end"
            >
              <Grid item md={true} sm={true} xs={true}>
                <div style={{ position: 'relative' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'none' }}
                    fullWidth
                    onClick={() => verifyOtp({ otp: otpCode})}
                  >
                    Verify
                  </Button>
                </div>
              </Grid>
            </Grid>


            {error ? (
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: '30px' }}
              alignItems="flex-end"
            >
              <Grid item md={true} sm={true} xs={true}>
                <div style={{ position: 'relative' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'none' }}
                    fullWidth
                    onClick={() => resendOtp()}
                  >
                    Resend the OTP
                  </Button>
                </div>
              </Grid>
            </Grid>
            ) : 

            <Grid
              container
              justifyContent="center"
              style={{ marginTop: '30px' }}
              alignItems="flex-end"
            >
              <Grid item md={true} sm={true} xs={true}>
                <div style={{ position: 'relative' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'none' }}
                    fullWidth
                    onClick={() => resendOtp()}
                  >
                    Get the OTP
                  </Button>
                </div>
              </Grid>
            </Grid>

            }

          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default OtpPage
