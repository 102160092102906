const sinus_bradycarida_1 = (function () {
    return [
"Hi {{name}},\n\nI am pleased to inform you that your ECG results are looking lovely! Your heart rate might be a bit lower than average but don't stress about it. It could be that you're simply relaxed, or you have a naturally low resting heart rate. All other aspects are well within the normal limits, and no abnormal beats were detected. Overall, everything is looking A-OK!\n\nHave a good day! 😄",

"Hey {{name}},\n\nI have heartwarming news for you: your ECG looks pretty good! Your heart rate is slightly lower than expected, but there's no need to worry at all. It's likely just because you're feeling at ease or your usual resting heart rate is on the lower side. All other measurements are absolutely standard, and no abnormal beats were observed. In summary, all is well!\n\nTake care! ❤️",

"Hi {{name}},\n\nI am excited to tell you that your ECG is looking really nice! Your heart rate is a bit lower than the norm, but there's no need to worry. It might be because you're feeling calm or your regular resting heart rate tends to be low. All other measurements are perfectly normal, and no abnormal beats were observed. In summary, all is well and looking good!\n\nHave a good day 😃",

"Hey {{name}},\n\nI am thrilled to inform you that your ECG looks pretty good! Your heart rate is somewhat lower than expected (it's called bradycardia), but there's no need to be concerned. It could just be that you're relaxed, or your standard resting heart rate is naturally low. All other aspects are perfectly within normal limits, and no abnormal beats were found.\n\nTake care and stay safe! 💖",
        
"Hi {{name}},\n\nI have carefully checked your ECG and found that your heart rate is a bit slow, but rest assured, your rhythm is normal and regular. All other measurements are within the norm, and there are no concerning extra beats. This is excellent news!\n\nSending you lots of warm thoughts! 😄",

"Hey {{name}},\n\nAfter going through your ECG, I noticed that your heart rate is slightly slower than usual (this is what bradycardia means), but there's no need to be concerned! Sinus Bradycardia is still considered a normal finding  🙂 Your overall rhythm is fantastic, and all PQRST measurements are perfectly normal. Moreover, there are no abnormal extra beats in your recording!\n\nHave a good day! 😊",

"Hi {{name}}, \n\nAfter inspecting your ECG, I observed that your heart rate is on the slow side (below 60 BPM). But please don't worry about it (it’s considered normal!). Your overall rhythm is excellent, and all other measurements are perfectly regular. What's more, there are no abnormal extra beats in your recording! 😃\n\nTake care! ❤️",

"Hey {{name}},\n\nAfter reviewing your ECG, I noticed that your heart rate is slightly slower than the norm. Your rhythm looks normal, and all other measurements are standard as well. Plus, there are no abnormal extra beats, which is truly fantastic news! A lower heart rate without any other symptoms and abnormalities in your recordings is still considered normal, and it’s typically not a cause for any stress\n\nSending you lots of warm thoughts and care! 🤗",

"Hi {{name}},\n\nI have looked into your recording and found that your heart rate is a tad slow (below 60 BPM), which can be attributed to factors such as high fitness level, relaxation, genetic predisposition, or medications. But don't be concerned! Your overall rhythm is fantastic, and PQRST intervals are within the normal range. No abnormal extra beats were detected, which is truly great news!\n\nTake care! 😊",

"Hey {{name}} 💖,\n\nI'm so happy to inform you that your ECG looks pretty good! Your heart rate is a bit on the slow side at {{heartrate}}, but please don't worry about it. It could just be because you're feeling calm or your usual resting heart rate is lower. Also, some medications might have an impact on your heart rate as well.\n\nSending you lots of warm thoughts! 😃",

"Hi {{name}},\n\nYour ECG results are looking good! Your heart rate may be a bit below the norm (less than 60 BPM), but there's no need for concern. It could simply be that you're feeling relaxed (hopefully this is the case), or your typical resting heart rate is lower than average. Also, please remember that certain medications can contribute to a slower heart rate.\n\nTake care! 😊",
  
"Hey {{name}},\n\nYour ECG looks normal and regular! Your PQRST waves are within the normal range, and there are no ectopic beats to worry about. Your heart rate is a little slower than expected, but please don't stress. It might just be because you're calm, or your average resting heart rate is naturally low. Also, some medications can cause lower heart rate.\n\nHave a good day 😃",

"Hi {{name}} 👋,\n\nIt's my pleasure to inform you that your rhythm is normal :) Your PQRST measurements are within the norm, and there's no sign of ectopic beats. Your heart rate is slower than anticipated (below 60 beats per minute), but rest assured, it might just be because you're at ease or your normal resting heart rate is on the lower side. Keep in mind that some medications can affect your heart rate as well.\n\nTake care! 😌",

"Hey {{name}} 💙,\n\nI'm happy to report that your recording looks normal and regular! Your PQRST waves are all standard, and there are no abnormal beats present. Your heart rate is a bit on the slow side, but please don't worry. It could just be because you're feeling calm and relaxed or your usual resting heart rate is lower. Also, some medications might have an impact on your heart rate.\n\nTake care and continue to look after your heart! 😃",

"Hello {{name}}! 😊\n\nI'm delighted to share that I've looked over your ECG results, and everything appears to be looking good! 🎉 There were no unusual beats detected, and aside from your heart rate, all values are perfectly normal. Your heart rate is a bit lower than the typical range (below 60 BPM), but there's usually no need for concern 😌. This is quite common and usually not a problem.\n\nA few potential causes for a lower-than-normal heart rate can include being physically fit, certain medications, or even just your body's natural rhythm. \n\nTake care and continue to look after your heart! ❤️",

"Hi {{name}},\n\nI'm happy to tell you that after reviewing your ECG results, everything seems to be looking good! No unusual beats were found, and all values, except for your heart rate, are within the norm. Your heart rate is slightly below the average range (below 60 BPM), but there's no need to worry. 😊 This is often completely normal.\n\nSome reasons for a lower-than-usual heart rate could be physical fitness, certain medications, or simply your body's natural rhythm.\n\nTake care! ❤️",

"Hey {{name}},\n\nI've checked your ECG results, and your rhythm seems to be nice and regular! 🎉 No ectopic beats were detected, and all the values, other than your heart rate, are typical. Your heart rate is a bit lower than usual (below 60 beats per minute), but rest assured, this is commonly normal. 😌\n\nSome factors that might cause a lower than average heart rate are being physically fit, taking certain medications like beta blockers, or just genetics.\n\nTake care and keep your heart happy! ❤️",

"Hi {{name}},\n\nYour ECG looks pretty nice. 😃 Your heart rate is a bit low (below 60 BPM), but all other measurements are right where they should be. Plus, no extra beats were detected! A slower heart rate is generally normal and might be due to your typical heartbeat or a calm state of mind.\n\nTake care! 😌",

"Hello {{name}}, \n\nYour ECG is looking nice and clean! 🌟 Your heart rate is a bit low (as it's below 60 BPM), but all other values are in their ideal ranges. No abnormal beats were seen as well! A slower heart rate is usually a normal finding, and it could be your natural rhythm or a result of being relaxed (also, some medications can impact your heart rate). 😊\n\nI hope this helps! Always take care 💖",

"Hi {{name}},\n\nYour ECG results are in, and they look pretty good! 😄 Sinus Bradycardia is a normal heart rhythm with a rate under 60 BPM. Rest assured, no ectopic beats were detected, and your PQRST is perfectly normal. Possible causes for Bradycardia include physical fitness or just being calm. It's generally nothing to worry about. \n\nTake care and continue to look after your heart! 🥰",

"Hello {{name}} 👋,\n\nYour ECG shows Sinus Bradycardia, which is a normal heart rhythm with a heart rate below 60 BPM (you are at {{heartrate}} BPM). No ectopic beats were found, and your PQRST values are in the normal range. Common causes can be athletic training, simply being at ease, or certain medications. Remember, it's usually normal and not a cause for concern.\n\nSending you lots of warm thoughts! 😊",

"Hi {{name}},\n\nYour ECG results indicate Sinus Bradycardia - regular and normal heart rhythm with a heart rate below the 60 BPM 😊. There are no ectopic beats, and your PQRST measurements are perfectly normal. Common causes like being athletic, feeling relaxed, or some medications like beta blockers might explain it. It's typically normal and not a cause for worry at all.\n\nI hope everything is alright and stay safe. 🤗",

"Hey {{name}},\n\nYour ECG results are in, and they look pretty good! Sinus Bradycardia (normal rhythm with heart rate below 60 BPM) is displayed, but rest assured, it's often normal and nothing to worry about. I didn’t observe any ectopic beats, and your PQRST intervals are within the norm! Sinus Bradycardia is caused by being athletic, being calm, or even using certain medications.\n\nI hope this helps! Always take care 💖",

"Hi {{name}},\n\nYour ECG looks amazing! 😊 I found a regular rhythm with a slightly lower than standard heart rate (below 60 BPM). No ectopic beats were found as well, and your PQRST intervals are normal. Sinus Bradycardia is typically harmless and can be due to fitness or relaxation (it’s also considered normal heart rhythm 🤗). \n\nTake care! ❤️",

"Hey {{name}},\n\nYour ECG shows a regular rhythm and a lower-than-normal heart rate (it's called Sinus Bradycardia). No ectopic beats were observed, and your PQRST intervals are excellent. Sinus Bradycardia is usually not a cause for concern and can result from being athletic or just being calm (I hope this is the case).\n\nTake care! 😊",

"Hi {{name}} 💗,\n\nYou have a regular rhythm with a slightly lower heart rate (Sinus Bradycardia). I didn't find any ectopic beats, and your PQRST intervals are well within the norm. Sinus Bradycardia is typically harmless and can be due to certain medications, physical fitness, or just being really relaxed.\n\nSending you lots of warm thoughts! 🤗",

"Hello {{name}},\n\nYour recording shows a normal and regular rhythm with a heart rate slightly below the average level (60-100 BPM). 😇 No ectopic beats were found, and your PQRST intervals are excellent. Sinus Bradycardia is usually harmless and can result from medications, being in good shape, or being calm. \n\nTake care! 😊",

"Hi {{name}},\n\nI've gone through your ECG, and it's looking nice! Your rhythm is nice and normal, but your heart rate is slightly slower than expected (below 60 BPM). Your PQRST intervals are just right, and I didn't find any ectopic beats. Lower than normal heart rate can be caused by being fit, some medications, or your own unique rhythm. There's no cause for concern, this is still considered a normal finding! \n\nI hope this helps! Take care and stay safe. 💖",

"Hello {{name}}, \n\nI've examined your recording, and it looks like you caught Sinus Bradycardia. You have an amazing and regular rhythm with a heart rate that's a bit lower than usual. Sinus bradycardia can be attributed to physical fitness, specific medications like beta blockers, or your body's inherent rhythm. Rest assured, your results are considered perfectly normal! \n\nTake care! ❤️", 

"Hi {{name}},\n\nI've reviewed your ECG, and it's looking pretty good! Your heart rhythm is great, though your heart rate is a bit lower than average (it's below 60 BPM). But please don't worry about it, it's usually a normal finding 😄. Lower heart rate might be due to being in great shape, certain medications, or it's just your own distinct rhythm. Additionally, I didn't spot any ectopic beats, and your PQRST intervals are well within the norm!\n\nTake extra good care! 💖",

"Hey {{name}} 👋,\n\nI've reviewed your ECG and noticed that your rhythm is normal and regular but with a slightly lower than usual heart rate (at {{heartrate}} BPM, you are below 60 BPM). No ectopic beats were detected, and your PQRST intervals are excellent. Lower than usual heart rate could be due to factors like being physically fit, taking certain medications, or just your body's unique rhythm. To sum up, your results are typical and not a cause for concern! \n\nTake care and stay safe! 💖",

"Hi {{name}} 😄,\n\nI've examined your ECG and noticed that you caught a rhythm called Sinus Bradycardia (it's a normal rhythm with a heart rate below 60 BPM). Additionally, I didn't spot any ectopic beats, and your PQRST measurements are ideal. A heart rate below 60 BPM might be caused by being in good physical shape, certain medications, or your body's distinct rhythm.\n\nTake care 💖",

"Hello {{name}},\n\nI've checked your ECG and determined that your rhythm is normal and regular, but your heart rate is a touch lower than typical (it's below 60 BPM). This is generally a normal finding and might be due to factors such as being in top shape, certain medications, or simply your body's natural rhythm. No ectopic beats were detected, and your PQRST intervals are well within the norm. Don't worry; your results are normal and nothing to stress over! \n\nTake care! 💗",

"Hi {{name}},\n\nI've analyzed your ECG and observed that your rhythm is nice and normal, with a heart rate that's slightly below average (it's called Sinus Bradycardia). There are no ectopic beats in your recording, and your PQRST intervals are standard as well 🥰. This is often a normal finding and can be attributed to good physical fitness, certain medications such as beta-blockers, or your body's distinct rhythm. There's no need to worry – your results are considered absolutely normal! \n\nTake care 💖",

"Hello {{name}} 🤗,\n\nI've looked at your recording and found a rhythm called Sinus Bradycardia. This means that your rhythm is normal and regular, but your heart rate is slightly lower than average. This is very common and might be due to factors like being in excellent physical condition, certain medications, or your body's unique rhythm.\n\nI hope this helps! Take care and stay safe.💖  ",

"Hi {{name}},\n\nI'm thrilled to share that your rhythm looks fantastic and regular! Your heart rate is slightly below the average, but there's no cause for concern. It could be due to your calm state or your natural resting heart rate being lower. All other measurements are perfectly fine, and no irregular beats were detected. Overall, everything is looking good! 😊\n\nTake extra good care! 💖",

"Hey {{name}} 💙,\n\nYour heart rate is a bit lower than usual (this is what Bradycardia means), but there's no reason to worry. Your rhythm is still fantastic and regular - bradycardia is considered a normal finding.  It might be a result of being relaxed or having a naturally low resting heart rate. All other values are perfectly normal, and no abnormal beats were found. In conclusion, all is looking pretty good!\n\nTake care! 😄",

"Hello {{name}} 💖,\n\nUpon reviewing your ECG, I noticed that your heart rate is a bit slower than average (which is what Bradycardia refers to), but don't worry, it is still a normal finding! Your overall rhythm is excellent, and all PQRST measurements are excellent. Additionally, no abnormal extra beats were found in your recording!\n\nTake care! 😄",

"Hi {{name}},\n\nAfter examining your ECG, it appears that your heart rate is a bit slower than typical at {{heartrate}} BPM (this is called Bradycardia), but there's no cause for concern as it's still considered normal ECG finding! Your overall rhythm is outstanding, and all PQRST measurements are entirely normal. Plus, there are no unusual extra beats present in your recording! \n\nTake extra good care! 😁",

"Hey {{name}},\n\nAfter assessing your ECG, I found that your heart rate is slower than usual (this is referred to as Bradycardia. Fun fact: brady in Greek means slow 🤗), but there's absolutely no need to be alarmed as it's still a normal finding! Your overall rhythm is great, and all PQRST measurements are perfectly within the norm. What's more, there are no abnormal extra beats in your recording, which is awesome news! 😃\n\nI hope everything is alright and stay safe. 💖",

"Hi {{name}} 👋,\n\nUpon evaluating your recording, I observed that your heart rate is a bit slower than expected (below 60 BPM). Please don't be concerned about it, as it's still a normal finding as your overall rhythm is top-notch. All PQRST measurements are perfectly normal. Also, there are no unusual extra beats present in your recording! \n\nTake care and stay safe! 💖",

"Greetings {{name}},\n\nI've analyzed your ECG, and it shows that your heart is beating at a slower-than-expected pace (below 60 BPM). This is known as Sinus Bradycardia - it's a standard rhythm for many, especially those who are physically fit or just naturally calm (also, some medications can cause it). Rest assured, every beat is steady and regular.\n\nKeep up the good health habits! 💖",

"Hi {{name}} 🌹,\n\nI'm pleased to report that your heart rhythm is looking pretty nice! It's a touch on the slower side, indicative of Bradycardia (Brady means slow in Greek), but for many, it's a sign of a well-conditioned heart (it can also be caused by some medications like beta-blockers). No extra beats like PVCs or PACs were noted in your recording as well.\n\nHave a great day {{name}}! 😊",

"Hello {{name}} 💓,\n\nAfter a careful look at your ECG, your heart's rhythm, though a bit slow, is perfectly regular and normal (that's what we call Bradycardia). It's a common finding in many healthy individuals. All your heart's signals are right on point, with no unusual activity.\n\nAll the best! 😄", 

"Good day {{name}},\n\nYour ECG results are in, and they reveal a slower than average heartbeat with still great and regular like clock rhythm 🕔. It's actually quite normal, especially in well-trained individuals or as a personal baseline. The rest of your heart's metrics are stellar!\n\nAll the best 😃", 

"Hi {{name}} 🔆,\n\nUpon inspecting your ECG, I've found that your heart beats a bit slow at {{heartrate}} BPM - it’s known as Bradycardia. It's a standard heart rhythm and could be a reflection of your fitness level or simply your heart's natural pace. Also, medications like beta-blockers can cause it. Everything else looks superb! 😃\n\nHave a great day!",

"Hello {{name}} 🌻,\n\nYour ECG depicts a slow heart rate (below 60 BPM), which we refer to as Bradycardia (Sinus part means that rhythm is still fantastic and regular). It's a common finding among the healthy and active. To sum up, there is nothing alarming about your recording. 💖", 

"Greetings {{name}} 😄,\n\nI'm happy to report that your ECG findings are reassuring! Your rhythm is Sinus Bradycardia, which simply means your heart rate is under 60 BPM, but your rhythm is excellent and regular. This can be perfectly normal, especially for athletes or those who are simply relaxed or on certain medications. The rest of your ECG, including the PQRST intervals, is textbook normal. No unusual beats were found!\n\nStay well and safe! 💖",

"Hi {{name}} 😊,\n\nI've reviewed your ECG, and everything looks pretty nice. You're showing a Sinus Bradycardia rhythm, which is a fancy way of saying your heart is beating a bit slower than the norm, but it's often seen in healthy individuals as well. PQRST intervals are all normal, and no ectopic beats are in sight. This can be a sign of good fitness, a natural calm state, or the effect of certain medications.\n\nHave a happy day! 💖",

"Hello {{name}},\n\nYour ECG results have arrived, and they're looking good! Your heart's rhythm is in Sinus Bradycardia, indicating a heart rate below 60 BPM. This isn't unusual and is often a sign of good cardiovascular fitness, a relaxed state, or sometimes medication effects. The most important thing is it’s a normal finding! All your PQRST intervals are spot on, with no ectopic beats detected.\n\nTake care and keep smiling! 💖",

"Hi {{name}} 💙,\n\nYour ECG shows a rhythm called Sinus Bradycardia, which means your heart rate is slightly slower than average (below 60 BPM), but your rhythm is as good as it can be (basically, your heart rate is playing the great song, keeping a nice rhythm, but the song is a bit slower than usually). This is common among individuals with high fitness levels or those who are very calm or on certain meds. Your PQRST intervals look perfect, and there's not a single ectopic beat in sight\n\nStay safe and take care! 💖",

"Greetings {{name}} 💕,\n\nUpon reviewing your recent heart recording, I've noticed your heart rate is on the slower side, dipping below 60 BPM (You are at {{heartrate}} BPM). This can often be seen in individuals who are exceptionally fit, naturally relaxed, or have a genetic inclination toward a lower heart rate. Fortunately, there's nothing to worry about. Your heart rhythm is looking excellent, and your PQRST intervals are comfortably normal. Plus, there are no extraneous beats – which is excellent!\n\nHave an amazing day! 😊",

"Hello {{name}},\n\nYour recording indicates a slightly slower beat, under 60 BPM. This is sometimes the case with those who are very active, innately calm, or have a genetic leaning towards a slower pace (also, some meds can cause changes in heart rate). Importantly, your heart's overall rhythm is stellar, and the PQRST intervals are well within the norm. And with no extra beats detected, it's all positive news!\n\nBest regards and good health! 😊",

"Hey there {{name}},\n\nJust finished analyzing your recording. Your heart it's a bit on the lower side with {{heartrate}} BPM. This isn't unusual for folks who are either quite athletic, naturally serene, or genetically predisposed to such a rhythm. Also, some meds like beta-blockers and cause lower heart rate 🙂 Besides that, your heart's rhythm is in great shape, and the PQRST intervals are right where they should be. No signs of any abnormal beats, which is fantastic.\n\nKeep up the great work! 😊",

"Hi {{name}},\n\nAfter examining your recording, I've observed a slightly slower rate (less than 60 BPM). But your heart rhythm is impeccable and still considered normal heart rhythm. Your PQRST intervals are within the expected range. Additionally, there are no irregular extra beats. \n\nTake good care! 😊",

"Hello {{name}},\n\nI've reviewed your recording and noticed your heart rate is on the lower side, hovering below 60 BPM. This is actually quite common and not inherently worrying, sinus bradycardia is still normal heart rhythm. You're maintaining a stellar rhythm, and your PQRST intervals are textbook perfect. What's more, there's not a single unusual extra beat in sight.\n\nStay safe! 💖", 

"Hi {{name}},\n\nAfter analyzing your ECG, I see that your heart rate is a bit below 60 BPM. This slower pace can be normal and isn't necessarily a concern. It’s usually caused by certain medications, being in good shape, or it can be your baseline heart rate 😃 Your heart's rhythm is absolutely excellent, and the PQRST intervals are exactly as they should be. Plus, I found no extra beats like PVCs or PACs! \n\nLook after yourself and stay safe! 💖", 

"Greetings {{name}},\n\nUpon reviewing your ECG, I can confirm that your heart's rhythm is steady and regular, but your rate is slightly subdued, being under 60 BPM. There's no evidence of ectopic beats, and your PQRST intervals fall perfectly within the expected parameters. Lower heart rate can often be a sign of good fitness or just your unique physiological baseline (Additionally, medications can affect your heart rate as well). Rest assured, your recording is looking good! \n\nBest regards! 💗",

"Hi {{name}},\n\nYour ECG results are in, and they show a consistent and normal rhythm with a heart rate just below 60 BPM (You are at {{heartrate}} BPM. Your PQRST intervals are nicely within the normal range. Sometimes, an athletic lifestyle, relaxation techniques, or genetics can contribute to this. This is a very common heart rhythm that it’s still normal 😃, so there's usually no cause for concern! \n\nStay healthy! 💗",

"Hello {{name}} 😄,\n\nI've analyzed your ECG, and it's clear your heart maintains a normal rhythm with a heart rate that's a bit lower than average, under 60 BPM. With no irregular beats and normal PQRST intervals, these findings are typically normal and could be linked to excellent physical condition or other benign factors.\n\nWishing you wellness! 💗", 

"Hey {{name}} 👋,\n\nJust went over your ECG, and it looks like your heart's rhythm is right on target—regular and normal. Your heart rate is a bit on the lower side, below 60 BPM, but that's not unusual and often seen in individuals with a high level of fitness or those who are very relaxed (I hope this is the case). Your PQRST intervals are also within the normal range, so all in all, it's good news! \n\nKeep up the good health habits! 💗", 

"Hello {{name}},\n\nI've looked at your ECG, and everything appears normal, with a regular rhythm. Your heart rate is somewhat lower than what's typical at {{heartrate}} BPM However, this can be pretty normal, especially for those who are fit or on certain medications. With no ectopic beats and normal PQRST intervals, your recording looks great 🙂\n\nTake care! 💗",

"Hi {{name}} 😄,\n\nYour ECG has been reviewed, and it indicates a normal and regular heart rhythm, though your heart rate falls just below the typical 60 BPM. Such a reading, alongside standard PQRST intervals and no ectopic beats, can be a standard variant, particularly for those in excellent physical condition, with a naturally lower heart rate, or on certain meds.\n\nHave a great day, {{name}}! 💗",

"Hey {{name}},\n\nI've evaluated your ECG, and it's all looking good—your heart rhythm is normal, and the rate is a little low, under 60 BPM, but that's not at all uncommon at all. With your PQRST intervals being in the normal range and no ectopic beats present, it often means you're either quite fit, your body's resting pace is naturally slower, or it can be a result of some medications. \n\nBest wishes for your health! 💗", 

"Hello {{name}},\n\nAfter examining your ECG, I'm pleased to report that your heart rhythm is very regular and normal, with a heart rate that is slightly lower, below 60 BPM. This is a common occurrence and often not a concern, especially with your PQRST intervals within the norm and no abnormal beats found 😄. It could be a result of your physical fitness or other non-concerning reasons (like medications or genetics). All looks pretty good! \n\nBest regards and take care! 💗", 

"Hello {{name}} 👋,\n\nJust finished reviewing your ECG, and I'm pleased to let you know it’s looking good! Your PQRST waves are within the normal range, and I didn't find any extra beats. While your heart rate is a little on the lower side, it's usually nothing unusual—it can happen due to a relaxed state or might be your heart's comfortable pace.\n\nBest wishes! 😃", 

"Hey {{name}},\n\nYour ECG results are in, and everything's looking pretty good. Standard PQRST waves, no ectopic beats, and while your heart rate's on the slower side, it's not necessarily a concern 💙. This could be a testament to your calm demeanor or simply a personal baseline. Sometimes, medications play a part as well.\n\nAll the best! 😃", 

"Hi {{name}} 😃,\n\nYour ECG came back, and it's looking positive. The PQRST waves are spot on, and there's an evident absence of ectopic beats (PVCs and PACs). Your heart's ticking at a bit low rate (below 60 BPM), which is usually completely fine—it might be your body's relaxed state, the influence of medication, or even just your personal baseline.\n\nBest regards! 💕", 

"Hi {{name}},\n\nAll's looking well with your heart's recording! Your PQRST waves are in the normal range, and the ectopic beats are absent, which is perfect. Your heart rate seems a bit slow, but don't stress about it 🤗 — it's likely a sign of relaxation or medication effects.\n\nKeep taking good care of your heart! 😃", 

"Greetings {{name}} 💙,\n\nI've just reviewed your ECG, and I'm pleased to report that your heart is beating in a wonderfully regular pattern. Your PQRST waves are precisely as we'd expect—no abnormalities there. While your heart rate is a bit on the lower side, this is often seen in individuals who are very relaxed or have a naturally lower resting rate. No extra beats to speak of, either.\n\nBest regards! 😊", 

"Hello {{name}} 👋,\n\nYour recent ECG has been analyzed, and the results are pretty good. Your heart's rhythm is regular, and the PQRST waves are looking great. A slightly slower heart rate is quite normal for many people, especially those who are very at ease or use medications like beta-blockers. And with no ectopic beats detected, I don’t see anything you should worry about!\n\nKeep taking great care of yourself! 😊", 

"Hi {{name}},\n\nI have good news regarding your ECG—it looks absolutely fine. The rhythm is regular with standard PQRST intervals, and although your heart rate is a little lower than usual, it's nothing to worry about. This could be a reflection of your calm mind or just your body's natural state. Plus, no ectopic beats were found, which is fantastic.\n\nWishing you the best of health! 😊", 

"Hello {{name}},\n\nAfter going through your ECG, I'm delighted to inform you that your recording is looking great. You've got a nice and regular rhythm with PQRST intervals that are spot on. Your heart rate is a little low at {{heartrate}} BPM, but that's typically not something you should worry about. This is often caused by simply being relaxed or taking certain medications (for many people, this is also a personal baseline).\n\nAll the best! 😊", 

"Hey {{name}},\n\nI'm excited to tell you that your ECG turned out to be just fine! Your heart rhythm is excellent and regular, and the PQRST waves are all normal. While your heart rate might be slower, it's a common finding in many healthy individuals.\n\nTake care and continue to thrive! 😊", 

"Hi {{name}},\n\nYour ECG results are in, and they're pretty positive. Your heart rhythm is regular, with PQRST waves falling within the normal range. Though your heart rate is a bit lower, it's quite common among individuals who are either very fit or very relaxed—sometimes even genetic factors play a role. And no ectopic beats, which is excellent news!\n\nAll the best and take care! 😊", 

"Hey {{name}},\n\nYour recent ECG indicates a Sinus Bradycardia rhythm, which is a fancy term for a heart rate under 60 BPM. This can be a result of regular exercise, a state of restfulness, or using some medications. And with no ectopic beats and a normal PQRST range, it looks like you don’t have anything to worry about.\n\nKeep smiling and take care! 😊", 

"Hey {{name}},\n\nI've taken a look at your ECG, and it's showing a Sinus Bradycardia pattern. That's medical speak for a heart rate that's a bit slow, under 60 BPM, but it's usually completely normal 💖. Your PQRST intervals are just where they should be, and no abnormal beats in sight. All is looking pretty well!\n\nWarm regards and best health! 😊", 

"Greetings {{name}} 💙,\n\nYour ECG displays a Sinus Bradycardia, a standard heart rhythm with a heart rate below 60 BPM. This could be due to a naturally calm disposition or some medications like beta blockers. Your PQRST intervals are as expected, and without any ectopic beats, it's all clear!\n\nWishing you continued happiness! 😊", 

"Hello {{name}} 👋,\n\nJust went over your ECG, and it's showing Sinus Bradycardia. No need for alarm - a heart rate under 60 BPM is usually pretty standard, particularly if you're well-trained or just really relaxed (often, it’s also just genetics). PQRST is looking good, and I have no ectopic beats to report.\n\nSending positive vibes your way! 😊", 

"Hey {{name}} 💖,\n\nI've gone through your ECG, and it's displaying Sinus Bradycardia with a heart rate at {{heartrate}} BPM. This is usually a typical finding of an exceptionally relaxed state during the recording or medications like beta blockers. With fantastic-looking PQRST intervals and no ectopic beats, everything looks pretty great.\n\nBest wishes and take care! 😊", 

"Hello {{name}},\n\nAfter a thorough review of your ECG, I can confirm that your heart rate is slightly below average, which isn't uncommon and can be caused by genetics, medications, or even being relaxed. Your rhythm is steady and normal, and all other metrics are within expected limits 💪. Plus, there are no extra beats to be concerned about.\n\nWarm regards and best wishes! 😄", 

"Hi {{name}},\n\nI've examined your ECG closely and noted your heart rate is a touch low. However, it's still considered a normal, regular rhythm, and all related measurements are just as they should be. No abnormal beats were detected—everything looks good!\n\nSending your positive energy! 😄", 

"Hey {{name}} 👋,\n\nJust finished looking over your ECG, and it shows a slightly slower heart rate, but I'm glad to report that your rhythm is perfectly normal. All PQRST intervals are within the normal range. And no extra beats! This is undoubtedly a reason to smile.\n\nWishing you happiness and health! 💕", 

"Hey {{name}},\n\nAfter a detailed assessment of your ECG, your heart rate may be slower, but your rhythm is consistently normal and regular. All measures are comfortably within the norm, and I'm pleased to see no additional beats 💪. This is very encouraging news!\n\nSending you a bundle of warm thoughts! 😄",

"Hello {{name}},\n\nYour ECG review is complete, and while your heart rate is a bit slow (below 60 BPM), it's still in the normal rhythm. All related measurements are well within normal limits, and the absence of extra beats is heartening 🥰. It's all looking pretty good!\n\nWarm thoughts are on their way to you! 😄",

"Hi {{name}} 💕,\n\nI've carefully analyzed your ECG, and although your heart rate is somewhat slower than usual at {{heartrate}} BPM, the rhythm is excellent and regular. All other measurements are within the expected parameters. There's no sign of any extra beats, which is awesome news!\n\nSending you a wave of warm thoughts! 😄", 

"Hello {{name}},\n\nYour ECG shows Sinus Bradycardia, which is a slower than average heart rate, but please don’t stress about it - it’s still considered normal finding 😄 This can be due to excellent physical conditioning, relaxation techniques, or even just your natural heart rhythm. No ectopic beats and regular PQRST intervals are a bonus.\n\nKeep up the good health practices! Take care! 🌟",

"Hi {{name}} 😁,\n\nYour ECG reveals Sinus Bradycardia, a typical finding in the physically fit or in those who are often in a state of relaxation—meditation can be a contributing factor as well. Rest assured, all your readings are considered normal.\n\nBest health ahead! 💕", 

"Hey {{name}} 💙,\n\nYour ECG indicates Sinus Bradycardia, which can be due to excellent physical fitness, a relaxed lifestyle, or even medication effects (Sinus Bradycardia is still a normal finding on the ECG). Your heart rhythm is beautifully regular, and all measurements, including PQRST, are in the normal range.\n\nStay wonderful! 🌟",

"Hello {{name}} 😄,\n\nSinus Bradycardia is present in your ECG— your heart rate is slow, but your rhythm is fantastic and regular. This is often seen in those who engage in relaxation practices. It can also be influenced by certain medications (or even genetics). Your PQRST values fall nicely within the standard range, and I didn’t find any abnormal beats.\n\nKeep shining! ☀️",

"Hello {{name}} 💖,\n\nI've noted Sinus Bradycardia on your ECG. This slower-than-average heart rate is often found in people who practice relaxation or meditation regularly or as an effect of certain medications. Your overall rhythm looks excellent, with no irregularities. Please keep in mind that a slower heart rate is not typically something you should worry about, and sinus bradycardia is still a normal finding.\n\nKeep up the great work! 💪",

"Hey {{name}},\n\nYour ECG suggests Sinus Bradycardia, common in well-conditioned bodies or as a result of serene and relaxed states. Sometimes, it's just the way your heart works, or it could be due to certain medications. But everything looks normal with your rhythm and intervals - to sum up, nothing worrisome, sinus bradycardia is still considered normal rhythm 😄.\n\nTake care of that heart! 💖",

"Greetings {{name}} 👋,\n\nYour ECG shows Sinus Bradycardia, which can be a pretty normal finding, especially for people who engage in consistent relaxation practices, or those on certain heart-related medications. Your heart is ticking away regularly with no extra beats—all good signs!\n\nWishing you a great day! 🌺", 

"Hi {{name}} 😄,\n\nA thorough look at your ECG reveals Sinus Bradycardia, which is not uncommon for those with excellent physical fitness, a very calm lifestyle, or who may be taking medications that affect heart rate. Your heart rhythm and PQRST intervals are commendably normal. Sinus bradycardia is considered normal heart rhythm - not something you should be concerned with too much, {{name}}.\n\nTake care! 💖", 

"Hello {{name}} 💙,\n\nYour ECG reflects Sinus Bradycardia (it’s considered normal rhythm), which can be a natural occurrence in those who are exceptionally calm or on certain medications that slow the heart rate. Your heart is in a fantastic and regular rhythm, and all other measurements are within normal ranges.\n\nWarm regards! 😄",

"Greetings {{name}} 👋,\n\nThe Sinus Bradycardia (a normal rhythm) on your ECG is typically a reflection of either someone who's often in a peaceful state or an effect of medication (genetics also play a role). Your rhythm is steady and regular. All your heart's signals are precisely where they should be.\n\nContinue to thrive! 🏄‍♂️",

"Hi {{name}} 😉,\n\nYour ECG indicates a Sinus Bradycardia rhythm. This can be a sign of excellent physical conditioning, a relaxed state of mind, or the influence of certain medications that slow the heart rate. Your heart’s regular rhythm, and the PQRST intervals are reassuringly normal.\n\nBest wishes! ✨", 

"Hello {{name}} 👋,\n\nI've detected Sinus Bradycardia in your ECG reading. This is common among those who regularly engage in exercise, are often in a relaxed state, or are taking medication that affects heart rhythm. Your PQRST intervals are spot-on, and I didn’t find any abnormal beats. It's important to note that sinus bradycardia is considered a regular rhythm and isn't usually a reason for worry.\n\nTake care! 👍",

"Hey {{name}} 😄,\n\nThe Sinus Bradycardia seen on your ECG is quite normal, especially if you're working out or practicing relaxation techniques or if you're on medications that can lower your heart rate. Your PQRST intervals are within normal limits, which is fantastic news.\n\nStay well! 🌼", 

"Hello {{name}} ✨,\n\nI've examined your ECG, and it shows Sinus Bradycardia, which simply means your heart rate is a bit slower than average. However, everything else, including your PQRST intervals, looks excellent. This is something we often see and usually isn't something to be troubled about, {{name}}. Rest assured, it's quite norma.\n\nTake care! 💗"
    ];
})();

export default sinus_bradycarida_1;