const sinus_bradycarida_2 = (function () {
    return [
"Hi {{name}} 😄,\n\nAfter analyzing your recording, I noticed that your rhythm is normal, yet your heart rate is really slow. Sinus bradycardia (this is what a regular rhythm with a low heart rate is called) is not typically harmful, but it might be if you're experiencing dizziness, fatigue, or shortness of breath. Please make sure to check your results with your doctor if you experience any of those symptoms.\n\nSending you lots of warm thoughts! 🤗",

"Hello {{name}} 👋, \n\nUpon assessing your ECG, I found that your rhythm is normal, but your heart rate is exceptionally slow. In most cases, a slow heart rate isn't dangerous, but it can be if you're experiencing symptoms like dizziness, fatigue, or shortness of breath. So, {{name}}, be sure to consult your doctor about your results if you’re experiencing any of those symptoms. \n\nTake care! 😊",

"Hey {{name}},\n\nEvaluating your recording, I see that your rhythm is normal and regular; however, your heart rate is notably slow. Sinus bradycardia usually isn't a cause for concern. But if you're experiencing dizziness, fatigue, or shortness of breath, please discuss your results with your doctor. Everything else on your recordings is looking great and normal 😊\n\nI hope this helps! Always take care 💖",

"Hi {{name}},\n\nI've taken a look at your ECG recording and it's great to see that your heart rhythm is regular – that's always a good sign! I did notice your heart rate is a bit on the slower side. This is often seen in healthy individuals, especially those who are physically fit, and it's called sinus bradycardia, which just means your heart rate is below 60 beats per minute. It's usually nothing to worry about if you're not having any symptoms like dizziness, fatigue, or shortness of breath. If you do feel anything out of the ordinary, it's a good idea to mention it to your doctor at your next visit, as your heart rate is below 40 BPM. Also, your PQRST values are looking good, with no irregular beats to report.\n\nKeep taking wonderful care of yourself! 😁",

"Hello {{name}},\n\nUpon reviewing your recording, it appears that your heart rhythm is steady, although your heart rate is on the slower side. Generally, a slow heart rate isn't a concern, especially in the absence of symptoms like dizziness, fatigue, or shortness of breath – which you haven't mentioned. It's a good idea to discuss your ECG findings with your doctor when you see them next.\n\nWishing you well! 🤗",

"Hi {{name}} 👋, I've reviewed your recording and am pleased to report that your heart rhythm is looking normal - that's fantastic news! I did notice that your heartbeat is a bit leisurely, gently pacing at just below 40 BPM. This can be a variation of normal for some, especially if you're often physically active or particularly relaxed when the recording was taken.\n\nThere's certainly no cause for immediate concern, {{name}}, but I do suggest bringing this up with your doctor at your next appointment, just as a precaution and especially if you've noticed any unusual symptoms like dizziness, fatigue, or shortness of breath.\n\nWishing you the best of health and happiness! 😊 Take care!",

"Hello {{name}} ❤️,\n\nI've carefully reviewed your recording, and I'm happy to confirm that your heart rhythm is wonderfully regular. It's also noted that your heart is taking a relaxed approach, beating at a calm pace of below 40 BPM.\n\nWhile this typically isn't anything to be concerned about, I would recommend mentioning it to your doctor during your next check-up, particularly if you've been feeling any dizziness, fatigue, or shortness of breath.\n\nTake care! 😄",

"Hi {{name}},\n\nBased on your recording, it seems that your rhythm is excellent and regular. Nevertheless, your heartbeat is slower than usual, as it's below 40 BPM. While it may not be a cause for concern, please do share your results with your physician, particularly if you experience dizziness, fatigue, or shortness of breath. The rest of your recording appears superb, with PQRST intervals falling within normal limits and zero ectopic beats observed.\n\n\nSending you lots of warm thoughts! 🤗",

"Hello {{name}},\n\nI've had a chance to review your recording, and it's lovely to see that you have a consistent and regular heart rhythm. I did notice your heartbeat is taking its time, pacing at below 40 BPM — a bit more leisurely compared to the usual resting average of 60 to 100 BPM.\n\nPlease don't feel any distress over this (sinus bradycardia is still considered normal finding), but it would be wise to chat with your physician about these results at your next visit, just to ensure everything is as it should be. This is particularly true if you're noticing any symptoms like dizziness, fatigue, or difficulty breathing.\n\nSending you the warmest wishes for your health and well-being. Take care 💖",

"Hi {{name}},\n\nAfter reviewing your ECG, it seems that your rhythm is regular. Nonetheless, your heartbeat is slower than usual - a bit below 40 BPM. While it may not be a cause for alarm, please ensure you share your results with your doctor, particularly if you experience dizziness, fatigue, or shortness of breath. The rest of your recording paints a positive picture, with PQRST values in the expected range and ectopic beats nowhere to be found.\n\nI hope this helps! Take care and stay safe. 😄",

"Hello {{name}},\n\nI just reviewed your ECG, and it seems like you have captured sinus bradycardia. Don't worry; it's just a normal, regular rhythm with a slower heartbeat. However, in your case, your heart rate is exceptionally slow, as it's below 40 beats per minute. There's no need to panic, but please do check the results with your doctor, especially if you're also experiencing dizziness, fatigue, or shortness of breath. Everything else in your recording is looking wonderful – the PQRST values are spot on, and there's no evidence of ectopic beats.\n\nI hope this helps! Always take care 🤗",

"Hello {{name}} 😄, \n\nI've reviewed your recording, and it seems like you may have caught sinus bradycardia. But don't worry; it's a normal, regular rhythm with a slower heartbeat. However, in your case, your heart rate is particularly slow No need to be concerned, {{name}}, but please make sure to consult your doctor about the results, especially if you're experiencing dizziness, fatigue, or shortness of breath. 1Every other detail in your recording is in top shape, with PQRST values that are completely normal and not a single ectopic beat present.\n\nTake extra good care! 💕",

"Hi {{name}} 👋,\n\nAfter going through your ECG recording, it looks like you may have captured sinus bradycardia. It's an entirely normal, regular rhythm with a slower heartbeat. However, in your case, your heart rate is a bit slow at {{heartrate}} BPM. No need to stress, but please do make sure to discuss the results with your doctor, especially if you're also experiencing dizziness, fatigue, or shortness of breath.\n\nBeyond that, your recording presents brilliantly, with regular PQRST intervals and a lack of any ectopic activity like PVCs or PACs 💪.\n\nTake care! 😌",

"Greetings {{name}} 😄,\n\nYou're maintaining a regular rhythm, which is great! However, your heart rate is really low, falling below 40 BPM. This is often considered benign, but it is essential to consult your physician if you notice symptoms like dizziness, fatigue, or shortness of breath. Please bring this up at your next medical appointment. In all other respects, your recording is impressive, featuring PQRST values that are on target and a complete absence of ectopic beats.\n\nBest regards and stay healthy! 😃",

"Hello {{name}} 😃,\n\nI've taken a look at your ECG recording, and everything seems regular except that your heart rate is relatively low at {{heartrate}} BPM. While this can be normal for some individuals, it's crucial to discuss it with your doctor if you're experiencing any symptoms like dizziness or fatigue. Ensure to mention it at your upcoming health check-up.\n\nThe rest of your recording appears superb, with PQRST intervals falling within normal limits and zero ectopic beats observed.\n\nWarm wishes for your well-being! 🌟", 

"Hey {{name}},\n\nJust finished reviewing your ECG, and it's showing a steady rhythm with a heart rate that's a bit on the slow side, dipping below 40 BPM. This isn't typically something to worry about, but it's worth bringing up with your doctor, especially if you're feeling any symptoms like fatigue or breathlessness. Keep an eye on how you're feeling and chat with your doc about it soon. In all other respects, your recording is impressive, featuring PQRST values that are on target and a complete absence of ectopic beats.\n\nStay well! 😊", 

"Hello {{name}},\n\nYour ECG has been reviewed, and while your heart's rhythm is looking good, the rate is slower than what's usual, sitting below 40 BPM. If you're symptom-free, it's generally not a concern, but if you're experiencing symptoms such as dizziness or fatigue, please consult your doctor. Make a note to discuss this at your next visit.\n\nAside from that, your recording is top-notch - normal PQRST intervals and ectopic beats are completely missing.\n\nWishing you good health! 🌺", 

"Hi {{name}} 😄,\n\nI've had a chance to review your ECG, and it shows a normal rhythm with a low heart rate of {{heartrate}} BPM (normal range is 60-100 BPM). This condition is not uncommon and is often harmless, but if you're having symptoms like dizziness or tiredness, it would be wise to speak with your doctor about it. All other measurements in your recording are splendid - PQRST values are precisely within the normal range, and abnormal beats are undetected.n\nAll the best! 🍀", 

"Hello {{name}} 💕,\n\nUpon checking your ECG recording, your heart rhythm is steady and normal, yet the rate is slower than usual at below 40 BPM. This rate can be perfectly fine, but it's essential to consult with your doctor if you have symptoms such as dizziness or fatigue (as the standard range is between 60 and 100 BPM).\n\n\Every other part of your recording is looking sharp - PQRST values fall neatly in line with expected values, and ectopic beats are absent.\n\nAll the best! 😄", 

"Hi {{name}} 😄,\n\nYour ECG shows a normal, steady rhythm with a heart rate that is slower than what we typically see, below 40 BPM. This could be normal for you {{name}}, but do discuss it with your healthcare provider if you have symptoms like fatigue or breathlessness. 1. Everything else within your recording is looking first-rate - PQRST values are in the normal zone, and ectopic beats are nowhere to be found.\n\nBest wishes for your health! 🌼", 

"Hey {{name}} 👋,\n\nLooking at your ECG, your heart's rhythm is normal, but I've noticed your heart rate is lower than average, below 40 BPM. This isn't often a concern, but it's something you should definitely talk over with your doctor if you're experiencing any symptoms like dizziness or fatigue. Be sure to discuss this at your next appointment. All additional aspects of your recording are in perfect condition - PQRST values are on point, and ectopic beats are non-existent.\n\nWishing you well! 😇",

"Hello {{name}} 💕, Upon reviewing your ECG, I've observed that while your heart's rhythm is nice and steady, your heart rate at {{heartrate}} BPM is unusually low. This condition, known as sinus bradycardia, isn't generally a cause for concern unless symptoms like dizziness, fatigue, or shortness of breath accompany it. Every other detail in your recording is outstanding - PQRST values are consistently normal, and there's not a single ectopic beat detected.\n\nWishing you well! 😊",

"Hi {{name}} 🌹, I've taken a close look at your ECG, and it shows your heart rhythm is great and consistent, but your heart rate is relatively low. While a slow heart rate (sinus bradycardia), is often benign, it's important to discuss with your doctor if you have any symptoms such as dizziness, fatigue, or breathing difficulties. Apart from that, your recording is in fine form - showing normal PQRST values and a clear absence of any extra beats.\n\nTake care of yourself! 🌟", 

"Greetings {{name}} 😄,\n\nYour recent ECG analysis indicates a normal rhythm but a slower than normal heart rate (at {{heartrate}} BPM), a condition known as sinus bradycardia. This isn't usually worrisome, but if you have any symptoms, it's worth discussing with your healthcare provider.\n\nThe rest of your recording presents wonderfully – PQRST values are within normal limits, and ectopic beats are absent.\n\nAll the best to you! 🌺", 

"Hello {{name}} 😄, I've reviewed your ECG and noted that your heart maintains a steady rhythm, but the rate is slower than we would like to see (it’s known as sinus bradycardia). This is often benign. However, if you're experiencing any unusual symptoms, please consult your doctor. 1. Each additional aspect of your recording is in prime condition - with PQRST values that are ideal and no signs of abnormal beats.\n\nWishing you a healthy day! 🌿",

"Greetings {{name}} 😃,\n\nYour ECG shows a normal rhythm with a slower heart rate (at {{heartrate}} BPM, it’s a bit slower than we would like to see). This typically isn't concerning, but if you notice any symptoms, {{name}}, please discuss them with your doctor. Other measurements of your recording are flawless - with PQRST values that are standard and a complete absence of extra beats.\n\nWarmest regards for your well-being! 🌸",

"Hello {{name}},\n\nI've checked your recording, and it shows a nice and regular rhythm, though your heart rate is on the slower side, hovering around 40 BPM. It's generally not a concern, but I advise discussing this with your physician if you're having any symptoms like dizziness or fatigue. Aside from that, your recording is top-notch - standard PQRST intervals and ectopic beats are completely missing.\n\nBest wishes! 😊", 

"Hi {{name}},\n\nYour latest recording reflects a normal rhythm with a heart rate that's a bit slow, just below 40 BPM. There's typically no need for alarm, but please ensure you consult with your healthcare provider if you've noticed any unusual tiredness or breathlessness.\n\nThe rest of your recording presents wonderfully – PQRST values are within normal limits, and ectopic beats are absent.\n\nStay safe! 🌷", 

"Hey {{name}} 🌸,\n\nYour rhythm looks good, but at {{heartrate}} BPM, your heart is beating a tad slower than usual. Usually, it’s not an issue, but please chat with your doctor about these findings if you encounter any dizziness or fatigue. Every other detail in your recording is outstanding - PQRST values are consistently typical, and there's not a single ectopic beat detected.\n\nTake care of yourself! 😊",

"Hello {{name}} 🌹,\n\nI've reviewed your heart's recording, and while the rhythm is great and stable, your rate is a bit slow, below 40 BPM. No immediate worries, but do talk to your doctor about it, especially if you feel any symptoms like shortness of breath or fatigue.\n\n1. Your recording's additional aspects beam with positivity – textbook PQRST values and zero evidence of ectopic beats.\n\nAll the best! 😊", 

"Hi {{name}} 😄, Your heart is in a normal rhythm, but the pace is slower than what's average (normal range is between 60 and 100 BPM) While this isn't usually a problem, please do discuss it with your doctor if symptoms such as dizziness or tiredness arise. Beyond heart rate, your recording is exemplary – PQRST values are in the safe zone, and I didn’t find any ectopic beats. \n\nWishing you well! 💙",

"Hey {{name}} 💕,\n\nYour ECG looks good with normal PQRST intervals and no ectopic beats to report. Your heart rate is a bit slow as it’s under 40 BPM. While this isn't generally a cause for concern, it's still a good idea to mention this to your doctor if you notice any symptoms like dizziness or fatigue.\n\nTake care! 😊",

"Greetings {{name}},\n\nYour heart recording indicates that your PQRST values are within normal limits, and I didn't find any ectopic beats, which is excellent. Nonetheless, your heart rate is a little on the low side, just below 40 BPM. Though not necessarily worrisome, please talk to your doctor, especially if you're experiencing symptoms like fatigue or dizziness.\n\nAll the best! 😊",

"Hello {{name}},\n\nYour ECG results are quite normal with correct PQRST values and no ectopic beats, which is great to see. Your heart rate is on the slower side, below 40 BPM. This usually isn't a problem, but please consult your doctor if you're having symptoms like fatigue or dizziness. \n\nBest regards! 😊", 

"Hi {{name}},\n\nIt's good to see your heart's PQRST values are normal and there are no ectopic beats on your recording. Your heart rate is a tad slow, though, at just below 40 BPM (with normal range between 60 and 100 BPM). It's not necessarily a concern, but it's important to talk with your doctor about it, particularly if you're experiencing any unusual symptoms.\n\nTake care! 😊",

"Hey {{name}}, I've analyzed your ECG, and all looks normal in terms of PQRST values, I also didn’t notice any abnormal beats. But your heart rate is somewhat slow, hovering just below 40 BPM. Please consult with your doctor regarding these findings if you encounter any concerning symptoms.\n\nAll the best! 😊",

"Hey {{name}} 💗,\n\nI've carefully reviewed your ECG, and I'm pleased to share that your PQRST values are right on point, and there aren't any abnormal beats to speak of. I did observe that your heart rate is on the slower side, just a tad under 40 BPM. If you happen to notice symptoms like dizziness, lightheadedness, unusual fatigue, or shortness of breath, it would be wise to bring this to your doctor's attention along with these findings.\n\nWishing you the very best of health! 😊",

"Hello {{name}} 🌹,\n\nI've taken a look at your recent recording. It shows a regular, normal rhythm, but your heart is beating slower than what's typical. While this isn't always a cause for alarm, I recommend touching base with your healthcare provider about these findings. If you've noticed any unusual symptoms. On the plus side, your PQRST values are perfectly normal, and there are no ectopic beats, which is excellent news!\n\nStay safe! 😊",

"Hi {{name}} 💙, After examining your ECG, I noticed your heart rate on the low side, just below 40 BPM (while your rhythm is still normal and regular). Often it’s not cause for concern, but it's important to share this with your doctor if you've been experiencing any symptoms. Ending on a positive note, your ECG showed normal PQRST values and no signs of ectopic beats.\n\nBest regards! 😊", 

"Hi {{name}} 🌼,\n\nUpon reviewing your recording, I see a consistent, normal rhythm with a beat that's slower than average (below 60 BPM). While often, this isn't a concern, it's a good idea to consult with your healthcare provider if you've been experiencing any symptoms. The good news is your PQRST values are normal and there are no ectopic beats detected.\n\nTake care and be well! 😊",

"Hello {{name}} 😀,\n\nYour recording shows a steady, normal pattern, though the heart rate at {{heartrate}} BPM is a bit slow. No need for immediate worry, but do speak with your doctor about these results. Should you have any symptoms, make sure to mention them. Thankfully, your PQRST values are on point, and I didn’t find any ectopic beats.\n\nWishing you good health! 💙", 

"Greetings {{name}} 😊,\n\nYour latest recording presents a normal rhythm with a rate that's on the lower side (it’s {{heartrate}} BPM). It's not typically worrisome, but it's still worth mentioning to your healthcare provider as it’s a pretty low heart rate. If any symptoms have caught your attention, please do not hesitate to discuss them. Also, your PQRST values are normal, and ectopic beats are not present - which is excellent news.\n\nBest regards! 🌸", 

"Hello {{name}}, I’ve carefully reviewed your ECG, and I’m pleased to report that your PQRST values are all within the normal range. There’s also no sign of ectopic beats, which is great news. However, I did observe that your heart rate is on the slower side, consistently just under 40 BPM. While a lower heart rate can be standard for some individuals, especially athletes, it’s important to keep an eye on how you’re feeling. If you experience any symptoms that cause concern, it would be wise to discuss these findings with your healthcare provider.\n\nWishing you good health and peace of mind! 😊",

"Hi {{name}} 😊,\n\nJust finished looking over your ECG results. Good news – your PQRST values are precisely where they should be, and there’s no indication of ectopic beats. I did notice your heart rate is on the low side, averaging just below 40 BPM. It's not uncommon for healthy individuals, but it’s always good practice to consult your doctor if this is new for you or if you have any symptoms that concern you. Take care of yourself, and don’t hesitate to reach out for any further ECGs.\n\nBest wishes! 🌻", 

"Hi {{name}} 💖,\n\nI've gone through your ECG data, and everything looks pretty good – the PQRST values are spot-on, and there are no ectopic beats to speak of. Your rhythm is nice and regular, but your heart rate seems really low, hovering around the 40 BPM mark. If you feel any discomfort, it might be worth discussing with your doctor. \n\nStay well, and all the best! 😊",

"Hello {{name}} 👋,\n\nAfter reviewing your ECG, everything appears to be in order with the PQRST values, and I'm not seeing any ectopic beats – which is fantastic! Your heart rate at {{heartrate}} BPM is really low (underlying rhythm is still lovely and regular), If you feel any symptoms that seem off or just want additional peace of mind, a conversation with your doctor could be beneficial.\n\nBest regards and health to you! 😄",

"Hi {{name}} 💙,\n\nGood news from your ECG – your PQRST values are within the normal range, and I didn't spot any extra beats. However, your heart rate is running really low, just below 40 BPM. Often it’s normal and not something to worry about, but if you notice any symptoms, please discuss these readings with your doctor.\n\nSending you positive and healthy thoughts! 😊",

"Hello {{name}} 😄,\n\nI've just finished a detailed analysis of your ECG readings, and it's a pleasure to inform you that all your PQRST intervals are looking good, and there's a reassuring absence of ectopic beats. I notice that your heart rate is on the lower side, close to 40 BPM. While this could be perfectly normal for you, it's worth keeping in touch with your doctor about these numbers if you're experiencing any new or concerning symptoms.\n\nWarmest regards and good health! 💙",

"Hi {{name}} 🌹,\n\nYour ECG presented standard PQRST intervals and no ectopic beats – this is excellent news. Your rhythm is nice and steady, but your heart rate does appear to be really low, just below 40 BPM. If you ever feel uneasy or notice any symptoms that are new to you, it might be a good idea to consult with your doctor.\n\nBest wishes, and keep smiling! 😊",

"Hi {{name}} 💙,\n\nGood news – your ECG indicates a normal underlying rhythm. Additionally, the PQRST values are spot-on, and there's a happy absence of ectopic beats. I've noted your heart rate is a bit too low, hanging just below 40 BPM. It could be expected for you, but if any symptoms arise that seem unusual, it might be best to consult with your doctor.\n\nWishing you a heart-healthy day! 😄", 

"Hello {{name}} 🌻,\n\nI'm pleased to inform you that your heart's underlying rhythm is normal. Alongside this, your PQRST values are just as they should be, and ectopic beats are not present. Do keep an eye on your heart rate, though; it's low at just under 40 BPM. Often it’s not a cause for concern, but if you notice any symptoms like dizziness, reach out to your doctor.\n\nWarm regards and healthy heart vibes to you! 😃", 

"Hey {{name}} 🌟, Starting with the positives – your underlying heart rhythm and PQRST values are entirely average, and ectopic beats aren't present (this is fantastic news). Your heart rate is somewhat slow at under 40 BPM. Usually, it’s not something to worry about, but if you notice any symptoms, it's a good idea to discuss this with your doctor.\n\nBest wishes! 😊",

"Hello {{name}} 😄,\n\nThe main takeaway from your ECG is that your underlying heart rhythm is normal, and the PQRST values are precisely where we'd expect them to be. Ectopic beats? None in sight. Your heart rate, though, is pretty slow at just below 40 BPM. This is a normal finding for many people, but if you experience any symptoms, it would be good to check in with your doctor.\n\nBest regards and to your good health! 💖", 

"Hey {{name}} 💕,\n\nThe results are in, and your heart's underlying rhythm is normal – that's always good news. Your PQRST values are in the correct range, and ectopic beats are a non-issue. Your heart rate is on the lower side (the norm is between 60 and 100 BPM). It’s often not a cause for concern and can be 100% normal for you, but if you have any unusual symptoms, please check in with your doctor.\n\nAll the best! 😊",

"Hi {{name}},\n\nLet's celebrate the fact that your ECG shows a normal underlying rhythm! The PQRST values are also within the normal parameters, and I'm not seeing any ectopic beats – that's double good news. I did note your heart rate is really low, just below 40 BPM. If you feel anything out of the ordinary, a chat with your doctor might be in order - but please keep in mind it’s often a very normal founding as long as you don’t have any additional symptoms.\n\nTake care! 😊",

"Hi {{name}} 🌻,\n\nI've gone through your ECG, and it looks like your heart rate is below 40 BPM, which we call bradycardia. It's important to note that this can be completely normal, especially if you're physically fit or you are on particular medication. It may simply mean that your heart is very efficient! All other parameters on your ECG are normal, with no unusual beats detected. However, if you're experiencing any symptoms like dizziness, fatigue, or shortness of breath, it would be wise to check in with your doctor just to be sure.\n\nTake care! 😃",

"Hi {{name}} 💗,\n\nYour ECG has given me a clear picture: your heart's rhythm and PQRST waveforms are textbook perfect. As for ectopic beats, there's not a single one. I did notice your heart rate is a bit slow (just under 40 BPM). It’s often not something to be concerned with, but if you feel any unusual symptoms, a chat with your doctor could be beneficial.\n\nAll the best! 😊",

"Greetings {{name}} 😀,\n\nJust wanted to share that your ECG results show a beautifully normal heart rhythm and ideal PQRST intervals. And while your heart rate at {{heartrate}} BPM is really slow, it’s typically not something you should be concerned with (it can be caused by everyday things). However, should you encounter any concerning symptoms, please consult your physician.\n\nTake care! 💕",

"Hi {{name}} 💕,\n\nYour recent ECG shows steady, normal rhythm and on-point PQRST values. No ectopic beats were detected as well, and while your heart rate is slow at just below 40 BPM, it's often not something to worry about. Still, stay attentive to your body and consult your doctor if you notice any symptoms.\n\nHave a great day! 😄"
    ];
})();

export default sinus_bradycarida_2;