import { Alert, IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import { TASK_STATUS, TASK_TYPE } from '../../common/constants'
import { MakeRedirect } from '../../common/utils'
import { getTimeAgo } from '../../common/utils'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import { TableTag } from './TaskTable.styled'
import { useContext } from 'react'
import { TasksContext } from '../../contexts/Tasks.context'
import { useState } from 'react'

const TaskTable = ({ taskData, mode }) => {
  const { unassignTask } = useContext(TasksContext)
  const [alertOpen, setAlert] = useState(false)
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="35%">Task</TableCell>
              <TableCell width="15%">Time requested</TableCell>
              {mode === "live" && <TableCell>Type</TableCell>}
              <TableCell >Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskData.map((row, index) => (
              <TableRow key={row.ecg_id} hover={true}>
                <TableCell component="th" scope="row">
                  <b>{row.ecg_id}</b>
                </TableCell>

                <TableCell component="th" scope="row">
                  <b>
                    {getTimeAgo(new Date(row.analysis_requested_at))}
                  </b>       
                </TableCell>

                {mode === "live" &&  <TableCell>
                  <div style={{display:"flex", alignItems:"center", gap:"10px"}}> { row.source === "apple_watch" ? 
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.12 0 0 1.12 0 2.5V19.5C0 20.88 1.12 22 2.5 22H10.5C11.88 22 13 20.88 13 19.5V2.5C13 1.12 11.88 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="black" fill-opacity="0.54"/>
                    </svg> : 
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 0C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0H2ZM9 7V1.5L14.5 7H9Z" fill="black" fill-opacity="0.54"/>
                    </svg>
                    
                  }
                  {TASK_TYPE[row.source]}</div>
                 
                </TableCell>}
                <TableCell>
                  <TableTag
                    label={TASK_STATUS[row.status]}
                    color="primary"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Annotate">
                    <IconButton
                      id="edit-task"
                      onClick={MakeRedirect(
                        '/review/' + (mode === 'tutorial' ? 0: row.ecg_id) + `?mode=${mode}`
                      )}
                      size="large">
                      <CreateOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Unassign">
                    <IconButton
                     id="remove-task"
                      onClick={() => {
                        unassignTask(row.ecg_id, mode).then(res => setAlert(true))
                      }}
                      size="large">
                      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlert(false)}>
                        <Alert severity="success">Task unassigned successfully!</Alert>
                      </Snackbar>
                      <IndeterminateCheckBoxOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default TaskTable
