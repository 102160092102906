import { createSlice } from '@reduxjs/toolkit'
import * as endpoints from '../../common/endpoints'
import API from '../../common/api'
import moment from 'moment'

export const ecgSlice = createSlice({
  name: 'ecg',
  initialState: {
    ecg: {},
  },
  reducers: {
    setEcg: (state, action) => {
      state.ecg = { ...action.payload }
    },
    clearEcg: (state) => {
      state.ecg = {}
    },
  },
})

export const { setEcg, clearEcg } = ecgSlice.actions

export const selectEcg = (state) => state.ecg.ecg

export const getEcg = async (id) => {
  const store = await import('../store')
  return new Promise((resolve, reject) => {
    API.get(endpoints.GETECG(id))
      .then((data) => {
        data = data.data.data
        /*
        // Old Logic, only set the started_at at the first time open the ECG, 
        // started_at will be set at the fisrt open, it could lead to a longer time AHT, if the current user back to view another ECG

        localStorage.getItem(data.ecg_id) || localStorage.setItem(data.ecg_id, moment.utc().format())
        */

        // New Logic, always refresh the started_at when getting an ECG
        var started_at = moment.utc().format()
        console.log("Refresh local web started_at: " + started_at)
        localStorage.setItem(data.ecg_id, started_at)

        store.default.dispatch(setEcg(data))
        resolve(data)
      })
      .catch((e) => {
        console.error(e)
        reject(e)
      })
  })
}

export default ecgSlice.reducer
