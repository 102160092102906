  const sinus_rhythm = (function () {
    return [
"Hi {{name}},\n\nYour ECG looks fantastic! Everything looks good, all measurements are normal, and I didn't find any abnormal beats in your recording.\n\nHope the rest of your day will be as good as your ECG ❤️",
"Hello {{name}},\n\nI have to tell you that it is a really lovely ECG ❤️ There's nothing wrong going on with your ECG — no unusual beats. Every measurement is within limits, which we love to see ❤️\n\nTake care :)",
"Hey {{name}},\n\nLooking at all the measurements, I can tell you that everything is good :) Your ECG looks nice and clean, and your PQRST intervals and heart rate are well within the limit. Plus, there are no abnormal beats :)\n\nBest regards! ❤️",
"Hi {{name}},\n\nGood news :) After reviewing your ECG, I can say that everything looks very normal. All values are nicely within normal limits. I also didn't find any abnormal beats. This is what we all love to see.\n\nHave a great day 😊",
"Hey {{name}},\n\nYour ECG looks perfect and clean. Your heart rate and PQRST values are well within their usual range, and no ectopic beats were noted.\n\nI truly hope the rest of your day will be as good as your recording, {{name}}. All the best! ❤️",
"Hello {{name}},\ n\nI'm happy to say that your ECG looks perfect and clean. Your heart rate and PQRST values are all within the normal range, and no ectopic beats were found - which is great news.\n\nTake care! 🤗",

"Hi {{name}},\n\nCongratulations! Your ECG looks perfect and clean. Your heart rate and PQRST values are all within the normal range, and no ectopic beats were recorded.\n\nAll the best!",

"Hello {{name}},\ n\nI'm delighted to say that your ECG looks good. Your heart rate and PQRST values are all within the normal range, and no ectopic beats were found as well.\n\nHave a good day! 😊",

"Hey {{name}},\n\nYour ECG results are wonderful! 😊 All measurements are in the normal range, and no unusual beats were detected. To put it simply, everything is looking great.\n\nKeep up the good work! 🌟",

"Hi {{name}},\n\nI looked at your ECG carefully, and everything seems amazing, {{name}}! The rhythm is clear and regular, and all the PQRST measurements and heart rate are normal.\n\nTake care! 😊",

"Hey {{name}},\n\nI checked your ECG closely, and I'm happy to say it all looks good! The rhythm is neat and clean, and all the numbers (PQRST intervals and heart rate) are within the usual range.\n\nHave a good day! 🌟",

"Hi {{name}},\n\nI examined your ECG, and I'm glad to report that it's all normal! The rhythm is clean and regular, and every measurement is within the expected range.\n\nHave a great rest of your day! 😊",

"Hey {{name}},\n\nAfter going over your recording, I'm happy to say everything is fine. The rhythm is normal and steady, and all measurements are within normal limits. To sum up, there is nothing to worry about! 🎉\n\nTake care!",

"Hello {{name}},\n\nI examined your recording and didn't find any problems. The rhythm is very good and regular, and every measurement is in the expected range. 😄\n\nSending lots of love your way! ❤️",

"Hi {{name}},\n\nI looked closely at your recording and saw nothing to worry about. The rhythm is perfect and regular, and all the numbers, like PQRST intervals and heart rate, are in the usual range. 🌟\n\nBest regards ❤️",

"Hey {{name}},\n\nYour recording looks great! It reveals a Sinus Rhythm with normal PQRST values and no ectopic beats. This is awesome news, as there is nothing to worry about in your recording.\n\nKeep taking care of your heart! 🌟",

"Hello {{name}},\n\nGood news! Your recording shows a Sinus Rhythm. The PQRST measurements are normal, and there are no ectopic beats.\n\nHave a great rest of your day! 😊",

"Hi {{name}},\ n\nI'm pleased to share that your recording demonstrates a Sinus Rhythm. Normal PQRST measurements and no ectopic beats – that's fantastic news! 🎉\n\nSending lots of love your way! ❤️",

"Hey {{name}},\ n\nI'm thrilled to share that your recording is looking wonderful! 🤗 It displays a Sinus Rhythm, normal PQRST values, and no ectopic beats.\n\nHave a fabulous day ahead!",

"Hello {{name}},\ n\nI'm happy to report that your recording looks fabulous! It exhibits a Sinus Rhythm, normal PQRST intervals, and no ectopic beats. To sum up, everything looks normal, and there is nothing to worry about.\n\nSending you positive energy! 🌟",

"Hi {{name}},\n\nWonderful news! Your recording displays a Sinus Rhythm, normal PQRST measurements, and no ectopic beats. Additionally, your heart rate at {{heartrate}} is well within the norm.\n\nSending you hugs and positive energy! 🤗",

"Hello {{name}},\n\nI just checked out your ECG, and I'm happy to report that there's nothing concerning in the ECG results. Your recording shows a Sinus Rhythm with normal PQRST values and no ectopic beats.\n\nI hope this helps! Stay safe! 💖",

"Hi {{name}},\n\nI reviewed your ECG, and everything looks good. There's nothing to be concerned about, as it indicates a Sinus Rhythm, normal PQRST, and no ectopic beats.\n\nTake care! 🤗",

"Hey {{name}},\n\nI wanted to let you know that your ECG results show no cause for concern. Your heart is in Sinus Rhythm with normal PQRST values and no ectopic beats.\n\nHave a peaceful day! 🙏🏼",

"Hello {{name}},\ n\nI've got some great news for you! Your ECG looks perfect, with nothing to be concerned about. It shows a Sinus Rhythm, normal PQRST measurements, and no ectopic beats.\n\nI hope this was helpful.✨",

"Hi {{name}},\n\nWonderful news! Your ECG shows no cause for concern. It indicates a Sinus Rhythm, normal PQRST values, and no ectopic beats.\n\nTake care! 💕",

"Hey {{name}},\n\nYour ECG results are in, and I'm glad to say there's nothing to worry about! It shows a Sinus Rhythm, normal PQRST values, and no ectopic beats (like PVCs or PACs).\n\nSending you lots of warm thoughts 🤗",

"Hello {{name}},\n\nI just reviewed your ECG, and I'm happy to report that there's nothing alarming in your results. Your heart is showing a Sinus Rhythm with normal PQRST values and no ectopic beats.\n\nHave a fabulous day! 🌟",

"Hi {{name}},\n\nFantastic news! I looked at your ECG, and everything appears good. There's nothing to be troubled about, as it indicates a Sinus Rhythm, normal PQRST, and no ectopic beats.\n\nI hope this was helpful.❤️",

"Hey {{name}},\ n\nI'm pleased to share that your ECG looks wonderful! There's nothing worrisome in the results, as they display a Sinus Rhythm, normal PQRST measurements, and no ectopic beats.\n\nI hope this helps! Always take care 💖",

"Hi {{name}},\n\nWonderful news! Your ECG shows no cause for worry. It indicates a Sinus Rhythm, normal PQRST values, and no extra beats like PVCs or PACs.\n\nHope you have a great day! 😊",

"Hello {{name}},/nnI'm happy to inform you that your ECG looks perfect, with nothing to worry about! It displays a Sinus Rhythm, normal PQRST measurements, and no ectopic beats (like PVCs or PACs).\n\nTake care 😘",

"Hi {{name}},\n\nWonderful news! Your ECG results show a Sinus Rhythm, typical PQRST measurements, and no abnormal beats. Additionally, your heart is between 60 and 100 BPM, which is what we want to see. \n\nHave a good day ☀️",

"Hey {{name}},\n\nYour ECG results are here, and I'm thrilled to say that everything appears to be in line with the norm. A Sinus Rhythm, normal PQRST measurements, and no ectopic beats :) \n\nTake care and stay safe! 💖",

"Hi {{name}},\ n\nI'm pleased to inform you that your ECG results are normal. With a Sinus Rhythm, typical PQRST values, and no ectopic beats. Your heart rate is within normal range as well. 😊\n\nTake care! 💕",

"Hey {{name}},\ n\nI'm happy to report that your ECG results are in, and they show a completely normal heart rhythm (which is great news 🎉). Your PQRST values fall nicely within the norm, and I didn't find any ectopic beats in your recording.\n\nSending you lots of warm thoughts and care 🤗",

"Hi {{name}},\ n\nI've got some great news for you! Your ECG results show an excellent heart rhythm. It features a Sinus Rhythm, typical PQRST measurements, and no abnormal beats.\n\nHope you have a wonderful day! 😊",

"Hey {{name}}, \ n\nI'm delighted to let you know that your ECG results show nothing to worry about. With a Sinus Rhythm, usual PQRST values, and no ectopic beats - everything looks nice and normal.\n\nHave a good day 🤗",

"Hi {{name}},\ n\nI've carefully reviewed your ECG, and everything seems to be just right! The rhythm is distinct and even, and all the measurements are as they should be. 😊\n\nSending you lots of warm thoughts and care ☀️",

"Hey {{name}},\n\nFantastic news! Your ECG results show a normal sinus rhythm, no ectopic beats, and a completely normal PQRST pattern. To sum up - there is nothing to worry about!\n\nTake care and stay healthy! 🌟",

"Hi {{name}},\ n\nI'm happy to report that your ECG results show a beautifully regular sinus rhythm and a normal PQRST pattern. Additionally, there are no extra beats in your recording, which is good news.\n\nTake care 😘",

"Hey {{name}},\n\nYour ECG results are in, and they're fantastic! A strong, regular sinus rhythm, no ectopic beats, and all of your PQRST intervals are well within the norm as well. To sum up, there is nothing you should be concern with.\n\nTake care! 💖",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG results show a sinus rhythm (which is normal heart rhythm), no ectopic beats, and a standard PQRST pattern. There is nothing abnormal going on 😊. \n\nTake care! 💕",

"Hey {{name}},\n\nYour recording shows a sinus rhythm, no extra beats, and all PQRST values are within the norm. To sum up, no concerns - your ECG is excellent!\n\nWishing you all the best! 🥳",

"Hi {{name}},\ n\nI'm happy to inform you that your ECG looks perfect, with nothing to worry about! It displays a Sinus Rhythm, normal PQRST measurements, and no ectopic beats.\n\nTake care 😘",

"Hi {{name}},\n\Wonderful news! Your ECG results show a Sinus Rhythm, typical PQRST measurements, and no extra beats. Which means that there is nothing problematic going on in your recording.\n\nHave a good day 🌟",

"Hey {{name}},\n\nYour ECG results are here, and I'm thrilled to say that everything appears to be in line with the norm. A Sinus Rhythm, normal PQRST measurements, and no ectopic beats :) \n\nTake care and stay safe! 💖",

"Hi {{name}},\ n\nI'm pleased to inform you that your ECG results are normal. With a Sinus Rhythm, normal PQRST values, and no ectopic beats. 😊\n\nTake care! 💕",

"Hey {{name}},\ n\nI'm happy to report that your ECG results are in, and they show a completely normal heart rhythm (which is great news 🎉). Your PQRST values are textbook. Additionally, I didn't find any abnormal beats.\n\nSending you lots of warm thoughts 🤗",

"Hi {{name}},\ n\nI've got some great news for you! Your ECG results look perfect. It features a Sinus Rhythm, normal PQRST measurements, and no ectopic beats (I'm really happy to see it).\n\nHope you have a wonderful day! 😊",

"Hey {{name}}, \ n\nI'm delighted to let you know that your ECG results are looking great, and there is nothing to worry about. With a Sinus Rhythm, normal PQRST values, and no ectopic beats, everything looks normal.\n\nHave a good day 🤗",

"Hi {{name}},\ n\nI've carefully reviewed your ECG, and everything seems to be just right! The rhythm is normal and even, and all the measurements (heart rate and PQRST intervals) are as they should be. 😊\n\nSending you lots of warm thoughts and care ☀️",

"Hey {{name}},\n\nFantastic news! Your ECG results show a normal sinus rhythm, no ectopic beats, and a completely normal PQRST pattern.\n\nTake care! 🌟",

"Hi {{name}},\ n\nI'm happy to report that your ECG results show a beautifully regular sinus rhythm and a normal PQRST pattern. Additionally, there are no extra beats in your recording, which is good news.\n\nTake care 😘",

"Hey {{name}},\n\nYour ECG results are in, and they're fantastic! A strong and regular sinus rhythm, no ectopic beats, and all of your PQRST intervals are well within the norm.\n\nTake care 😘",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG results show a sinus rhythm (which is normal heart rhythm), no ectopic beats, and a standard PQRST pattern.\n\nTake care! 💕",

"Hey {{name}},\n\nYour recording shows a sinus rhythm (this is great news!), no extra beats and all PQRST values are within the norm. To sum up, no concerns - your ECG is excellent!\n\nWishing you all the best! 🥳",

"Greetings {{name}},\ n\nI'm pleased to let you know that your ECG results are in, and they're just what we want to see! Your rhythm is steady, and all the indicators (PQRST intervals and heart rate) are within the normal range. No abnormalities were detected. Keep up the good health habits! 😊",

"Hello {{name}},\n\nIn the world of ECGs, yours could be a teaching example of what we like to see. Regular, consistent, and with no abnormalities (like ectopic beats).\n\nTake care 😘",

"Hey {{name}},\ n\nI'm delighted to inform you that your ECG is looking AMAZING! A heart rate of {{heartrate}} bpm and beautifully regular rhythm - to sum up, it looks great. \n\nSending you lots of warm thoughts 😘",

"Hello {{name}}, 😊\ n\nI'm thrilled to share that your ECG results are in, and everything looks fantastic! Your heart is beating with a steady, regular rhythm, showing no signs of irregularities.\n\nWishing you all the best! 😘",

"Dear {{name}}, 🌟\n\nGreat news from your latest ECG! Your heart is performing splendidly, maintaining a perfect rhythm and pace. It's always wonderful to see results like these 😊 There are also no ectopic beats in your recording. \n\n All the best! 💕",

"Greetings {{name}},\n\nYour ECG results brought a smile to my face today! Your recording looks excellent, with normal rhythm and regular PQRST intervals. There are also no ectopic beats 👍\n\nKeep on shining! ✨",

"Hey {{name}}, 🎉\ n\nI've just had the pleasure of reviewing your ECG, and I must say, it's as if your heart wrote a love letter to life itself! Every beat is strong and steady, showing a balanced and regular heart rhythm.\ n\nLet's keep that heart of yours happy! ❤️",

"Hello {{name}}, ✨\n\nYour ECG results are in, and they're just brimming with good news. Your heart rhythm is regular, and all the PQRST values are just right - this is what we want to see! I also didn't find any extra beats.\n\nAll the best 😘",

"Hey {{name}}, 🌻\n\nYour ECG results are exactly what we want to see—normal and regular! Your PQRST intervals are well within the norm, and I didn't find any abnormal beats 😄.\n\nKeep up the great work! 💪",

"Hello {{name}}, 🌟\ n\nI'm thrilled to share that your ECG results have come back, and everything looks fantastic! Your heart rate and rhythm are in perfect harmony, which is exactly what we always want to see. Nothing here causes any concern, so you can take a deep breath and smile, knowing your ECG is looking great. Keep up with your healthy habits, and your heart will surely thank you! 😊👍",

"Hi {{name}} ✨,\n\nYour ECG is in, and the results are looking good!. Your heart is beating steadily, with all the PQRST values looking just right. It's always a joy to see such results 😊 Keep on shining! 🏆",

"Greetings {{name}}, 🌈\n\nI have some excellent news from your latest ECG—it's all clear! Your heart is ticking with a perfect rhythm, and there are no abnormal beats in the recording. It's results like these that make me smile! 😊\n\nWishing you a heart-full of happiness, {{name}}! Take care! 😊",

"Hey {{name}}, \n\nYour ECG results are in, and they're superb! Each heartbeat is right where it should be, and the overall pattern is regular and normal - exactly what we like to see in the ECG. I also didn't find any abnormal beats.\n\nAll the best 💓",

"Hi {{name}}, 🌷\n\nYour recent ECG has a regular and steady rhythm (which is what we want to see!). There are no abnormalities, and every PQRST measurement is within the ideal range.\n\nThis is a wonderful outcome, and I hope the rest of your day is as good as your ECG! 😄",

"Hello {{name}},\ n\nI've reviewed your ECG, and it's textbook perfect! Your heart's rhythm is regular, with no extraneous beats or disturbances. Additionally, all of your PQRST intervals are well within the norm. Exceptional ECG, {{name}}!\n\nLet's keep the positive energy rolling throughout your day. ✨",

"Hi {{name}}, 🌻\n\nYour ECG has brought me nothing but smiles today. With every measurement like PQRST intervals and heart rate sitting comfortably within the norm. It's the best outcome we could ask for. May the rest of your day be as flawless as your ECG! 😄",

"Hello {{name}},\n\nAfter a thorough examination of your ECG, I'm happy to report that your heart's rhythm is strong and regular, your PQRST intervals and heart rate are normal - and normal is definitely what we want to see.\n\nI really hope the rest of your day is equally brilliant. 🌟",

"Hey there, {{name}},\n\nAll is well with your ECG – it's as perfect as we could wish for. Your recordings show a nice and regular rhythm, and all of your measurements are perfectly normal. I also didn't find any abnormal beats.\n\nMay your day follow suit and bring you moments as pleasant as your heart's rhythm. 💓",

"Greetings, {{name}},\n\nDelighted to inform you that your ECG is looking fantastic – everything is ticking along just as it should. The rhythm is regular, and every measurement (PQRST intervals and heart rate) sits within the healthy range.\n\nAll the best 💓",

"Hello {{name}},\n\nI have reviewed your ECG, and it's excellent news – your heart's rhythm, rate, and PQRST values are exactly as they should be. No irregularities or concerns at all. May your day be as steady and peaceful as your ECG 😊👍",

"Hello {{name}},\n\nAfter analyzing your ECG, I'm pleased to say it doesn't get any better than this – everything is looking perfect. Your rhythm is normal and regular, your measurements are well within the norm, and I didn't find any extra beats 😊\n\nAll the best! 🌟",

"Hello {{name}},\ n\nI'm thrilled to share some excellent news with you today! After a thorough analysis of your ECG, it's clear that your recording shows a Sinus Rhythm. Additionally, your PQRST intervals are perfect, and there's a complete absence of ectopic beats, which is great news!\n\nSending you a burst of positive energy to brighten your day! 🌟",

"Hi {{name}},\ n\nI've just finished reviewing your ECG, and it's my pleasure to report back with nothing but good news. Your heart's electrical activity is displaying a textbook Sinus Rhythm, with PQRST intervals that are exactly as we would hope for. Moreover, I didn't find any abnormal beats.\n\nEmbracing you with hugs and a wave of positive vibes! 🤗",

"Hello {{name}},\n\nUpon examining your ECG, it's clear that your ECG is performing splendidly. Your Sinus Rhythm is impeccably regular, the PQRST complex is well within normal ranges, and it's particularly encouraging to see no ectopic beats interrupting the rhythm. It's always a pleasure to see such a great ECG.\n\nSending you many hugs! 💖",

"Hello {{name}},\n\nYour ECG has been analyzed, and I'm delighted to convey that the rhythm showcased is consistent and regular. I didn't find any abnormal beats, and all of your measurements are within the norm. It's a reassuring sign that everything is as expected. There's nothing in the findings that raises any concerns. I hope this message adds a touch of serenity to your day. 🌼",

"Hey there, {{name}},\ n\nIt's a pleasure to see your ECG displaying a strong and regular rhythm (this is what we want to see!). The measurements are precisely within the normal range, offering a comforting confirmation that all is looking well. I hope that your day is as smooth and untroubled as your ECG. 🤗",

"Hi {{name}} 😊,\n\nUpon reviewing your ECG, it's heartening to see that your rhythm is nice and regular. Your PQRST measurements and heart rate is normal as well. Rest assured, there are no abnormalities to worry about. 💖\n\nHave a great day!",

"Hi {{name}} 😊,\n\nYour rhythm is steady and regular - this is always what we want to see. Additionally, your PQRST intervals and heart rate are normal. This is a reassuring result that we're always pleased to see.\n\nAll the best! 🌟",

"Hello {{name}},\n\nYour ECG looks really nice. Your PQRST intervals and heart rate are just where we like to see them. Additionally, your rhythm is normal and regular (Sinus Rhythm). \n\nWishing you a day that's as pleasant and worry-free as your ECG results. 😊",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG looks awesome - your PQRST intervals and heart rate are comfortably within the normal range. Your ECG showcases a sinus rhythm - with exactly what we want to see.\n\nHave a beautiful day ahead! 🌼",

"Greetings {{name}},\n\nYour ECG reflects a beautiful sinus rhythm - which is exactly what we want to see. All the details, including PQRST intervals and heart rate, are well within the norm. Rest assured, your results are normal with no abnormalities in sight. 💖\n\nTake care!",

"Hello {{name}},\ n\nIt's a joy to share that your ECG showcases a sinus rhythm. Your PQRST intervals and heart rate at {{heartrate}} BPM fall nicely within the norm. There is absolutely nothing to worry about — your results are completely free of abnormalities.\n\nAll the best! 😊",

"Hello {{name}},\n\nFantastic news! Your heart's rhythm is beautifully consistent and regular. The PQRST intervals and heart rate are normal as well. There's not a single ectopic beat in sight. To sum up - there is nothing to worry about😊\n\nWishing you a great day!",

"Hi {{name}},\ n\nI'm overjoyed to report that your ECG is looking amazing. Your recording shows Sinus Rhythm - which is exactly what we want to see. Your ECG shows PQRST intervals that align perfectly with the norm. Rest assured, your results are normal with no abnormalities in sight. 💖\n\nHave a great day!",

"Greetings {{name}} 🌷,\n\nYour ECG results are in, and they're cause for celebration! You're exhibiting a perfect Sinus Rhythm with ideal PQRST intervals and heart rate.\n\nWishing you happiness as bright as your ECG results! ☀️",

"Hey {{name}},\ n\nI've got great news! Your heart is ticking away in perfect Sinus Rhythm with PQRST well-aligned with standard norms. Additionally, your heart at {{heartrate}} is exactly what we want to see.\n\nHave a great day 🎉",

"Hello {{name}},\n\nWhat a pleasure to see ECG results like you! Your heart rhythms are great and regular with PQRST intervals that align perfectly with the norm. No ectopic beats as well!\n\nWishing you a day as great as your results! 🌷",

"Hey {{name}},\n\nYour ECG results are here, and they're amazing! Your rhythm is beautiful and regular, and your PQRST intervals are exactly what we'd hope for (within the norm). Additionally, your heart rate is between 60 and 100 BPM, which is exactly what we want to see. Absolutely nothing to worry about—your results are completely free of abnormalities. 💖\n\nAll the best!",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG is looking good, and there is nothing to worry about. Your recording is showing Sinus Rhythm, and the PQRST intervals and heart rate comfortably fall within the expected range. Not a single ectopic beat in sight as well!\n\nAll the best 🌸",

"Hey {{name}},\ n\nI'm pleased to tell you that your ECG is showing a Sinus Rhythm - which is exactly what we want to see. Your heart rate and PQRST intervals are nicely within the norm. You're clear of ectopic beats - which means that there is nothing to worry about in your recording! 😊\n\nHave a fabulous day!",

"Hi {{name}},\n\nYour ECG is a cause for joy! Your heart is beating in a perfect Sinus Rhythm, with PQRST intervals and heart rate that are absolutely textbook. The absence of ectopic beats is the icing on the cake. Wishing you a day that's as lovely as your ECG results! 🍰",

"Hello {{name}},\ n\nI'm thrilled to let you know that your ECG results are exemplary. Your recording shows Sinus Rhythm with PQRST intervals that are just as they should be. It's a heartwarming sight to see such good results 😊\n\nI truly hope that the rest of your day will be as good as your results. 🔆",

"Hello {{name}} 🌷,\n\nYour ECG brings a smile to my face! Your rhythm shows Sinus Rhythm, with PQRST intervals just perfect. Additionally, at {{heartrate}} BPM falls nicely within the norm.\n\nMay your day be as cheerful as this news, {name}}! 😄",

"Hello {{name}},\ n\nI'm thrilled to let you know that your ECG has come back looking splendid. You're in a Sinus Rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM, all falling within the ideal range. Everything looks great and there is nothing to worry about.\n\nAll the best! 🌟",

"Hi {{name}},\n\nWonderful news! Your ECG looks absolutely beautiful, with a steady Sinus Rhythm and PQRST intervals that are absolutely within the norm. Your heart rate at {{heartrate}} BPM couldn't be better. Rest assured, your results are normal, with no abnormalities in sight. 💖\n\nTake care!",

"Hi {{name}},\n\nYour heart's rhythm and rate look perfect. With a Sinus Rhythm and PQRST intervals within the normal range, plus a heart rate at {{heartrate}} BPM, everything looks nice and normal 😄. It's all good news—no signs of anything abnormal! 💖",

"Greetings {{name}},\ n\nI'm smiling as I review your ECG – it's a flawless example of a Sinus Rhythm, with every PQRST interval and the heart rate in the ideal range. And no extra beats to complicate the picture.\n\nNo signs of anything abnormal in your recording at all. 🌷",

"Hi {{name}},\ n\nIt's delightful to see an ECG as perfect as yours, showing a Sinus Rhythm that's nice and regular. Your PQRST intervals and heart rate are exemplary, and the lack of ectopic beats is reassuring as well!\n\nHopefully rest of your day is as good as your ECG 😄",

"Hello {{name}},\n\nYour ECG brought a smile to my face – a Sinus Rhythm nice and regular, PQRST intervals, and a heart rate that could be used as a reference for normality. The absence of ectopic beats is just what we hoped for.\n\nHave a great day {{name}} 💖!",

"Hello {{name}},\n\nI am thrilled to report your ECG is exemplary – a Sinus Rhythm as regular as clockwork, ideal PQRST intervals, and the heart rate is nicely within the norm. No ectopic beats to report as well, which is excellent news.\n\nHope your day is as wonderful as your ECG findings! ✨",

"Hello {{name}},\n\nYour ECG is as good as it gets – a Sinus Rhythm that's regular and strong, PQRST intervals and heart rate that are the gold standard, and not a hint of ectopic beats. To sum up - everything looks great and there is nothing to worry about! ☀️",

"Hi {{name}},\ n\nIt's a pleasure to see such a good ECG as your, {{name}}. Your rhythm is nice and regular. PQRST intervals and heart rate are what we could put in the textbook. Additionally, there are no ectopic beats in sight.\n\nEverything's in top shape ☀️ All the best!",

"Hey {{name}},\n\nYour ECG results are in, and they're superb – a nice and regular Sinus Rhythm, ideal PQRST intervals, and a heart rate that's nicely within the norm, plus not a single ectopic beat in sight! To put it briefly, everything looks excellent!\n\nI really hope your day is as good as your results 😄",

"Hey {{name}},\n\nYour ECG results are in, and they're picture-perfect! A regular Sinus Rhythm, PQRST intervals in the perfect range, and a heart rate that's just what we want to see. To boil it down, your recording is flawless, with no abnormalities detected 💖!\n\nHave a great day!",

"Greetings {{name}},\ n\nI'm excited to share that your ECG is absolutely what we always hope to see: a regular Sinus Rhythm, PQRST intervals well within the normal range, and a heart rate that's just right. And the best part? No ectopic beats whatsoever!\n\nAll the best 🌟",

"Hi {{name}},\n\nWhat a joy to review your ECG! A Sinus Rhythm that couldn't be steadier, PQRST intervals, and heart rate that are just perfect. And no ectopic beats in sight! In a nutshell, your recording is clean as a whistle 😄\n\nHave a nice day!",

"Hello {{name}},\ n\nI'm over the moon with your ECG results – a Sinus Rhythm that's beautiful and regular, with PQRST intervals and a heart rate that is exactly where we want them. There are no ectopic beats, just a perfect recording with nothing to worry about. 💃",

"Hi {{name}} 💖,\ n\nIt's heartening to see your ECG – a Sinus Rhythm that's nice and steady, PQRST intervals that are the gold standard, and a heart rate that's spot-on. Ectopic beats? None in sight.\n\nSumming it up, your recording presents zero abnormalities, ensuring there's nothing for you to worry about 😄\n\nTake care!",

"Hey {{name}},\n\nYour ECG is clear, and there is nothing to worry about – a Sinus Rhythm that's regular and reassuring, PQRST intervals, and a heart rate that's nicely within the norm. And no ectopic beats, which is the cherry on top! 🍒\n\nAll the best!",

"Greetings, {{name}}!\ n\nI'm happy to let you know that your ECG reflects a flawless Sinus Rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM that couldn't be more perfect. \n\nIn essence, your recording is great with no abnormalities and nothing to worry about 😄",

"Hey {{name}},\ n\nIt's a beautiful day to have an ECG like yours! Your recording indicates a Sinus Rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM that couldn't be more ideal.\n\nI really hope that the rest of your day will be as good as your results 💖. All the best!",

"Hello {{name}},\n\nWonderful news! Your ECG presents a steady sinus rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM, all comfortably within the normal range.\n\nTo boil it down, your recording is flawless, with no abnormalities detected 😄",

"Hi {{name}},\n\nJust went over your ECG, and guess what? It's perfect! Sinus rhythm, PQRST intervals, and heart rate — all within the normal range. Additionally, there are no ectopic beats.\n\nThere is nothing to worry about in your recording, {{name}}. Have a nice day! 😄",

"Hey {{name}} 😄,\n\nYour recent ECG is a reason to celebrate! Sinus rhythm? Check. Normal PQRST intervals and heart rate? Double-check. It's all clear, without a single abnormal beat or a reason to worry.\n\nAll the best! 💖",

"Greetings {{name}},\ n\nI'm happy to inform you that your ECG results are as good as it gets. Your recording shows sinus rhythm (this is great news!), PQRST intervals are spot on, and the heart rate is a steady {{heartrate}} BPM. To compress, there's not an abnormality in your recording, so rest assured there is nothing to worry about here 😄.\n\nTake care!",

"Hello {{name}},\n\nI have some excellent news — your ECG is as good as it gets! Sinus rhythm is present, PQRST intervals are aligned with the norm, and the heart rate is a solid {{heartrate}} BPM.\n\nAll reasons to smile! 😄",

"Hey {{name}},\n\nJust went over your ECG, and it's fantastic – a Sinus Rhythm with PQRST intervals that are the gold standard and a heart rate that's exactly where we want it. Zero ectopic beats as well!\n\nTo boil it down, your recording is flawless 💓!",

"Hello {{name}},\n\nDelighted to inform you that your ECG looks amazing – showcasing a Sinus Rhythm that's as steady as they come, with PQRST intervals and a heart rate that is comfortably within the normal range. Plus, the absence of ectopic beats is a sweet bonus! 🌟\n\nTake care!",

"Greetings {{name}},\n\nYour ECG looks very nice – a beautifully regular Sinus Rhythm, spot-on PQRST intervals, and a heart rate that's just right. Not to mention completely free of ectopic beats.\n\nBest wishes 💓!",

"Hey there {{name}},\n\nJust reviewed your ECG, and it looks AMAZING – a Sinus Rhythm that's nice and regular, PQRST intervals, and a heart rate that's the epitome of normal. And zero ectopic beats to speak of - this is what we want to see.\n\nAll the best!",

"Hi {{name}},\n\nYour ECG brought a smile to my face – a Sinus Rhythm that's consistent and stable, PQRST intervals, and heart rate that's perfectly placed within the normal range. And a complete no-show of abnormal beats! It's all looking great, {{name}}! 😊\n\nBest regards!",

"Hello {{name}},\n\nChecking your ECG has been the highlight of my day – a Sinus Rhythm that's predictably regular, PQRST intervals that are flawless, and a heart rate that's neatly within normal limits. And the lack of ectopic beats? Perfect! 🌼\n\nWarmest regards!",

"Hello {{name}} 🌟,\ n\nI'm thrilled to share that your ECG results are perfect! Sinus rhythm, PQRST intervals, and heart rate are all within the normal range. In essence, your recording is great with no abnormalities and nothing to worry about 😄.\n\nHopefully your day is as good as your results 💓!",

"Hello {{name}},\n\nI have some delightful news for you – your heart is keeping a perfect rhythm! The PQRST intervals and heart rate are all normal, without a hint of an ectopic beat. This is what we always hope to see, as there is nothing to worry about.\n\nAll the best! 🌟",

"Hi, {{name}},\ n\nI'm happy to let you know that your ECG is exceptionally good! Its rhythm is nice and regular, with PQRST intervals and heart rate all in the normal range. Abnormal beats? None to be found! You've got no reason to be worried. 😊\n\nWishing you a day that's as cheerful as this news!",

"Hello {{name}},\n\nToday I bring you great news! Your heart is keeping a beautifully consistent beat, and the PQRST intervals along with your heart rate are all normal. There's not a single ectopic beat disrupting this rhythm, which is what we want to see 😊. To put it simply, there is nothing to worry about.\n\nAll the best!",

"Hello {{name}} 😊,\n\nChecking your ECG has been a great joy – a Sinus Rhythm that's regular and steady, PQRST intervals, and a heart rate that's neatly within normal limits. And the lack of ectopic beats? Perfect! 🌼\n\nWarmest regards!",

"Hello {{name}},\n\nJust finished reviewing your ECG, and it's all positive news. You've got a steady Sinus Rhythm, PQRST intervals are in the normal range, and there are zero ectopic beats to report. All is as it should be!\n\nWishing you well! 🤗",

"Greetings {{name}},\n\nYour ECG results are in, and they're looking great. Your heart's rhythm is normal and steady (this is what we want to see), the PQRST measurements are textbook, and ectopic beats are nowhere to be found. To sum up, there is nothing to worry about.\n\nBest regards! 🤗",

"Hello {{name}},\n\nYour ECG depicts nothing but good news. A flawless Sinus Rhythm, impeccable PQRST intervals, and a complete absence of ectopic beats. To boil it down, your recording is looking great, with no abnormalities detected and nothing to stress about 🤗.\n\nWishing you all the best!",

"Hey {{name}},\n\nToday, I bring you only great news! Your heart is maintaining a beautifully consistent rhythm, and the PQRST intervals, along with your heart rate, are all normal. There's not a single extra beat disrupting this rhythm, which definitely is what we love to see.\n\nWarmest regards! 🌼",

"Hi {{name}},\n\nYour ECG looks fantastic! Everything looks good, all measurements are normal, and I didn't find any abnormal beats in your recording.\n\nHope the rest of your day will be as good as your ECG ❤️",

"Hello {{name}},\n\nI have to tell you that it is a really lovely ECG ❤️ There's nothing wrong going on with your ECG — no unusual beats. Every measurement is within limits :)\n\nTake care :)",

"Hey {{name}},\n\nLooking at all the measurements, I can tell you that everything is looking good :) Your rhythm looks nice and clean. Plus, there are no abnormal beats :)\n\nBest regards! ❤️",

"Hi {{name}},\n\nGood news :) After reviewing your ECG, I can say that everything looks very normal. All values are nicely within normal limits. I also didn't find any abnormal beats. This is what we all love to see.\n\nHave a great day 😊",

"Hey {{name}} 🌟,\n\nYour ECG looks perfect and clean. Your heart rate and PQRST values are well within their usual range, and no ectopic beats were noted. To sum up, no reason to worry about 😄\n\nTake care!",

"Hello {{name}},\ n\nI'm happy to say that your ECG looks perfect and clean. Your heart rate and PQRST values are all within the normal range, and no ectopic beats were found.\n\nTake care! 🤗",

"Hi {{name}},\n\nCongratulations! Your ECG looks perfect and clean. Your heart rate and PQRST values are all within the normal range, and no ectopic beats were recorded.\n\nHave a day as good as your ECG 😊",

"Hello {{name}},\ n\nI'm delighted to say that your ECG looks good. Your heart rate and PQRST values are all within the normal range, and no ectopic beats were found as well.\n\nHave a good day! 😊",

"Hey {{name}},\n\nYour ECG results are wonderful! 😊 All measurements are in the normal range, and no unusual beats were detected.\n\nKeep up the good work! 🌟",

"Hi {{name}},\n\nI looked at your ECG carefully, and everything seems fine! The rhythm is clear and regular, and all the measurements are normal. Nothing out of the ordinary is going on 😉\n\nTake care! ❤️",

"Hey {{name}},\n\nI checked your ECG closely, and I'm happy to say it all looks good! The rhythm is neat and clean, and all the numbers are within the usual range. 🌟\n\nHave a fabulous day!",

"Hi {{name}},\n\nI examined your ECG, and I'm glad to report that it's all normal! The rhythm is clean and regular, and every measurement is within the expected range.\n\nHave a great rest of your day! 😊",

"Hey {{name}},\n\nAfter going over your recording, I'm happy to say everything is fine. The rhythm is normal, and all measurements are within normal limits. Additionally, I didn't find any abnormal beats - which is great news! 🎉\n\nTake care!",

"Hello {{name}},\n\nI examined your recording and didn't find any problems. The rhythm is very good and regular, and every measurement is in the expected range. 😄\n\nSending lots of love your way! ❤️",

"Hi {{name}},\n\nI looked closely at your recording and saw no issues. The rhythm is really good, and all the numbers (heart rate and PQRST values) are in the usual range. 🌟\n\nBest regards ❤️",

"Hey {{name}},\n\nYour recording looks great! It reveals a Sinus Rhythm, with normal PQRST values and no ectopic beats.\n\nHave a great day! 🌟",

"Hello {{name}},\n\nGood news! Your recording shows a Sinus Rhythm. The PQRST measurements are normal, and there are no ectopic beats.\n\nHave a great rest of your day! 😊",

"Hi {{name}},\ n\nI'm pleased to share that your recording demonstrates a Sinus Rhythm (normal heart rhythm). Normal PQRST and no ectopic beats – that's fantastic news! 🎉\n\nSending lots of love your way! ❤️",

"Hey {{name}},\ n\nI'm thrilled to share that your recording is looking wonderful! It displays a Sinus Rhythm, normal PQRST values, and no ectopic beats.\n\nHave a fabulous day ahead!",

"Hello {{name}},\ n\nI'm happy to report that your recording looks fabulous! It exhibits a Sinus Rhythm, normal PQRST, and no abnormal beats like PVCs and PACs. Nothing is worrying about your recording 😃 \n\nSending you positive energy! 🌟",

"Hi {{name}},\n\nWonderful news! Your recording displays a Sinus Rhythm, normal PQRST measurements, and no extra beats.\n\nSending you hugs and positive energy! 🤗",

"Hello {{name}},\n\nI just checked out your ECG, and I'm happy to report that there's nothing concerning in the ECG results. Your heart is showing a Sinus Rhythm with normal PQRST values and no ectopic beats 😊.\n\nI hope this helps! Stay safe! 💖",

"Hi {{name}},\n\nI reviewed your ECG, and everything looks good. There's nothing to be concerned about, as it indicates a Sinus Rhythm, normal PQRST, and no ectopic beats.\n\nTake care! 🤗",

"Hey {{name}},\n\nI wanted to let you know that your ECG results show no cause for concern. Your heart is in Sinus Rhythm with normal PQRST values and no ectopic beats.\n\nHave a peaceful day! 🙏🏼",

"Hello {{name}},\ n\nI've got some great news for you! Your ECG looks perfect, with nothing to be concerned about. It shows a Sinus Rhythm, normal PQRST measurements, and no ectopic beats.\n\nI hope this was helpful and give you some peace of mind ✨",

"Hi {{name}},\n\nWonderful news! Your ECG shows no cause for concern 🤗. It indicates a Sinus Rhythm, normal PQRST values, and no ectopic beats.\n\nTake care! 💕",

"Hey {{name}},\n\nYour ECG results are in, and I'm glad to say there's nothing to worry about! It shows a Sinus Rhythm, normal PQRST values, and no ectopic beats.\n\nSending you lots of warm thoughts 🤗",

"Hello {{name}},\n\nI just reviewed your ECG, and I'm happy to report that there's nothing alarming in the results. Your heart is showing a Sinus Rhythm with normal PQRST values and no ectopic beats.\n\nHave a fabulous day! 🌟",

"Hi {{name}},\n\nFantastic news! I looked at your ECG, and everything appears good. There's nothing to be troubled about, as it indicates a Sinus Rhythm, normal PQRST, and no ectopic beats.\n\nI hope this was helpful.❤️",

"Hey {{name}},\ n\nI'm pleased to share that your ECG looks wonderful! There's nothing problematic in the results, as they display a Sinus Rhythm, normal PQRST measurements, and no ectopic beats.\n\nI hope this helps! Always take care 💖",

"Hi {{name}},\n\nWonderful news! Your ECG shows no cause for distress. It indicates a Sinus Rhythm, normal PQRST values, and no ectopic beats.\n\nHope you have a great day! 😊",

"Hello {{name}},\ n\nI'm happy to inform you that your ECG looks perfect, with nothing to worry about! It displays a Sinus Rhythm, normal PQRST measurements, and no ectopic beats.\n\nTake care 😘",

"Hi {{name}},\n\nWonderful news! Your ECG results show a Sinus Rhythm (this is normal heart rhythm), typical PQRST measurements, and no extra beats.\n\nHave a good day ☀️",

"Hey {{name}},\n\nYour ECG results are here, and I'm thrilled to say that everything appears to be in line with the norm. A Sinus Rhythm, normal PQRST measurements, and no ectopic beats :) \n\nTake care and stay safe! 💖",

"Hi {{name}},\ n\nI'm pleased to inform you that your ECG results are all normal. With a Sinus Rhythm, typical PQRST values, and no ectopic beats 😊\n\nTake care! 💕",

"Hey {{name}},\ n\nI'm happy to report that your ECG results are in, and they show a completely normal heart rhythm (which is great news 🎉). Your PQRST values and absence of ectopic beats are all signs of normal ECG.\n\nSending you lots of warm thoughts and care 🤗",

"Hi {{name}},\ n\nI've got some great news for you! Your ECG results show an excellent heart rhythm. It features a Sinus Rhythm, typical PQRST measurements, and no ectopic beats.\n\nHope you have a wonderful day! 😊",

"Hey {{name}}, \ n\nI'm delighted to let you know that your ECG results show nothing to worry about. With a Sinus Rhythm, usual PQRST values, and no ectopic beats, everything looks normal.\n\nHave a good day 🤗",

"Hi {{name}},\ n\nI've carefully reviewed your ECG, and everything seems to be just right! The rhythm is distinct and even, and all the readings are as they should be. 😊\n\nSending you lots of warm thoughts and care ☀️",

"Hey {{name}},\n\nFantastic news! Your ECG results show a normal sinus rhythm, no extra beats (like PVCa and PACs), and a completely normal PQRST pattern.\n\nTake care and! 🌟",

"Hi {{name}},\ n\nI'm happy to report that your ECG results show a beautifully regular sinus rhythm and a normal PQRST pattern. Additionally, there are no extra beats in your recording, which is good news.\n\nTake care 😘",

"Hey {{name}},\n\nYour ECG results are in, and they're fantastic! A strong, regular sinus rhythm, no ectopic beats, and all of your PQRST intervals are well within the norm. I'm happy to say that there is nothing to worry about!\n\nTake care 😘",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG results show a sinus rhythm, no ectopic beats, and a standard PQRST pattern. It's great news, and it means that there is nothing to worry about your recording.\n\nTake care! 💕",

"Hey {{name}},\n\nYour recording shows a sinus rhythm (this is great news), no extra beats and all PQRST values are within the norm. To sum up, no concerns - your ECG looks excellent!\n\nWishing you all the best! 🥳",

"Hi {{name}},\ n\nI'm happy to inform you that your ECG looks perfect, with nothing to worry about! It displays a Sinus Rhythm, normal PQRST measurements, and no ectopic beats (I'm super happy to report that!).\n\nTake care 😘",

"Hi {{name}},\n\nWonderful news! Your ECG results show a Sinus Rhythm, typical PQRST measurements, and no extra beats. I'm happy to report that everything looks amazing.\n\nHave a good day 🌟",

"Hey {{name}},\n\nYour ECG results are here, and I'm thrilled to say that everything appears to be in line with the norm. A Sinus Rhythm, normal PQRST measurements, and no ectopic beats :) \n\nTake care and stay safe! 💖",

"Hi {{name}},\ n\nI'm pleased to inform you that your ECG results are normal (I'm not going to lie, I love to see that 💖). With a Sinus Rhythm, normal PQRST values, and no ectopic beats.\n\nTake care! 😊",

"Hey {{name}},\ n\nI'm happy to report that your ECG results are in, and they show a completely normal heart rhythm (which is great news 🎉). Additionaly, you have normal PQRST values, and no abnormal beats.\n\nSending you lots of warm thoughts and care 🤗",

"Hi {{name}},\ n\nI've got some great news for you! Your recording is looking perfectly normal. It features a Sinus Rhythm, normal PQRST measurements, and no ectopic beats.\n\nHope you have a wonderful day! 😊",

"Hey {{name}}, \ n\nI'm delighted to let you know that your ECG results show nothing to worry about. With a Sinus Rhythm, normal PQRST values, and no ectopic beats, everything looks normal.\n\nHave, a good day 🤗",

"Hi {{name}},\ n\nI've carefully reviewed your ECG, and everything seems to be just right! The rhythm is normal and even, and all the measurements are as they should be. 😊\n\nSending you lots of warm thoughts and care ☀️",

"Hey {{name}},\n\nFantastic news! Your ECG results show a normal sinus rhythm, no ectopic beats, and a completely normal PQRST pattern. To sum up - everything is looking good! \n\nTake care! 🌟",

"Hi {{name}},\ n\nI'm happy to report that your ECG results show a beautifully regular sinus rhythm and a normal PQRST pattern. Additionally, there are no extra beats in your recording, which is good news.\n\nTake care 😘",

"Hey {{name}},\n\nYour ECG results are in, and they're fantastic! A strong, regular sinus rhythm, no ectopic beats, and all of your PQRST intervals are well within the norm.\n\nTake care 😘",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG results show a sinus rhythm, no ectopic beats, and a standard PQRST pattern, which means that there is nothing to worry about in your recording. \n\nTake care! 💕",

"Hey {{name}},\n\nYour recording shows a sinus rhythm (this is normal heart rhythm), no extra beats and all PQRST values are within the norm. To sum up, no concerns - your ECG is excellent!\n\nWishing you all the best! 🥳",

"Greetings {{name}},\ n\nI'm pleased to announce that your ECG results are in, and they're just what we want to see! Your heart rate is steady, and all the indicators (PQRST intervals and heart rate) are well within the normal range. No abnormalities were detected. Keep up the good health habits! 😊",

"Hello {{name}},\n\nIn the world of ECGs, yours could be a teaching example of what we like to see. Regular, consistent, and with no abnormalities (like ectopic beats).\n\nTake care 😘",

"Hey {{name}},\ n\nI'm delighted to inform you that your ECG is looking fabulous! A heart rate of {{heartrate}} bpm and beautifully regular rhythm - to sum up, it looks great. \n\nSending you lots of warm thoughts and care 💕",

"Hello {{name}}, 😊\ n\nI'm thrilled to share that your ECG results are in, and everything looks fantastic! Your heart is beating with a steady, regular rhythm, showing no signs of irregularities.\n\nWishing you all the best! 😘",

"Hi {{name}}, 🌟\n\nGreat news from your latest ECG! Your heart is performing splendidly, maintaining a perfect rhythm and pace. It's always wonderful to see results like these 😊 There are also no ectopic beats in your recording.\n\n All the best! 💕",

"Greetings {{name}},\n\nYour ECG results brought a smile to my face today! Your recording looks excellent, with normal rhythm and regular PQRST intervals. There are also no ectopic beats 👍\n\nKeep on shining! ✨",

"Hey {{name}}, 🎉\ n\nI've just had the pleasure of reviewing your ECG, and I must say, it's as if your heart wrote a love letter to life itself! Every beat is strong and steady, showing a balanced and regular heart rhythm.\ n\nLet's keep that heart of yours happy! 😘",

"Hello {{name}}, ✨\n\nYour ECG results are in, and they're just brimming with good news. Your heart rhythm is regular, and all the PQRST values are just right - this is what we want to see! I also didn't find any extra beats.\n\n All the best 😘",

"Hey {{name}}, 🌻\n\nYour ECG results are exactly what we want to see—normal and regular! Your PQRST intervals are well within the norm, and I didn't find any abnormal beats 😄.\n\nKeep up the great work! 💪",

"Hello {{name}}, 🌟\ n\nI'm thrilled to share that your ECG results have come back, and everything looks fantastic! Your rhythm looks perfect, which we always want to see. Nothing here causes any concern, so you can take a deep breath and smile, knowing your ECG is looking great. Keep up with your healthy habits, and your heart will surely thank you! 😊👍",

"Hi {{name}}, ✨\n\nYour ECG is in, and the results are looking good!. Your heart is beating at a steady pace, with all the PQRST values looking just right. It's always a joy to see such results 😊\n\nKeep on shining, {{name}}! 🏆",

"Greetings {{name}} 💖,\n\nI have some excellent news from your ECG—it's all clear! Your heart is ticking with a perfect rhythm, and there are no abnormal beats in the recording. It's results like these that make me smile! 😊 Wishing you a heart-full of happiness, {{name}}!\n\nTake care! 😊",

"Hey {{name}}, \n\nYour ECG results are in, and they're superb! Each heartbeat is right where it should be, and the overall pattern is regular and normal - exactly what we like to see in the ECG. I also didn't find any abnormal beats.\n\nAll the best 💓",

"Hi {{name}}, 🌷\n\nYour recent ECG has a regular and steady rhythm (which is what we want to see!). There are no abnormalities, and every PQRST measurement is within the ideal range.\n\nThis is a wonderful outcome, and I hope the rest of your day is as good as your ECG! 😄",

"Hello {{name}},\ n\nI've reviewed your ECG, and it's textbook perfect! Your heart's rhythm is regular, with no abnormal beats or disturbances. Additionally, all of your PQRST values are well within the norm. Exceptional ECG, {{name}}!\ n\nLet's keep the positive energy rolling throughout your entire day. ✨",

"Hi {{name}}, 🌻\n\nYour ECG has brought me nothing but smiles today. With every measurement like PQRST intervals and heart rate sitting comfortably within the norm. It's the best outcome we could ask for. May the rest of your day be as flawless as your ECG! 😄",

"Hello {{name}},\n\nAfter a thorough examination of your ECG, I'm happy to report that your heart's rhythm is strong and regular, your PQRST intervals and heart rate are normal - and normal is definitely what we want to see.\n\nI really hope the rest of your day is equally brilliant. 🌟",

"Hey there, {{name}},\n\nAll is well with your ECG – it's as perfect as we could wish for. Your recordings show a nice and regular rhythm, and all of your measurements are perfectly normal. I also didn't find any abnormal beats.\n\nMay your day follow suit and bring you moments as pleasant as your heart's rhythm. 💓",

"Greetings, {{name}},\n\nDelighted to inform you that your ECG is looking fantastic – everything is ticking along just as it should. The rhythm is regular, and every measurement (PQRST intervals and heart rate) sits within the healthy range.\n\nAll the best 💓",

"Hello {{name}},\n\nI have reviewed your ECG, and it's excellent news – your heart's rhythm is looking amazing. No irregularities or concerns at all. May your day be as steady and peaceful as your ECG 😊👍",

"Hello {{name}},\n\nAfter analyzing your ECG, I'm pleased to say it doesn't get any better than this – everything is looking perfect. Your rhythm is normal and regular, your measurements are well within the norm, and I didn't find any extra beats 😊\n\nAll the best! 🌟",

"Hello {{name}},\ n\nI'm thrilled to share some excellent news with you today! After a thorough analysis of your ECG, it's clear that your recording shows a Sinus Rhythm. Additionally, your PQRST intervals are perfect, and there's a complete absence of ectopic beats, which is great news!\n\nSending you a burst of positive energy to brighten your day! 🌟",

"Hi {{name}},\ n\nI've just finished reviewing your ECG, and it's my pleasure to report back with nothing but good news. Your heart is displaying a textbook Sinus Rhythm, with PQRST intervals that are exactly as we would hope for. Moreover, the lack of ectopic beats adds to the reassuring nature of your results.\n\nEmbracing you with hugs and a wave of positive vibes! 🤗",

"Hello {{name}},\n\nUpon examining your ECG, it's clear that your ECG is looking splendid. Your Sinus Rhythm is impeccably regular, the PQRST complex is well within normal ranges, and it's particularly encouraging to see no ectopic beats interrupting the rhythm. It's always a pleasure to see such a good recording.\n\nWishing you a great day! 💖",

"Hello {{name}},\n\n I have analyzed your ECG, and I'm delighted to say that the rhythm is consistent and regular. I didn't find any abnormal beats, and all of your measurements are within the norm. It's a reassuring sign that everything is as expected. There's nothing in the findings that raises my concerns.\n\nHoping this message adds a touch of serenity to your day. 🌼",

"Hey there, {{name}},\ n\nIt's a pleasure to see your ECG displaying a strong and regular rhythm (this is what we want to see!). The measurements are precisely within the normal range, offering a comforting confirmation that all looking great.\n\nI hope that your day is as smooth and untroubled as your ECG. 🤗",

"Hi {{name}} 😊,\n\nUpon reviewing your ECG, it's heartening to see that your rhythm is nice and regular. Your PQRST measurements and heart rate are normal as well. Rest assured; there are no abnormalities to worry about in your recording 💖.\n\nHave a great day!",

"Hi {{name}} 😊,\n\nYour rhythm is steady and regular - this is always what we want to see. Additionaly, your PQRST intervals, and heart rate are normal. This is a reassuring result that we're always pleased to see.\n\nAll the best! 🌟",

"Hello {{name}},\n\nYour ECG looks really nice. Your PQRST intervals and heart rate are just where we like to see them. Additionally, your rhythm is normal and regular (Sinus Rhythm). Wishing you a day that's as pleasant and worry-free as your ECG results. 😊",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG looks awesome - your PQRST intervals and heart rate are comfortably within the normal range. Your ECG showcases a sinus rhythm - which is exactly what we want to see.\n\nHave a beautiful day ahead! 🌼",

"Greetings {{name}},\n\nYour ECG reflects a beautiful sinus rhythm - which is exactly what we want to see. All the details, including PQRST intervals and heart rate, are well within the norm. Rest assured, your results are normal, with no abnormalities in sight. 💖\n\nAll the best!",

"Hello {{name}},\ n\nIt's a joy to share that your ECG showcases a sinus rhythm. Your PQRST intervals and heart rate at {{heartrate}} BPM fall nicely within the norm. There is absolutely nothing to worry about — your results are completely free of abnormalities.\n\nAll the best! 😊",

"Hello {{name}},\n\nFantastic news! Your heart's rhythm is beautifully consistent and regular. The PQRST intervals and heart rate are normal as well. There's not a single ectopic beat in sight. To sum up - there is nothing to worry about 😊\n\nWishing you a great day!",

"Hi {{name}},\ n\nI'm overjoyed to report that your ECG is looking amazing. Your recording shows Sinus Rhythm - which is exactly what we want to see. Your ECG shows PQRST intervals that align perfectly with the norm.\n\nRest assured, your results are normal, with no abnormalities in sight. 💖",

"Greetings {{name}},\n\nYour ECG results are in, and they're cause for celebration! You're exhibiting a perfect Sinus Rhythm with ideal PQRST intervals and heart rate.\n\nWishing you happiness as bright as your ECG results! ☀️",

"Hey {{name}},\ n\nI've got great news! Your heart is ticking away in perfect Sinus Rhythm with PQRST well-aligned with standard norms. Additionally, your heart at {{heartrate}} is exactly what we want to see.\n\nHave a great day 🎉",

"Hello {{name}},\n\nWhat a pleasure to see ECG results like yours! Your rhythm is great and regular, with PQRST intervals that align perfectly with the norm. No ectopic beats as well!\n\nWishing you a day as great as your results! 🌷",

"Hey {{name}},\n\nYour ECG results are here, and they're amazing! Your rhythm is beautiful and regular, and your PQRST intervals are exactly what we'd hope for (within the norm). Additionally, your heart rate is between 60 and 100 BPM, which is exactly what we want to see. Absolutely nothing to worry about—your results are completely free of abnormalities. 💖\n\nAll the best!",

"Hi {{name}},\ n\nI'm delighted to inform you that your ECG is looking good, and there is nothing to worry about. Your recording shows Sinus Rhythm, and the PQRST intervals and heart rate comfortably fall within the expected range. Not a single ectopic beat in sight as well!\n\nAll the best 🌸",

"Hey {{name}},\ n\nI'm pleased to tell you that your ECG is showing a Sinus Rhythm - which is what we want to see. Your heart rate and PQRST intervals are nicely within the norm. You're clear of ectopic beats - which means that there is nothing to worry about in your recording! 😊\n\nHave a great day!",

"Hi {{name}},\n\nYour ECG is a cause for joy! Your heart is beating in a perfect Sinus Rhythm, with PQRST intervals and heart rate that are absolutely textbook. The absence of ectopic beats is the icing on the cake.\n\nWishing you a day that's as lovely as your ECG results! 🍰",

"Hello {{name}},\ n\nI'm thrilled to let you know that your ECG results are exemplary. Your recording shows Sinus Rhythm with PQRST intervals that are just as they should be. It's a heartwarming sight to see such good results.\n\nI really hope that the rest of your day will be as good as your results. 🔆",

"Hello {{name}},\n\nYour ECG brings a smile to my face! Your rhythm shows Sinus Rhythm, with PQRST intervals just perfect. Additionally, at {{heartrate}} BPM, your heart rate falls nicely within the norm.\n\nMay your day be as cheerful as this news, {name}}! 😄",

"Hello {{name}},\ n\nI'm thrilled to let you know that your ECG has come back looking splendid. You're in a Sinus Rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM, all falling within the ideal range. Everything looks great, and there is nothing to worry about.\n\nAll the best! 🌟",

"Hi {{name}},\n\nWonderful news! Your ECG looks beautiful, with a steady Sinus Rhythm and PQRST intervals that are absolutely within the norm. Your heart rate at {{heartrate}} BPM couldn't be better.\n\nRest assured, your results are normal, with no abnormalities in sight. 💖",

"Hi {{name}},\n\nYour heart's rhythm and rate look perfect. With a Sinus Rhythm and PQRST intervals within the normal range, plus a heart rate at {{heartrate}} BPM, everything looks nice and normal 😄 .\n\nIt's all good news—no signs of anything abnormal to be found! 💖",

"Greetings {{name}},\ n\nI'm smiling as I review your ECG – it's a flawless example of a Sinus Rhythm, with every PQRST interval and the heart rate in the ideal range. And no extra beats to complicate the picture.\n\nNo signs of anything abnormal in your recording at all. 🌷 All the best!",

"Hi {{name}},\ n\nIt's delightful to see an ECG as perfect as yours, showing a Sinus Rhythm that's nice and regular. Your PQRST intervals and heart rate are exemplary, and the lack of ectopic beats is reassuring as well!\n\nHopefully rest of your day is as good as your ECG 😄",

"Hello {{name}},\n\nYour ECG brought a smile to my face – a nice and regular Sinus Rhythm, PQRST intervals, and a heart rate that could be used as a reference for normality. The absence of ectopic beats is just what we hoped for.\n\nHave a great day {{name}} 💖!",

"Hello {{name}},\n\nI am thrilled to report your ECG is exemplary – a Sinus Rhythm as regular as clockwork, ideal PQRST intervals, and the heart rate is nicely within the norm. There are no ectopic beats to report as well, which is excellent news.\n\nHope your day is as wonderful as your ECG findings! ✨",

"Hello {{name}},\n\nYour ECG is as good as it gets – a Sinus Rhythm that's regular and strong, PQRST intervals and heart rate that are the gold standard, and not a hint of ectopic beats.\n\nTo sum up - everything looks great, and there is nothing to worry about! ☀️",

"Hi {{name}},\ n\nIt's a pleasure to see such a good ECG as your, {{name}}. Your rhythm is nice and regular. PQRST intervals and heart rate are what we could put in the textbook. Additionally, there are no ectopic beats in sight.\n\nEverything's in top shape ☀️",

"Hey {{name}},\n\nYour ECG results are in, and they're superb – a nice and regular Sinus Rhythm, ideal PQRST intervals, and a heart rate that's nicely within the norm, plus not a single ectopic beat in sight! To put it briefly, everything looks excellent!\n\nI really hope your day is as good as your results 😄",

"Hey {{name}},\n\nYour ECG results are in, and they're picture-perfect! A regular Sinus Rhythm, PQRST intervals in the perfect range, and a heart rate that's just what we want to see.\n\nTo boil it down, your recording is flawless, with no abnormalities detected 💖!",

"Greetings {{name}},\ n\nI'm excited to share that your ECG is absolutely what we always hope to see: a normal Sinus Rhythm, PQRST intervals well within the typical range, and a heart rate that's just right. And the best part? No ectopic beats whatsoever!\n\nAll the best 🌟",

"Hi {{name}},\n\nWhat a joy to review your ECG! A Sinus Rhythm that couldn't be steadier, PQRST intervals, and heart rate that are just perfect. And no ectopic beats in sight! In a nutshell, your recording is clean as a whistle 😄",

"Hello {{name}},\ n\nI'm over the moon with your ECG results – a Sinus Rhythm that's beautiful and regular, with PQRST intervals and a heart rate that are exactly where we want them. There are also no ectopic beats, just a perfect recording with nothing to worry about. 💃\n\nAll the best!",

"Hi {{name}} 💖,\ n\nIt's heartening to see your ECG – a Sinus Rhythm that's nice and steady, PQRST intervals that are the gold standard, and a heart rate that's spot-on. Ectopic beats? None in sight.\n\nSumming it up, your recording presents zero abnormalities, and there's nothing for you to worrisome about your ECG 😄\n\nTake care!",

"Hey {{name}},\n\nYour ECG is clear, and there is nothing to worry about – a rhythm that's nice and regular, PQRST intervals, and a heart rate that's nicely within the norm. And no ectopic beats, which is just the cherry on top! 🍒\n\nAll the best!",

"Greetings, {{name}}!\ n\nI'm happy to let you know that your ECG reflects a flawless Sinus Rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM that couldn't be more perfect. In essence, your recording is great with no abnormalities and nothing to worry about 😄\n\nTake care!",

"Hey {{name}},\ n\nIt's a beautiful day to have an ECG like yours! Your recording indicates a Sinus Rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM that couldn't be more ideal.\n\nI really hope that rest of your day will be as good as your results 💖.\n\nAll the best!",

"Hello {{name}},\n\nWonderful news! Your ECG presents a steady sinus rhythm with PQRST intervals and a heart rate of {{heartrate}} BPM, all comfortably within the normal range. To boil it down, your recording is looking great, with no abnormalities detected 😄\n\nHave a fabulous day!",

"Hi {{name}},\n\nJust went over your ECG, and guess what? It's perfect! Sinus rhythm, PQRST intervals, and heart rate — all within the normal range. Additionally, there are no ectopic beats. There is nothing to worry about, {{name}}.\n\nHave a nice day! 😄",

"Hey {{name}} 😄,\n\nYour recent ECG is a reason to celebrate! Sinus rhythm? Check. Normal PQRST intervals and heart rate? Double-check. It's all clear, without a single abnormal beat or a reason to worry.\n\nHave a happy day! 💖",

"Greetings {{name}},\ n\nI'm happy to inform you that your ECG results are as good as it gets. Your recording shows sinus rhythm (this is great news!), PQRST intervals are spot on, and the heart rate is a steady {{heartrate}} BPM. To put it simply, there are zero abnormalities in your recording 😄.\n\nTake care!",

"Hello {{name}},\n\nI have some excellent news — your ECG is as good as it gets! Sinus rhythm is present, PQRST intervals are aligned with the norm, and the heart rate is a solid {{heartrate}} BPM. All reasons to smile! 😄\n\nHave a fabulous day!",

"Hey {{name}},\n\nJust went over your ECG, and it's fantastic – a Sinus Rhythm with PQRST intervals that are the gold standard and a heart rate that's exactly where we want it. Zero ectopic beats as well!\n\nTo boil it down, your recording is looking flawless 💓!",

"Hello {{name}},\n\nDelighted to inform you that your ECG looks amazing – showcasing a Sinus Rhythm that's as steady as they come, with PQRST intervals and a heart rate that is comfortably within the normal range. Plus, the absence of ectopic beats is a sweet bonus! 🌟\n\nTake care!",

"Greetings {{name}},\n\nYour ECG looks very nice – a beautifully regular Sinus Rhythm, spot-on PQRST intervals, and a heart rate that's just right. Not to mention completely free of ectopic beats.\n\nBest wishes 💓!",

"Hey there {{name}},\n\nJust reviewed your ECG, and it looks AMAZING – a Sinus Rhythm that's nice and regular, PQRST intervals, and a heart rate that's the epitome of normal. And zero ectopic beats to speak of - this is what we want to see.\n\nAll the best!",

"Hi {{name}},\n\nYour ECG brought a smile to my face – a Sinus Rhythm that's consistent and stable, PQRST intervals, and heart rate that's perfectly placed within the normal range. And a complete no-show of abnormal beats! It's all looking great, {{name}}! 😊\n\nBest regards!",

"Hello {{name}},\n\nChecking your ECG has been the highlight of my day – a Sinus Rhythm that's predictably regular, PQRST intervals that are flawless, and a heart rate that's neatly within normal limits. And the lack of ectopic beats? Perfect! 🌼\n\nWarmest regards!",

"Hello {{name}} 🌟,\ n\nI'm thrilled to share that your ECG results are perfect! Sinus rhythm, PQRST intervals, and heart rate are all within the normal range. In essence, your recording is great with no abnormalities and nothing to worry about 😄.\n\nHopefully your day is as good as your results 💓!",

"Hello {{name}},\n\nI have some delightful news for you – your heart is keeping a perfect rhythm! The PQRST intervals and heart rate are all normal, without a hint of an ectopic beat. This is what we always hope to see, as there is nothing to worry about.\n\nAll the best! 🌟",

"Hi, {{name}},\ n\nI'm happy to let you know that your ECG is exceptionally good! Its rhythm is nice and regular, with PQRST intervals and heart rate all in the normal range. Abnormal beats? None to be found! You've got ZERO reason to be worried about your recording. 😊\n\nWishing you a day that's as cheerful as this news!",
"Hello {{name}},\n\nI bring you great news! Your heart is keeping a beautifully consistent beat, and the PQRST intervals, along with your heart rate, are all normal. There's not a single ectopic beat disrupting this rhythm, which is what we want to see 😊. To put it simply, there is nothing to worry about your ECG.\n\nAll the best!",
"Hello {{name}},\n\nChecking your ECG has been a great joy – a Sinus Rhythm that's regular and steady, PQRST intervals, and a heart rate that's neatly within normal limits. And the lack of ectopic beats? Perfect! 🌼\n\nWarmest regards!",
"Hello {{name}},\n\nJust finished reviewing your ECG, and it's all positive news. You've got a steady Sinus Rhythm, PQRST intervals are in the normal range, and there are zero ectopic beats to report. All is as it should be!\n\nWishing you well! 🤗",
"Greetings {{name}},\n\nYour ECG results are in, and they're looking great. Your heart's rhythm is nice and steady (this is what we want to see), the PQRST measurements are textbook, and ectopic beats are nowhere to be found. To sum up, there is nothing to worry about.\n\nBest regards! 🤗",
"Hello {{name}},\n\nYour ECG depicts nothing but good news. A flawless Sinus Rhythm, impeccable PQRST intervals, and a complete absence of ectopic beats. To boil it down, your recording is flawless, with no abnormalities detected and nothing to stress about 🤗.\n\nWishing you all the best!",
"Hey {{name}},\n\nToday, I bring you only great news! Your heart is maintaining a beautifully consistent rhythm, and the PQRST intervals, along with your heart rate, are all normal. There's not a single extra beat disrupting your rhythm, which definitely is what we love to see.\n\nWarmest regards. 🌼"
    ];
  })();


  export default sinus_rhythm;