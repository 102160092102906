import { createSlice } from '@reduxjs/toolkit'
import * as endpoints from '../../common/endpoints'
import API from '../../common/api'

export const userCvdSurveySlice = createSlice({
  name: 'userCvdSurvey',
  initialState: {
    userCvd: {},
  },
  reducers: {
    setUserCvd: (state, action) => {
      // console.log(Object.entries(action.payload))
      state.userCvd = { ...action.payload }
      console.log(state.userCvd)
    },
    clearUserCvd: (state) => {
      state.userCvd = {}
    },
  },
})

export const { setUserCvd, clearUserCvd } = userCvdSurveySlice.actions

export const currentUserCvdSurvey = (state) => state.userCvdSurvey.userCvd

export const getUserCvdSurvey = async (id) => {
  const store = await import('../store')
  return new Promise((resolve, reject) => {
    store.default.dispatch(setUserCvd({}))

    API.get(endpoints.GET_USER_CVD(id))
    .then((data) => {  
      if (data.data.data != null && data.data.data != "null" && data.data.data != "undefined") {
        data = data.data.data.cvd      
        store.default.dispatch(setUserCvd(data))
      }      

      resolve(data)
    })
    .catch((e) => {
      console.error(e)
      reject(e)
    })

  })
}

export default userCvdSurveySlice.reducer
