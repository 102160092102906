
import API from '../common/api'
const endpoints = require('../common/endpoints')

export const getadminTasksService = async (page, rows, search)=>{
    const res = await API.get(endpoints.ADMIN_TASKS, { params: search? {page, limit: rows, search }:{page, limit: rows,sort:"id" } })
    return {tasks: res.data.data, meta: res.data.meta}
}

export const changeEcgPriority = async (id, priority) =>{
    const res = await API.post(endpoints.ECG_PRIORITY(id), {
        priority
    })
    return res.data.data
    
}

export const forceEcgComplete = async (id) =>{
    const res = await API.post(endpoints.ECG_FORCE_COMPLETE(id), {})
    return res.data.data
    
}

export const listTechniciansService = async(page, rows, search, searchBy)=>{
    const res = await API.get(endpoints.LIST_TECHNICIANS,{ params: search? {page, limit: rows, search_by:searchBy, search } :{page, limit: rows } })
    return {technicians: res.data.data, meta: res.data.meta }
}

export const listTechniciansTasksService = async()=>{
    const res = await API.get(endpoints.LIST_TECHNICIANS,{ params: {paginate: false}})
    return {technicians: res.data.data, meta: res.data.meta }
}


export const getTechnician = async(id)=>{
    const res = await API.get(endpoints.GET_TECHNICIAN(id))
    return res.data.data
}

export const saveTechnician = async(data, id)=>{
    const res = await API.put(endpoints.SAVE_TECHNICIAN(id), data)
    return res.data.data
}

export const addTechnician = async(data)=>{
    const res = await API.post(endpoints.ADD_TECHNICIAN, data)
    return res.data.data
}

