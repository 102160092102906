
import React, {createContext, useEffect, useState} from 'react'
import { useContext } from 'react'
import { changeEcgPriority, forceEcgComplete, getadminTasksService, listTechniciansService, listTechniciansTasksService } from '../services/admin'
import { AuthContext } from './Auth.context'


export const AdminContext = createContext(null)


const AdminProvider = ({children}) => {
    const [tasks, setTasks] = useState([])
    const [technicianData, setTechnicianData] = useState([])
    const [technicianTasksData, setTechnicianTasksData] = useState([])
    const [tasksData, setTasksData] = useState([])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [forceComppleteSuccess, setForceCompleteSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    // const [filteredTechnicians, setFilteredTechnicians] = useState(null)
    const {logout} = useContext(AuthContext)

    const getTasks = (page, rows, search) => {
        setLoading(true)
        getadminTasksService(page, rows, search).then(setTasksData).catch(error => console.log(error)).finally(() => setLoading(false))
    }

    const setPriority = (id, priority) =>{
      changeEcgPriority(id, priority).then(() => setSuccess(true)).catch(() => setFailed(true))
    }

    const forceComplete = (id) => {
        forceEcgComplete(id).then(() => setForceCompleteSuccess(true)).catch(() => setFailed(true))
    }

   const getTechnicians = (page, rows, search, searchBy) => {
    listTechniciansService(page, rows, search, searchBy).then(setTechnicianData).catch(error => console.log(error))
   }

   const getTechniciansTasks = () => {
    listTechniciansTasksService().then(setTechnicianTasksData).catch(error => console.log(error))
   }


    useEffect(() => {
       if(success || failed){
           setTimeout(() => {
               setFailed(false)
               setSuccess(false)
           }, 2000);
       }
    }, [success, failed])
    return (
        <AdminContext.Provider value={{technicianTasksData,getTechniciansTasks, technicianData, tasksData,getTechnicians,  getTasks, success, failed, forceComppleteSuccess, tasks, loading, setPriority, forceComplete}}>
            {children}
        </AdminContext.Provider>
    )
}

export default AdminProvider
