const sinus_tachycardia_1 = (function () {
    return [

"Hi {{name}},\n\nUpon reviewing your ECG, I'm happy to say it’s looking good 😊. The PQRST values fall nicely within the normal range, and there are no abnormal beats, which is fantastic news. Your heart rate is a bit elevated, but this is often a normal reaction to exercise, stress, anxiety, caffeine, or other stimulants.\n\nStay positive and take care! 💖",

"Hello {{name}},\n\nExamining your ECG, I can happily report that it looks great 😃. Your PQRST values are well within the normal limits, and there are no irregular beats, which is fantastic news 🎉. Your heart rate is a little higher than usual, but that's often a typical response to working out, stress, anxiety, or even coffee.\n\nTake care! :)",

"Hey {{name}},\n\nAfter looking at your ECG, I'm pleased to tell you that everything seems fine 😄. Your PQRST values are comfortably within normal boundaries, and there are no abnormal beats, which is lovely news. Your heart rate is slightly increased, but this is often a natural reaction to working out or everyday stress (hopefully, this is not the case).\n\nStay bright and look after yourself! 🤗",

"Hi {{name}},\n\nHaving analyzed your ECG, I'm thrilled to report that everything looks pretty standard. Your PQRST values are well within the acceptable range, and there are no abnormal beats. Your heart rate is a tad higher (above 100 BPM). Usually, that’s simply the result of physical exercise, stressful events, anxiety spells, or caffeine use\n\nStay bright and look after yourself! 🌟",

"Hello {{name}},\n\nI've reviewed your ECG, and I'm happy to say that everything appears to be fine. The PQRST values are all within normal limits, and there are no irregular beats. Your heart rate is a bit elevated at {{heartrate}} - such a reaction is typically just an outcome of performing physical exercises, facing stressful conditions, feeling anxious, drinking caffeine, or using other forms of stimulants like alcohol.\n\nSending you lots of warm thoughts! 😄",

"Hey {{name}},\n\nAfter examining your ECG, I'm pleased to inform you that everything seems to be okay. Your PQRST values are nicely within the normal range, and there are no abnormal beats, which is incredible news 😁. Your heart rate is slightly raised, that’s often merely the body’s natural response to workouts, stressful experiences, or stimulation like caffeine. \n\nTake care and stay safe! 💖",

"Hi {{name}},\n\nUpon checking your ECG, I'm delighted to announce that everything looks alright 😊. The PQRST values are safely within the normal limits, and there are no extra beats as well. Your heart rate is a bit higher than normal, but this is often a typical result of exercise, stress, anxiety, caffeine, or other stimulants like alcohol.\n\nSending you all of my best wishes 💕",

"Hello {{name}},\n\nI've looked at your ECG, and I'm glad to tell you that everything seems to be okay 🎉. Your PQRST values are well within normal parameters, and there are no abnormal beats, which is excellent news 😄. Your heart rate is a little elevated, but this is often a natural outcome of stress, anxiety, or one too many cups of coffee.\n\nI hope this helps! Always take care 💖",

"Hey {{name}},\n\nReviewing your ECG, I can happily confirm that everything appears normal 😃. Your PQRST values are well within the standard range, and there are no irregular beats. Your heart rate is slightly raised, but this is often a typical response to working out, stress, anxiety, caffeine, or other stimulants.\n\nStay bright and look after yourself!",

"Hi {{name}},\n\nHaving gone through your ECG, I'm excited to say that everything looks fine. Your PQRST values are within the normal boundaries, and there are no abnormal beats, which is fantastic news 😆. Your heart rate is a bit high (I would love to see it below 100 BPM), but this is often a standard reaction to everyday things like exercise, stress, anxiety, or caffeine.\n\nTake care and stay safe! 💖",

"Hello {{name}},\n\nI've assessed your ECG, and I'm glad to report that it appears to be looking good. The PQRST values are all within normal limits, and there are no abnormal beats. Your heart rate is a little elevated, but this is often a standard effect of exercise, stress, anxiety, caffeine, or other stimulants like alcohol.\n\nSending you lots of warm thoughts! 😄",

"Hey {{name}},\n\nI took a look at your ECG, and it shows that you have Sinus Tachycardia, which simply means your heart rate is a bit faster than usual. Your PQRST values are normal, and there are no abnormal beats, which is a good sign. Sinus Tachycardia can be caused by many things like exercise or emotions, so it's pretty common and usually not something you should be concerned about.\n\nTake care! 💖",

"Hi {{name}},\n\nI've checked your ECG and found that you have Sinus Tachycardia, which means your heart is beating a bit faster than usual. No worries, though – your PQRST values are normal, and there aren't any ectopic beats. Sinus Tachycardia can happen for various reasons, like exercise or even excitement, and it's not something you should be concerned about too much.\n\nI hope this helps! Always take care 💖",

"Hello {{name}},\n\nThe delightful news is that your ECG looks quite good. There aren't any abnormal beats, though your heart rate is higher than what's typical (above 100 BPM). This is usually a normal situation and can be caused by everyday occurrences like working out, feeling stressed, or having a cup of coffee.\n\nTake care! 💕",

"Hey {{name}},\n\nThe fantastic news is that your ECG seems to be nice and regular. I didn't find any unusual beats, but your heart rate is a little higher than expected (higher than 100 BPM). Please, don't worry about it, this is usually a normal finding and might be linked to daily events such as exercising, stress, or caffeine consumption.\n\nTake care and stay safe! 💕",

"Hi {{name}},\n\nThe reassuring news is that your rhythm looks pretty fantastic. There are no abnormal beats, but your heart rate is slightly raised (as it's above 100 BPM). Don't let it bother you too much! This is usually a perfectly normal finding and can come from everyday things like working out, dealing with stress, or enjoying coffee a bit too much.\n\nTake care and stay safe! 💖",

"Hello {{name}},\n\nYour rhythm is looking fantastic, which is great news. There aren't any abnormal beats, but your heart rate is slightly higher than expected (this is what Tachycardia means). This is a very common finding and might be caused by things like physical activity, stress, or consuming coffee.\n\nI hope this helps! Always take care 💖",

"Hey {{name}},\n\nAfter examining your results, it seems that your heart rate is over 100 BPM, yet your rhythm is nice and normal – that's sinus tachycardia. Though it might feel scary, sinus tachycardia is a common finding, so please don't worry too much about it 😊. Factors like stress, anxiety, medications, or even an unhealthy diet can cause it. Rest assured, all other measurements are within normal limits, and no abnormal beats were detected.\n\nSending you all of my best wishes 👍",

"Hi {{name}},\n\nAfter assessing your ECG, I found that your rhythm is normal and regular, but your heart rate is a bit high (as it's above 100 BPM). This is considered a normal finding, so please don't worry about it. Causes can include stress, anxiety, medications, or one too many cups of coffee. All your other measurements are excellent, and there were no abnormal beats at all.\n\nTake care! 💓",

"Hello {{name}},\n\nAfter reviewing your recording, I noticed that your heart rate is over 100 BPM (it's a bit above the norm), with a nice and regular rhythm. It can feel frightening, but it's a regular finding, so please don't be too concerned about it 😌. Factors like stress, anxiety, medications, or even a bad diet can trigger it. Your PQRST values are well within normal limits, and no abnormal beats were noted.\n\nSending you lots of warm thoughts! 😄",

"Hey {{name}},\n\nUpon checking your results, your heart rate appears to be above 100 BPM (it's higher than the norm), while your rhythm is still nice and normal – this is what we call sinus tachycardia. It might feel alarming, but it's a standard finding, and it's not something you should be worried about 😊. Causes may involve coffee, stress, anxiety, medications, or an unhealthy diet. All other measurements are absolutely fine, and no abnormal beats were found.\n\nTake care! 💕",

"Hi {{name}},\n\nYour rhythm is looking nice and clean, but your heart rate is a bit high (above 100 BPM). It might feel daunting, but it's a common finding, so please try not to worry too much 😊. Causes can include everyday factors like stress, anxiety, medications, or coffee. Rest assured, all other measurements are within normal limits, and no abnormal beats were detected.\n\nI hope this helps! 😊",

"Hello {{name}},\n\nI've got some comforting news for you! 😊 Your ECG review showed that you have sinus tachycardia, which means your heart rate is a bit faster than usual, but your rhythm is still normal. Sinus tachycardia is very common and can be caused by factors like stress, caffeine, or exercise. No need to worry, as there were no ectopic beats detected. So, overall, everything looks quite good!\n\nTake care! 💖",

"Hey {{name}},\n\n Your ECG review revealed that you have sinus tachycardia, which means your heart rate is a tad faster than usual (above 100 BPM), but your rhythm is perfectly normal. Sinus tachycardia is quite common and can be caused by everyday factors like stress, caffeine, or exercise. There's no need to worry, as there were no ectopic beats detected. So, rest easy knowing that everything looks lovely!\n\nI hope this helps! 😊",

"Hi {{name}}, \n\nYour ECG results show a normal rhythm with a heart rate above 100 BPM, which is called sinus tachycardia. It might sound a bit scary, but it's actually quite common and not something to be overly worried about. This can be caused by everyday factors like stress, anxiety, or even caffeine. All other aspects of your ECG are excellent.\n\nI hope this helps! Always take care 😊",

"Hello {{name}},\n\nUpon reviewing your ECG, I see that your heart rate is a bit higher than normal, which is called sinus tachycardia. This is a regular rhythm, just at a faster pace (above 100 BPM). Sinus tachycardia can happen for various reasons like exercising, feeling stressed (hopefully, it's not the case), or even having a cup of coffee ☕. Your PQRST is normal, and there are no ectopic beats detected!\n\nStay positive and take care 💖.",

"Hey {{name}},\n\nYour ECG results show a regular rhythm with a heart rate above 100 BPM, which is known as sinus tachycardia. Although it might sound a bit intimidating, it's actually a common finding and nothing to be too concerned about 😊. Sinus tachycardia can be caused by everyday factors like stress, anxiety, or even caffeine intake. All other measurements in your ECG are within normal limits, and there are no abnormal beats noted.\n\nTake care! 💕",

"Hi {{name}},\n\nIt's great to share that your recording shows a normal rhythm called sinus tachycardia, with your heart rate being above 100 BPM. Although it might feel a bit scary, this is actually a common finding, and there's no need to worry too much 😊. Various everyday factors like stress, anxiety, or certain medications can cause it.\n\nStay positive and take care 💖.",

"Hey {{name}}! 🌞\n\nI just took a careful look at your ECG, and it seems you have Sinus Tachycardia. This means your heart is beating faster than usual (above 100 BPM), but your rhythm is still nice and normal. Sinus Tachycardia can be a natural response to exercise, stress, or even excitement.\n\nTake care! 💕",

"Hello {{name}},\n\nI've just had a look at your ECG, and it shows Sinus Tachycardia. In simpler terms, your heart is beating a bit faster than usual (while rhyme is still normal), which can happen for various reasons like exercise, excitement, or even a strong cup of coffee. 💓 All other measurements are well within the norm, and I also didn't notice any extra beats - which is great news.\n\nTake care, and continue to look after your heart! 😃",

"Hi {{name}},\n\nI just reviewed your ECG recording and noticed that you have Sinus Tachycardia. In simple terms, this means your heart is beating faster than usual, but it's still maintaining a regular rhythm.\n\nSinus Tachycardia often occurs in response to factors like exercise, excitement, or stress. So, it's not uncommon for our hearts to speed up sometimes! 🏃‍♀️\n\nTake care!",

"Hey {{name}},\n\nI just reviewed your ECG, and it looks like you have Sinus Tachycardia. In simpler terms, it means your heart is beating a bit faster than usual, but no worries! Your PQRST intervals are well within the norm, and there aren't any ectopic beats present as well. 🙌\n\nSinus Tachycardia is often caused by factors like exercise, excitement, or stress. It's pretty common and generally harmless.\n\nTake care and stay safe! 💖",

"Hello {{name}},\n\nI'm here to share the results of your ECG review. Your heart rhythm showed Sinus Tachycardia, which means your heartbeat is a bit faster than usual, but it's still nice and regular.\n\nThe good news is that PQRST measurements are normal, and there were no extra, out-of-place beats ❤️.\n\nAll the best!",

"Hi {{name}},\n\nThe ECG results show you're experiencing sinus tachycardia - that's just a fancy way of saying your heart rhythm is normal, but your heart rate is a bit faster (above 100 BPM). This can be due to common factors like exercise, stress, feeling anxious, or even having caffeine.\n\nTake care! 💕",

"Hey {{name}},\n\nYour ECG displays sinus tachycardia, which is a fancy term for a normal heart rhythm accompanied by an increased heart rate. Everyday causes like physical activity, stress, feeling anxious, a light cold, or caffeine can lead to this. Generally, it's not something to be concerned about, {{name}}}.\n\nTake care! ❤️",

"Hello {{name}},\n\nThe ECG shows you have sinus tachycardia, which is another way of saying your heart rhythm is excellent, but your heart rate is slightly elevated (a bit above 100 BPM). This can happen due to typical daily experiences such as exercise, stress, anxiety, diet, or even caffeine. In most cases, it's not a reason for concern at all.\n\nTake care! 💖",

"Hi {{name}},\n\nSinus tachycardia is present in your ECG, which simply indicates a normal heart rhythm with a faster heart rate (above 100 BPM). It's commonly caused by everyday activities like working out, dealing with stress, anxiety, or having some caffeine. Usually, there's no need to be worried.\n\nI hope this helps! Take care 💖",

"Hey {{name}},\n\nAs I examine your ECG, I can see that your heart rate is slightly elevated. Please don't be alarmed, though, as this is a common occurrence that can be triggered by various factors such as drinking a lot of coffee, doing intense exercise, or experiencing stress. Luckily, all PQRST measurements are standard, and there are no abnormal beats detected.\n\nTake care, and continue to look after your heart! 😃",

"Hello {{name}},\n\nAfter reviewing your ECG, I noticed that your heart rate is a little high. Please don't worry, as this is a common occurrence that can be caused by many things, such as drinking too much coffee, exercising, or even feeling worried 😔. Fortunately, all PQRST values are in the normal range, and there are no irregular beats in your recording.\n\nHave a great day. 🤗",

"Hi {{name}},\n\nIt appears that your heart rate is slightly elevated based on your ECG results. Please don't be too concerned, though, as this is a frequent occurrence that can be triggered by many things, such as consuming too much coffee, engaging in physical activity, or feeling stressed 😥. Thankfully, all other measurements are within the standard range, and there are no unusual heart rhythms observed.\n\nI hope this helps! Always take care 💖", 

"Greetings {{name}},\n\nYour ECG presents with Sinus Tachycardia, which, while indicating a faster than normal heart rate, is often a normal response to various conditions like exercise or excitement. The PQRST intervals are well within the norm, and there are no unusual beats in your ECG - which is great news!. \n\nI hope this helps! Take care 💖",

"Hello {{name}},\n\nYour ECG shows a Sinus Tachycardia rhythm. This means your heart rate is a bit higher than usual, at {{heartrate}} BPM, but your rhythm is nice and regular (which is excellent news). Your PQRST intervals fall nicely within the normal range, and there are no abnormal beats in your recording.\n\nTake care and stay safe! 😃",

"Hello {{name}} 😃,\n\nYour ECG indicates a rhythm of Sinus Tachycardia, which means your heart is beating faster than the average resting rate (above 100 BPM). Despite the quickened pace, your PQRST intervals and heart rate are consistent and within the expected parameters, showing no signs of ectopic beats as well. Sinus Tachycardia can be caused by many benign things like coffee, working out, or even stress. It is usually not a cause for concern.\n\nAlways take care 💖", 

"Hi {{name}},\n\nYour ECG demonstrates a Sinus Tachycardia rhythm. While this suggests a heart rate that's on the faster side (while your rhythm is still regular and normal), it's not uncommon and can occur in many everyday scenarios (like stress or too much coffee). Your PQRST intervals are regular, and there are no abnormal beats. Overall, your ECG is pretty nice, and increased heart rate at {{heartrate}} BPM is not usually something to stress about 😃. \n\nTake care and stay safe 💕",

"Greetings {{name}},\n\nI'm happy to report that your ECG indicates the presence of Sinus Tachycardia. Although your heart rate is faster than the typical range ({{heart rate}} BPM), the PQRST complexes are well-defined and regular, showing no signs of extra beats. Sinus Tachycardia can be a normal response to various harmless factors, such as increased activity or emotional stress.\n\nThere is usually nothing alarming about these findings. 🌟 Take care!",

"Hello {{name}},\n\nYour ECG illustrates a rhythm called Sinus Tachycardia, which is characterized by a heart rate that's quicker than usual (above 100 BPM) with a rhythm that is normal and regular. But don't worry, your PQRST intervals are looking normal, and there's no evidence of any abnormal beats. It's a common occurrence and generally not a cause for concern unless accompanied by other symptoms.\n\nWishing you a great day! 😊",

"Hi {{name}},\n\Your ECG shows Sinus Tachycardia. This means that your heart is beating a bit rapidly, but the rhythm is steady, and your PQRST intervals are consistently normal. This can happen due to many day-to-day factors and is not necessarily indicative of an underlying issue. \n\nSending you a lot of warm thoughts! 💓",

"Hey {{name}},\n\nYour ECG results are in, and they display a Sinus Tachycardia rhythm. Though your heart rate is elevated, the PQRST intervals remain normal, and there are no extra beats in your recording - which is fantastic news. Such a pattern is often seen in response to everyday physiological demands or even mild stressors.\n\nTake care! 🌟",

"Greetings {{name}},\n\nYour ECG reading indicates Sinus Tachycardia. Although the heart rate is higher than we would like it, the rhythm is regular and nice. Additionally, your PQRST intervals are normal as well. This rhythm is commonly seen in many individuals and is still considered normal, especially if you are anxious or have caffeine. There's nothing unusual in the overall pattern of your heart rhythm.\n\nBest regards! 🌷",

"Hello {{name}} 💓,\n\nUpon reviewing your ECG, I observed a Sinus Tachycardia rhythm. Your heart rate is elevated, yet the PQRST intervals are consistently normal. This is often a typical physiological response to various stimuli or situations like exercise or stress. The absence of any abnormal beats is a positive sign as well.\n\nWishing you a great day! 😊",

"Hi {{name}},\n\nYour ECG has shown a Sinus Tachycardia pattern. Though this means your heart rate is currently a bit high, the overall rhythm looks excellent and regular. The PQRST intervals fall nicely within the standard range. This can sometimes happen in response to things like caffeine intake, excitement, or exercise. There are no abnormalities that would suggest a problem with your recording.\n\nAll the best! 🌺",

"Greetings {{name}},\n\nYour ECG results display Sinus Tachycardia. While this implies a quicker heartbeat, your PQRST intervals and the regularity of your rhythm are perfectly normal. Often, this is simply the heart's natural response to some daily activities or emotions and is typically not worrisome.\n\nTake care, and continue to look after your heart! 😃",

"Hello {{name}}, I have pretty good news regarding your ECG—it presents a Sinus Tachycardia rhythm. Although the heart rate is increased, your rhythm is excellent and regular. The PQRST intervals remain normal, which is reassuring. This is a very common finding and can be caused by a variety of everyday factors (even a cup of coffee can cause it). There are no abnormal heartbeats in your recording.\n\nTake care! 💖",

"Good day {{name}} 😃,\n\nJust to update you on your ECG analysis: I've noted a Sinus Tachycardia rhythm. While your heart rate is above average, your underlying rhythm is looking great and regular. The PQRST intervals are perfectly normal. Such a finding is usually no cause for alarm and can result from a variety of everyday activities.\n\nAll the best 🌟",

"Hi {{name}},\n\nYour ECG has come back showing Sinus Tachycardia. This simply means your heart is beating a bit faster than usual (we would like to see it between 60 and 100 BPM). The underlying rhythm is very much regular, and it’s looking pretty nice! The PQRST intervals are just as they should be. This can often be a normal response to several benign factors, so there's no immediate concern at all. 😊\n\nI hope this helps! Always take care 💖",

"Hello {{name}} 💖,\n\nI've examined your ECG, and it shows Sinus Tachycardia. Though your heart rate is on the higher side, the rhythm is regular, and the PQRST intervals fall nicely within the norm. This is typically not something you should worry about and can be caused by something as simple as a brisk walk or a cup of coffee.\n\nTake care, and continue to look after your heart! 😃",

"Hi {{name}}, I'm pleased to inform you that your ECG results, which indicate Sinus Tachycardia (an increased heart rate), also show a strong and regular rhythm with normal PQRST intervals. This condition is not uncommon and is not a cause for concern—it can result from a variety of everyday activities such as brisk walking or stimulants like coffee.\n\nAll the best 🌟",

"Hey {{name}}, Your ECG has come back showing Sinus Tachycardia. This simply means your heart is beating a bit faster than usual. However, your underlying rhythm is looking great and regular (no issues in sight! This can often be a normal response to several benign factors, and usually, it’s not something you should be concerned 😊\n\nTake care 💖",

"Hello {{name}},\n\nI've examined your ECG, and it shows Sinus Tachycardia. Though your heart rate is on the higher side, the rhythm is regular, and your PQRST intervals are standard (which is a great thing). Sinus Tachycardia is typically not something you should worry about and can be caused by something as simple as a brisk walk or a cup of coffee.\n\nAll the best 💓",

"Greetings {{name}},\n\nYour ECG captured a Sinus Tachycardia rhythm, which means your heart is beating faster than what's typically expected. Despite the increased rate, your heart's rhythm and PQRST intervals are looking great. This is often seen even with active and healthy individuals and is generally not a concern.\n\nTake care 💖", 

"Greetings {{name}},\n\nAfter reviewing your ECG, I can confirm that you have Sinus Tachycardia. This means your heart rate is elevated, yet your rhythm is perfectly regular. Such a pattern can stem from daily activities or emotional states such as excitement or mild stress. It's super common, and usually, it’s nothing you should worry about. 🌟\n\nBest regards!",

"Hello {{name}},\n\nI've taken a carfeull look at your ECG, and it shows Sinus Tachycardia, which is a faster heart rate but with a regular rhythm. It's usually nothing alarming and can be caused by things like a brisk walk, a bit of anxiety, or a cup of strong coffee.\n\nAll the best! 💓",

"Hi {{name}},\n\nYour ECG indicates you have Sinus Tachycardia. Don't be concerned—it just means your heart's beating a bit quicker, maintaining a steady and strong rhythm. Additionally, your PQRST intervals are normal as well. This can happen with simple things like a good workout or feeling a bit rushed. It's a pretty standard finding and usually not a cause for concern 😊.\n\nAll the best!", 

"Greetings {{name}} 😊,\n\nYour recent ECG suggests Sinus Tachycardia. This simply reflects a heart rate that's higher than average, yet your heart's rhythm remains steady and regular. I didn’t find any abnormal beats - which is excellent news. It's often a natural response to various everyday situations, such as activity or stress. n\nTake care! 💓",

"Hey {{name}},\n\nThe results of your ECG are in, and they show Sinus Tachycardia. This is just a fancy way of saying your heart is a bit speedy but still beating in a healthy, regular pattern. It’s a typical response to life's hustle and bustle. Usually, it’s not something you should worry about 💛\n\nBest wishes!",

"Greetings {{name}},\n\nYour ECG review shows Sinus Tachycardia. This means that your rhythm is normal and regular, but your heart rate is a bit elevated (we would love to see it below 100 BPM). Your PQRST intervals fall nicely within the norm, and you don’t have any abnormal beats. This is often just a natural response to everyday activities and is generally not a cause for concern 🌟.\n\nWarm regards!", 

"Hello {{name}},\n\nUpon examining your ECG, it's clear you're experiencing Sinus Tachycardia. While your heart rate is slightly elevated, your rhythm is regular, and your PQRST intervals are normal, which is reassuring. No alarming signs here, it’s usually expected respond to life's little rushes. 💓\n\nStay well and take care!", 

"Hi {{name}},\n\nYour ECG indicates Sinus Tachycardia, which means a quicker heart rate but with regular rhythm and standard PQRST intervals. It's often just a reaction to physical activity or emotional stress—quite a standard finding. 🏃\n\nAll the best to you!",

"Hey {{name}} 😊,\n\nThe ECG points to Sinus Tachycardia. That means that your average heart rate is above 100 BPM, however, the rhythm is steady and regular. The PQRST intervals are well within the expected range. It's a typical physiological response to everyday things like exercise, stress, or even coffee. 🌷\n\nTake care and be well!", 

"Hello {{name}},\n\nYour ECG results indicate Sinus Tachycardia, meaning your heart rate is on the quick side but keeping a nice and regular pattern. Your PQRST intervals are in the expected range. It's typically just how the heart reacts to daily activities and stressors.  💛\n\nBest wishes!",

"Hello {{name}},\n\nYour ECG presents Sinus Tachycardia, which is a fancy term for a faster-than-usual heartbeat (while your rhythm is still nice and regular). Your PQRST intervals are perfectly normal. This is typically a benign response to daily stimuli like exercise or caffeine intake.\n\nAll the best!", 

"Hi {{name}},\n\nJust finished reviewing your ECG, and it's showing Sinus Tachycardia. While your heart is a bit more spirited in its pace, the rhythm is regular, and the PQRST intervals are just right. It's a common scenario that can arise from life's hustle and bustle or even a brisk walk. It’s usually not something to worry about! \n\nWishing you a good day!",

"Greetings {{name}},\n\nUpon examining your ECG, I've noticed that your rhythm is Sinus Tachycardia. This means your heart is beating faster than 100 BPM, yet it's keeping a steady and healthy rhythm, and your PQRST intervals are spot on. Often, this is just your heart's natural response to everyday activities or emotions. There's usually no cause for alarm. 🌼\n\nBest wishes and take care!",

"Hey {{name}},\n\nYour ECG indicates you have Sinus Tachycardia. While this may sound concerning, rest assured, it simply means your heart rate is a little quicker than average, but importantly, your rhythm is still great and regular. Your PQRST intervals remain normal. Such a rhythm can be influenced by various day-to-day activities or mild stressors. 😊.\n\nStay well and all the best!", 

"Hello {{name}} 😊,\n\nI've carefully reviewed your ECG, and it shows you have Sinus Tachycardia. Your heart rate is a bit high (above 100 BPM), yet it's still keeping a regular rhythm with PQRST intervals that are textbook perfect. Sinus Tachycardia is often just a sign that your heart is responding to your active lifestyle or even just a busy day. 💓.\n\nWarm regards!",

"Hi {{name}},\n\nGood news from your recent ECG: It reveals that your heart is in Sinus Tachycardia, meaning it's beating faster than usual but with a rhythm that's as regular as clockwork. The PQRST intervals are just right. This isn't unusual and is often just your heart's natural response to everyday activities. 🌼\n\nBest wishes!",

"Hi {{name}},\n\nI'm pleased to inform you that your ECG indicates Sinus Tachycardia, which is a fancy term for a quicker heartbeat accompanied by a wonderfully regular rhythm. This is a typical response to various normal activities and is not usually inherently concerning. 🌟\n\nTake care and all the best!",

"Hello {{name}},\n\nI've examined your ECG, and it shows Sinus Tachycardia, which means your heart rate is a bit higher than average. But don't worry - the rhythm is steady, and the PQRST intervals are spot-on, which is a great sign. It's often just a response to everyday activities or emotions (hopefully, this is not the case) 🌿. \n\nWishing you well!", 

"Greetings {{name}},\n\nYour ECG results are in, and they indicate Sinus Tachycardia. It means that your heart rate is above 100 BPM, but your underlying is excellent and regular. Your PQRST intervals all look nice and normal as well. This can happen for various reasons like activity, stress, or even excitement. It’s usually not a concern 😊.\n\nStay healthy and take care!",

"Hi {{name}},\n\nJust finished reviewing your ECG, and it shows a pattern of Sinus Tachycardia. Your heart is ticking a bit faster but in a perfectly regular rhythm. All of your PQRST intervals that are textbook normal. It’s a typical response to daily activities or even a bit of stress. Nothing alarming at all! Keep taking good care of yourself, {{name}} 😊.n\nBest regards!", 


"Hello {{name}},\n\nYour ECG has been analyzed, and it presents Sinus Tachycardia. Essentially, your heart rate is elevated yet marvelously consistent. Your PR, QTc, and QRS are standard. Such a pattern is often seen with increased daily activity or emotional moments. It’s pretty normal and typically no cause for concern. 💗\n\nBe well and stay active!", 

"Hi {{name}},\n\nI've gone over your ECG, and it reveals Sinus Tachycardia. Your heart's rhythm is a little quicker than usual, yet it's wonderfully rhythmic with PQRST intervals that are just as they should be. This is often just a natural response to life's little hustles and bustles. So, there's no need to worry. Just keep an eye on your well-being. 🌼\n\nAll the best to you!", 

"Hello {{name}},\n\nYour ECG shows Sinus Tachycardia, which signifies a higher heart rate with a regular and robust rhythm. The PQRST intervals are within the expected range, which is reassuring. It's a generally normal finding that can be influenced by various everyday factors (like stress or even coffee). Take it easy, and remember to relax. 💛\n\nTake care!",

"Greetings {{name}},\n\nUpon reviewing your ECG, it's clear that your heart is exhibiting Sinus Tachycardia. While your heart rate is above average, the rhythm remains impeccably steady, and your PQRST intervals are spot-on. This is often just a natural bodily response to activities or emotions. No concerns on the horizon 😊. \n\nWarm regards!",

"Hi {{name}},\n\nYour ECG analysis shows Sinus Tachycardia. Though your heart is beating faster, it's in a perfect rhythm with ideal PQRST intervals, which is excellent. This can happen for various everyday reasons, like a brisk walk or a bit of excitement. There's nothing here that suggests anything out of the ordinary 💛. \n\nStay well and best wishes!",

"Hi {{name}},\n\nYour ECG paints a picture of Sinus Tachycardia. This simply means that your heart's tempo is a bit high but with a rhythm that's as regular as clockwork. All of your PQRST intervals that are just as they should be. It's typically a normal variation, often just the heart's way of responding to life's little hustles. Nothing to worry about! 💙\n\nBest wishes!",

"Good day {{name}},\n\nYour ECG indicates a case of Sinus Tachycardia. This means your heart rate is a touch too high, yet it beats with a precision that reflects a textbook rhythm and spot-on PQRST measurements. This is quite typical, often just a heartbeat's response to everyday activities or emotions.\n\nWarm regards 😊",

"Hello {{name}},\n\nI've reviewed your ECG, and it reveals that you're experiencing Sinus Tachycardia. Though your heart rate is elevated, your rhythm is steady and regular. Your PQRST intervals are ideally within the expected ranges. This rhythm is nothing unusual and is often just a reaction to exercises or coffee.\n\nBest 🌼",

"Dear {{name}},\n\nPretty good news from your latest ECG – it indicates Sinus Tachycardia, which simply means your heart is beating a bit quicker than usual, but your underlying rhythm is nice and regular. But don't worry, everything else, including your PQRST intervals, is absolutely normal. Sinus Tachycardia is a typical response to everyday activities and isn't typically a cause for concern.\n\nWarm regards 🌟", 

"Hello {{name}},\n\nI'm pleased to let you know that your ECG has shown Sinus Tachycardia. While your heart rate is elevated, the rhythm remains regular. All your PQRST intervals are within the expected range, which is reassuring. Sinus Tachycardia can often be a normal reaction to various activities or emotions, so there's generally no need for alarm.\n\nBest wishes! ✨", 

"Hello {{name}}, Just finished looking over your ECG, and it shows a pattern called Sinus Tachycardia. This means your heart rate is a bit higher than average, but it's still beating in a healthy, regular pattern. The PQRST intervals are spot on, which is excellent. Sinus Tachycardia is quite common and can happen for many everyday reasons, like exercise or a bit of stress. So, nothing to stress about!\n\nAll the best! 😄", 

"Hi {{name}} 😊,\n\nI've reviewed your ECG, and it reveals Sinus Tachycardia. Your heart's ticking a tad fast but in perfect rhythm with correct PQRST values. It's a normal response to several everyday situations, such as increased activity or emotional events. It doesn't raise any red flags.\n\nTake care! 💙", 

"Hi {{name}},\n\nYour ECG shows typical PQRST values without irregular beats, which is good. Your heart rate is elevated. It's not uncommon, yet it's worth monitoring. Consider easing up on stimulants and stress (I know it can be tricky) where possible, and keep us posted on any changes, {{name}}. \n\nTake care! 😊",

"Hi {{name}},\n\nYour ECG has come back with everything looking pretty well - PQRST values are normal and no irregular beats. I do see your heart rate is a touch high, though. While often it's nothing to worry about, let's keep a watchful eye. Remember to take a little time for yourself to unwind, {{name}}.\n\nWarmly! ✨",

"Hey {{name}} 👋,\n\nYour ECG has been analyzed, and the core aspects look fine – PQRST values are within expected limits, with no unusual beats detected. Your rhythm is nice and regular (this is what Sinus means). I did notice your heart rate is elevated. This isn't uncommon and can be influenced by several factors like working out or stress.\n\nAll the best! :)",

"Hey {{name}},\n\nYour ECG indicates Sinus Tachycardia. Sure, your heart rate is a bit high, but the rhythm? It's regular, and those PQRST intervals are looking pristine. It's a pretty standard finding, and usually, it’s not anything you should worry about, {{name}} 😊.\n\nWarmly!",

"Hi {{name}},\n\nYour ECG shows Sinus Tachycardia. But don't let the name worry you; it's just a slightly elevated heart rate with a rhythm that's nice and regular. Your PQRST intervals are also looking good. This is often just your body's response to daily routines or a little bit of stress. 😌\n\nWishing you all the best!", 

"Hello {{name}},\n\nChecking in with your ECG results, and it's Sinus Tachycardia. It's a clinical term for a heart that's beating a bit fast but with a rhythm that's as steady as ever (This is what we want to see!). And those PQRST intervals? Absolutely normal. It's a typical physiological response to things like stress or even faster walking.\n\nTake care and best regards!",

"Greetings {{name}},\n\nYour ECG revealed Sinus Tachycardia. It sounds more daunting than it is – your heart rate is just a bit high, yet your rhythm is perfectly regular. Your PQRST intervals are on point. It's typical for the heart to respond this way to activity or emotions. 🌹\n\nBest wishes for your well-being!",

"Hi {{name}},\n\nI've reviewed your ECG, and it shows Sinus Tachycardia. Not to worry, though – your heart rate is slightly up, but the rhythm remains excellent. Your PQRST intervals are consistent with the norm. Sinus Tachycardia is often a natural reaction to everyday life's ups and downs. 😊\n\nStay positive!",

"Hey there {{name}},\n\nYour ECG indicates Sinus Tachycardia. While the term might seem intimidating, it's really just an increased heart rate, with your rhythm staying wonderfully regular. Plus, your PQRST intervals are looking great. This can happen with any number of daily activities like working out or stress. 🌻\n\nAll the best to you!",

"Hello {{name}},\n\nGot your ECG results, and it's Sinus Tachycardia. It simply means your heart's beating a little faster, but the rhythm is impeccable and regular. Your PQRST intervals are within the normal range. This is often just a harmless response to daily stresses. 🌼\n\nBest health wishes!",

"Greetings {{name}},\n\nYour ECG shows Sinus Tachycardia. But don't be alarmed, your heart is just a tad faster, yet the rhythm is as good as it gets. Additionally, your PQRST intervals are standard. Sinus Tachycardia is very typical and can be just a reaction to exercises or coffee. 😄\n\nStay well and happy!",
    ];
})();

export default sinus_tachycardia_1;