import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  getUserHistory,
  selectUserHistory,
} from '../../store/slices/userHistory'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import makeStyles from '@mui/styles/makeStyles';

import ColCard from '../ColCard'
import UserHistoryItem from '../UserHistoryItem'

const filters = ['newest', 'oldest']

const useStyles = makeStyles((theme) => ({
  empty: {
    padding: `calc(${theme.spacing()} * 4) ${theme.spacing()}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function UserHistory(props) {
  const { id } = props
  const classes = useStyles()
  const [filter, setFilter] = useState(filters[0])
  const lastFilterRef = useRef(null)
  const [userHistoryDataSorted, setUserHistoryDataSorted] = useState([])

  const userHistoryData = useSelector(selectUserHistory)

  const cursorRef = useRef(null)

  function handleChange(e, type) {
    const { value } = e.target
    switch (type) {
      case 'filter':
        setFilter(value)
        break
      default:
        break
    }
  }

  function compareUserHistoryData(a, b) {
    a = new Date(a.analysis_completed_at)
    b = new Date(b.analysis_completed_at)
    return a - b
  }

  useEffect(() => {
    getUserHistory(id, cursorRef.current)
  }, [id])

  useEffect(() => {
    setUserHistoryDataSorted(
      Array.from(userHistoryData).sort(compareUserHistoryData).reverse()
    )
    lastFilterRef.current = filters[0]
  }, [userHistoryData])

  useEffect(() => {
    if (filter !== lastFilterRef.current) {
      lastFilterRef.current = filter
      setUserHistoryDataSorted((prevState) => Array.from(prevState).reverse())
    }
  }, [filter])

  return (
    <ColCard
      title="History"
      noChildrenPadding
      collapseNoDivider
      renderHeader={{
        withoutTitle: false,
        render: () => (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: 'auto' }}
          >
            <Grid item>
              <FormLabel style={{ marginRight: 10 }}>Filter:</FormLabel>
            </Grid>
            <Grid item>
              <FormControl>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  value={filter}
                  onChange={(e) => handleChange(e, 'filter')}
                >
                  <MenuItem value={'newest'}>From newest</MenuItem>
                  <MenuItem value={'oldest'}>From oldest</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ),
      }}
    >
      {!!userHistoryDataSorted?.length ? (
        userHistoryDataSorted.map((item, index) => (
          <UserHistoryItem
            ecgId={item.ecg_id}
            key={item.id}
            index={index}
            media={item.medium}
            date={item.analysis_completed_at}
            interpretation={item.rhythms}
            tags={item.note_tags}
            userNote={item.note}
            pvc={item.pvc_count}
            pac={item.pac_count}
            mode={props.mode}
            samplingFrequency={item.sampling_frequency}
          />
        ))
      ) : (
        <Grid item className={classes.empty}>
          <Typography variant="body2" color="textSecondary">
            No data
          </Typography>
        </Grid>
      )}
    </ColCard>
  );
}

export default UserHistory
