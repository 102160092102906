import React from 'react'
import { Link } from 'react-router-dom'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import LockIcon from '@mui/icons-material/Lock'
import { auditGetScore } from '../../common/utils'
import { TableTag } from '../TaskTable/TaskTable.styled'
import { AUDIT_STATUS, TASK_STATUS, TASK_TYPE } from '../../common/constants'

function AuditTable(props) {
  const { setCursor, audits } = props

  function statusToText(status) {
    switch (status) {
      case 0:
        return (
          <Button variant="outlined" color="primary">
            PENDING
          </Button>
        )
      case 1:
        return (
          <Button variant="outlined" color="secondary">
            DONE
          </Button>
        )
      default:
        return 'UNKNOWN'
    }
  }

  function rowDataToAction(data) {
    switch (data.status) {
      case 0:
        return (
          <Link to={`/review/${data.ecg_id}?mode=audit`}>
            <ArrowRightAltIcon />
          </Link>
        )
      case 1:
        return (
          <Link to={`/review/${data.ecg_id}?mode=audit`}>
            <LockIcon />
          </Link>
        )
      default:
        return 'UNKNOWN'
    }
  }

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>ECG ID</TableCell>
              <TableCell>Technician</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Analysis Requested At</TableCell>
              <TableCell>Accuracy (score)</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audits.map((row, index) => {
              return (
                <TableRow key={row.ecg_id} hover={true}>
                  <TableCell component="th" scope="row">
                    <b>{row.id}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>{row.ecg_id}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>{row.reviewer.username}</b>
                  </TableCell>
                  <TableCell>{
                    <div style={{display:"flex", alignItems:"center", gap:"10px"}}> { row.source === "apple_watch" ? 
                      <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 0H2.5C1.12 0 0 1.12 0 2.5V19.5C0 20.88 1.12 22 2.5 22H10.5C11.88 22 13 20.88 13 19.5V2.5C13 1.12 11.88 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="black" fill-opacity="0.54"/>
                      </svg> : 
                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 0C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0H2ZM9 7V1.5L14.5 7H9Z" fill="black" fill-opacity="0.54"/>
                      </svg>
                      
                    }
                    {TASK_TYPE[row.source]}
                    </div>
                  }    
                </TableCell>
                  <TableCell component="th" scope="row">
                    <b>{new Date(row.analysis_requested_at).toLocaleString()}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>{auditGetScore(row.score)}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                  <TableTag
                    label={AUDIT_STATUS[row.status]}
                    color="primary"
                    variant="outlined"
                  />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {rowDataToAction(row)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <br />
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            let cursor = 0
            if (audits.length) {
              const maxId = audits.reduce(
                (max, audits) =>
                  parseInt(audits.id, 10) > max ? parseInt(audits.id, 10) : max,
                parseInt(audits[0].id, 10)
              )
              cursor = maxId
            }
            setCursor(prev => cursor)
          }}
        >
          Load More
        </Button>
      </div>
    </div>
  )
}

export default AuditTable
