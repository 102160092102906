import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  currentUserCvdSurvey,
  getUserCvdSurvey
} from '../../store/slices/userCvdSurvey'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles';

import ColCard from '../ColCard'

const useStyles = makeStyles((theme) => ({
  cvdItem: {
    padding: `calc(${theme.spacing()}) calc(${theme.spacing()} * 2)`,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
  },
  empty: {
    padding: `calc(${theme.spacing()} * 4) ${theme.spacing()}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function UserCvdSurvey(props) {
  const { id } = props
  const classes = useStyles()
  const userCvdSurveyData = useSelector(currentUserCvdSurvey) 

  useEffect(() => {
    getUserCvdSurvey(id)
  }, [id])

  useEffect(() => {
    console.log(userCvdSurveyData)
  }, [userCvdSurveyData])


  return (
    <ColCard
      title="CVD Survey"
      noChildrenPadding
      collapseNoDivider
      renderHeader={{
        withoutTitle: false,
        render: () => (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: 'auto' }}
          >

          </Grid>
        ),
      }}
    >
      {
      !!Object.keys(userCvdSurveyData)?.length ?         
      ( // If case
        Object.keys(userCvdSurveyData).map((key, index) => ( 
          <Grid item className={classes.cvdItem}>
            <Typography variant="body2" color="textSecondary">
            <b>Question: </b>{key}  <b>Answer: </b>{userCvdSurveyData[key]}
            </Typography>
          </Grid>
        ))
      ) 
      : // Else case      
      <Grid item className={classes.empty}>
        <Typography variant="body2" color="textSecondary">
            No data
        </Typography>
      </Grid>
      }
     
    </ColCard>
  );
}

export default UserCvdSurvey
